// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// components
import * as MZ from "../models/MZ";
//==============================
const MyDataTable = (props) => {
  // ui states
  const [settingsD, setSettingsD] = React.useState(false);
  // data states
  const [columns, setColumns] = React.useState([...props.columns]);
  //
  const formController = MZ.FormController();
  //
  var rData = props.data;
  var fData = rData;
  // pages
  const thresh = 25;
  var length = fData.length;
  var pages = Math.ceil(length / thresh);
  const [page, setPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState(null);
  const [sortReverse, setSortReverse] = React.useState(false);
  //
  const startI = () => (page - 1) * thresh;
  const endI = () => MZ.Num.clamp(startI() + thresh, 0, length);
  // functions
  const changeOrder = (key, num) => {
    let o = MZ.Arr.sort([...columns], false, "order", (v) => parseInt(v ?? 0));
    let i = o.findIndex((o) => o.key === key);
    if (i > -1) o.splice(i + num, 0, o.splice(i, 1)[0]);
    for (let i in o) {
      o[i].order = parseInt(i);
    }
    setColumns(o);
  };
  const changePage = (page) => {};

  // functions
  const _cell = (cell, head = false) => {
    return (
      <MUI.TableCell
        key={MZ.GetKey()}
        sx={{
          p: props.small ? 1 : 2,
          borderWidth: 0.25,
          backgroundColor: head ? "primary.main" : "light.main",
          borderColor: head ? "light.light" : "primary.light",
          color: head ? "light.main" : "primary.main",
          "&:nth-of-type(even)": {
            backgroundColor: head ? "primary.lighter" : "white.main",
          },
          textAlign: cell.center ?? true ? "center" : "start",
          ...cell.sx,
        }}
        colSpan={cell.colSpan ?? 1}
        rowSpan={cell.rowSpan ?? 1}
      >
        {cell.child ?? null}
      </MUI.TableCell>
    );
  };
  return (
    <MUI.Box
      className="fill"
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows="auto 1fr"
      gap={0}
      sx={props.sx}
    >
      <MUI.Box gridColumn="1" gridRow="1" sx={{ overflow: "hidden" }}>
        <MUI.Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
          sx={{ mb: 0.5 }}
        >
          <MUI.Typography variant="h5">{props.title}</MUI.Typography>
          <MUI.Box sx={{ flexGrow: 1 }} />
          {MZ.ForEach(props.titleActions, (ak, av) => (
            <MUI.Button
              key={`title-actions-${ak}`}
              onClick={() => av.onClick()}
              {...av.props}
            >
              {av.icon}
              {av.text}
            </MUI.Button>
          ))}
        </MUI.Stack>
        <MUI.Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          {/* <MUI.IconButton onClick={(evt) => setSettingsD(evt.currentTarget)}>
            <MIcons.FilterList />
          </MUI.IconButton> */}
          <MUI.Typography variant="h6">
            {`${startI() + 1} / ${endI()} | ${fData.length}`}
          </MUI.Typography>

          <MUI.Box sx={{ flexGrow: 1 }} />

          <MUI.Pagination
            page={page}
            count={pages}
            onChange={(evt, val) => setPage(val)}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            sx={{ alignSelf: "center" }}
          />
        </MUI.Stack>
      </MUI.Box>
      <MUI.Box gridColumn="1" gridRow="2">
        <MUI.TableContainer>
          <MUI.Table
            sx={{ minWidth: props.minWidth, borderColor: "primary.main" }}
          >
            <MUI.TableHead>
              <MUI.TableRow sx={{ ...props.headSX }}>
                {_cell(
                  {
                    child: "#",
                    sx: { width: "auto" },
                    colSpan: (props.actions ? props.actions.length : 0) + 1,
                  },
                  true
                )}
                {MZ.ForEach(MZ.Arr.sort(columns, false, "order"), (k, col) =>
                  _cell({ child: col.head }, true)
                )}
              </MUI.TableRow>
            </MUI.TableHead>

            <MUI.TableBody sx={{ ...props.bodySX }}>
              {props.loading ? (
                <MUI.TableRow key={`table-body-loader-row`}>
                  {_cell({
                    child: <MUI.Skeleton height={100} variant="rectangular" />,
                    colSpan:
                      columns.length +
                      (props.actions ? props.actions.length : 0) +
                      1,
                    sx: { p: 0, height: 50 },
                  })}
                </MUI.TableRow>
              ) : (
                MZ.ForEach(fData, (k, v) => {
                  if (startI() <= k && k < endI()) {
                    return (
                      <MUI.TableRow key={`table-body-row-${k}`}>
                        {_cell({
                          child: parseInt(k) + 1,
                          sx: { width: 50 },
                        })}
                        {MZ.ForEach(props.actions, (ak, av) =>
                          _cell({
                            child: (
                              <MUI.Button
                                onClick={() => av.onClick(k, v)}
                                sx={{ padding: 0.75, minWidth: "auto" }}
                              >
                                {av.icon}
                                {av.text}
                              </MUI.Button>
                            ),
                            sx: { width: 50 },
                          })
                        )}
                        {MZ.ForEach(
                          MZ.Arr.sort(columns, false, "order"),
                          (c, col) => {
                            return _cell({ child: col.value(k, v) }, false);
                          }
                        )}
                      </MUI.TableRow>
                    );
                  }
                })
              )}
            </MUI.TableBody>
          </MUI.Table>
        </MUI.TableContainer>
      </MUI.Box>
      {/* <MUI.Menu
        anchorEl={settingsD}
        open={!!settingsD}
        onClose={() => setSettingsD(null)}
      >
        <MUI.Table size="small">
          <MUI.TableBody>
            {MZ.ForEach(MZ.Arr.sort(columns, false, "order"), (ck, cv) => (
              <MUI.TableRow key={`FMTBR-${ck}-${cv.key}`}>
                <MUI.TableCell
                  sx={{ p: 0.1 }}
                  children={
                    <MUI.IconButton
                      sx={{ p: 0 }}
                      onClick={() => changeOrder(cv.key, -1)}
                      children={<MIcons.KeyboardDoubleArrowUp />}
                    />
                  }
                />
                <MUI.TableCell
                  sx={{ p: 0.1 }}
                  children={
                    <MUI.IconButton
                      sx={{ p: 0 }}
                      onClick={() => changeOrder(cv.key, 1)}
                      children={<MIcons.KeyboardDoubleArrowDown />}
                    />
                  }
                />
                <MUI.TableCell children={cv.head} />
                <MUI.TableCell
                  sx={{ p: 0.1 }}
                  children={
                    <MZ.InputControllerWidget
                      name={cv.key}
                      formController={formController}
                      initValue={formController.getValue(cv.key)}
                      inputField={(value, setValue, error) => (
                        <MUI.TextField
                          size="small"
                          fullWidth={true}
                          type="text"
                          name={cv.key}
                          value={value}
                          onChange={(evt) => setValue(evt.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          select={cv.filterValues ? true : false}
                          children={MZ.ForEach(cv.filterValues, (fk, fv) => (
                            <MUI.MenuItem
                              key={`FMTBR-${ck}-${cv.key}-${fk}`}
                              value={fv.value}
                            >
                              {fv.label}
                            </MUI.MenuItem>
                          ))}
                        />
                      )}
                    />
                  }
                />
              </MUI.TableRow>
            ))}
          </MUI.TableBody>
        </MUI.Table>
      </MUI.Menu> */}
    </MUI.Box>
  );

  /*
    columns: {
     {
        key: "des",
        head: "Dessert",
        value: (k,v)=>{

      }
    }

  */
};
// ==========
MyDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageRows: PropTypes.number,

  minWidth: PropTypes.number,

  sx: PropTypes.object,

  loading: PropTypes.bool,
};
// ==========
export default MyDataTable;
