// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
// layouts
import MyPage from "../layouts/MyPage";
// components
import MyFormDialog from "../components/MyFormDialog";
import MyDataTable from "../components/MyDataTable";

const MyOutlets = () => {
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  // const RouterParams = Router.useParams();
  // ui
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [formOpen, setFormOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(null);

  // check if logged in
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
    (async () => {
      // data
      var r = await getAPI();
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        setData(r.data["data"]["outlets"]);
      }
    })();
  }, []);

  return (
    <MyPage>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MyDataTable
        sx={{ p: 1 }}
        small={true}
        loading={loading}
        title={MZ.T("outlets")}
        columns={[
          {
            key: "logo",
            head: MZ.T("logo"),
            value: (k, v) => "-",
          },
          {
            key: "property_id",
            head: MZ.T("property"),
            value: (k, v) => v.property_name,
          },
          {
            key: "name",
            head: MZ.T("name"),
            value: (k, v) => v.name,
          },
          {
            key: "currency_id",
            head: MZ.T("currency"),
            value: (k, v) => v.currency_id,
          },
          {
            key: "stat_seats",
            head: MZ.T("seats"),
            value: (k, v) => v.stat_seats,
          },
          {
            key: "stat_size",
            head: MZ.T("size"),
            value: (k, v) => v.stat_size,
          },
          {
            key: "stat_work_hours",
            head: MZ.T("work-hours"),
            value: (k, v) => v.stat_work_hours,
          },
          {
            key: "active",
            head: MZ.T("status"),
            value: (k, v) => (v.active ? MZ.T("active") : MZ.T("inactive")),
            filterValues: [
              { value: "", label: "Choose" },
              { value: "1", label: "Active" },
              { value: "2", label: "Inactive" },
            ],
          },
          {
            key: "addstamp",
            head: MZ.T("date-added"),
            value: (k, v) =>
              new MZ.DateTime(v.addstamp).getFormated("Y-m-d H:i:s"),
          },
          {
            key: "updatestamp",
            head: MZ.T("last-modified"),
            value: (k, v) =>
              new MZ.DateTime(v.updatestamp).getFormated("Y-m-d H:i:s"),
          },
        ]}
        actions={[
          {
            //icon: <MIcons.Edit />,
            text: MZ.T("benchmarks"),
            onClick: (k, v) => {
              setFormData(v);
              setFormOpen(true);
            },
          },
        ]}
        data={data ?? []}
      />
      <MyBenchmarksForm
        open={formOpen}
        setOpen={setFormOpen}
        loading={loading}
        setLoading={setLoading}
        data={formData}
        dependants={data}
        apiError={apiError}
      />
    </MyPage>
  );
};

export default MyOutlets;

//============< filterForm
const MyBenchmarksForm = (props) => {
  // form
  var formController = MZ.FormController();
  //
  return (
    <MyFormDialog
      open={props.open}
      title={`${props.data ? props.data.name : ""} ${MZ.T("benchmarks")}`}
      onSubmit={(form) => {
        props.setLoading(true);

        props.setOpen(false);
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {MZ.T("save")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!props.apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {props.apiError}
        </MUI.Alert>
      )}
      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        {MZ.ForEach(props.dependants, (k, v) => {
          if (props.data.id === v.id) return;
          return (
            <MZ.InputControllerWidget
              key={`PBF-${k}`}
              formController={formController}
              name={`benchmarks-${v.id}`}
              index={parseInt(k)}
              initValue={(props.data.benchmarks ?? "")
                .split(",")
                .includes(v.id)}
              staticValue={v.id}
              inputField={(value, setValue, error) => (
                <MUI.FormControlLabel
                  label={v.name}
                  control={
                    <MUI.Checkbox
                      checked={value}
                      onChange={(evt) => setValue(evt.target.checked)}
                    />
                  }
                />
              )}
            />
          );
        })}
      </MUI.Grid>
    </MyFormDialog>
  );
};

// get transactions
async function getAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/outlets/get", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}
