// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../../models/MyAppM";
import { MyReportsM } from "../../models/MyReportsM";
import * as MZ from "../../models/MZ";
// layouts
import MyDemoPage from "../../layouts/MyDemoPage";
import MyDashboardPerformanceTab from "../../layouts/dashboard/MyDashboardPerformanceTab";
import MyDashboardDetailsTab from "../../layouts/dashboard/MyDashboardDetailsTab";
import MyDashboardFilterForm from "../../layouts/dashboard/MyDashboardFilterForm";
import MyDashboardDownloadForm from "../../layouts/dashboard/MyDashboardDownloadForm";
// components

const MyDemoDashboard = (props) => {
  // constants
  // const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  var RouterParams = Router.useParams();

  // my variables
  // ui
  const [tab, setTab] = React.useState("performance");
  const [filterDialog, setFilterDialog] = React.useState(false);
  const [downloadDialog, setDownloadDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [apiError, setApiError] = React.useState(null);
  // data
  const [filterExport, setFilterExport] = React.useState(_filterExport());
  const [filterData, setFilterData] = React.useState(_filterData());

  const [reportId, setReportId] = React.useState(
    "TotalRevenue/RevenueAnalysis"
  );
  const [dependants, setDependants] = React.useState(null);
  const [rawData, setRawData] = React.useState(null);
  const oldDates = React.useRef({});
  const [pData, setPData] = React.useState({});

  // check if logged in
  // pre render
  React.useLayoutEffect(() => {
    (async () => {
      // dependants
      if (!dependants) {
        var r = await getDependants(RouterParams.portal, _outletId());
        if (r.success === false) {
          setApiError(r.message);
        } else {
          setApiError(null);
          setDependants(r.data["data"]);
        }
      }
    })();
  }, []);

  // after render
  React.useEffect(() => {
    (async () => {
      let rData = rawData;
      if (!filterData || !dependants) return;
      //
      let now = new MZ.DateTime();
      if (!filterData["start_date"])
        filterData["start_date"] = now.addDays(-1).getFormated("Y-m-d");
      if (!filterData["end_date"])
        filterData["end_date"] = now.addDays(-1).getFormated("Y-m-d");
      if (!filterData["old_date"])
        filterData["old_date"] = now
          .addDays(-1)
          .addYears(-1)
          .getFormated("Y-m-d");
      //transactions
      if (
        filterData["start_date"] !== oldDates.current["start_date"] ||
        filterData["end_date"] !== oldDates.current["end_date"] ||
        filterData["old_date"] !== oldDates.current["old_date"]
      ) {
        var r = await getTransactions(
          RouterParams.portal,
          _outletId(),
          filterData["start_date"],
          filterData["end_date"],
          filterData["old_date"]
        );
        if (r.success === false) {
          setApiError(r.message);
        } else {
          setApiError(null);
          setRawData(r.data["data"]);
          rData = r.data["data"];
          oldDates.current["start_date"] = filterData["start_date"];
          oldDates.current["end_date"] = filterData["end_date"];
          oldDates.current["old_date"] = filterData["old_date"];
        }
      }
      //
      r = MyReportsM.processData(
        filterData,
        reportId,
        rData,
        dependants,
        RouterParams.portal
      );
      if (r.success === false) {
        setApiError(r.message);
        setFilterDialog(true);
      } else {
        setApiError(null);
        setPData(r.data);
      }

      setLoading(null);
    })();
  }, [dependants, rawData, filterData, reportId]);

  //
  function _outletId() {
    return !RouterParams.outletId
      ? 1
      : window.atob(decodeURI(RouterParams.outletId));
  }
  function _filterExport() {
    return !RouterParams.filterExport
      ? {}
      : JSON.parse(window.atob(decodeURI(RouterParams.filterExport)));
  }
  function _filterData() {
    return !RouterParams.filterData
      ? {}
      : JSON.parse(window.atob(decodeURI(RouterParams.filterData)));
  }

  return (
    <MyDemoPage outlet={dependants ? dependants["outlet"] : {}}>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MLab.TabContext value={tab}>
        <MUI.Box
          display="grid"
          gridTemplateColumns="1fr"
          gridTemplateRows="auto 1fr"
          gap={0}
        >
          <MUI.Box
            gridColumn="1"
            gridRow="1"
            sx={{ mt: 0.5, borderBottom: 1, borderColor: "divider", pr: 2 }}
          >
            <MUI.Stack direction="row" spacing={1}>
              <MLab.TabList
                onChange={(evt, val) => setTab(val)}
                sx={{ minHeight: 0, flexGrow: 1 }}
              >
                <MUI.Tab
                  icon={<MIcons.Assessment sx={{ fontSize: 15 }} />}
                  iconPosition="start"
                  label={MZ.T("performance")}
                  value="performance"
                  sx={{ fontSize: 12.5, minHeight: 0 }}
                />
                <MUI.Tab
                  icon={<MIcons.TableChart sx={{ fontSize: 15 }} />}
                  iconPosition="start"
                  label={MZ.T("details")}
                  value="details"
                  sx={{ fontSize: 12.5, minHeight: 0 }}
                />
              </MLab.TabList>

              <MUI.FormControl
                variant="filled"
                size="small"
                sx={{ mb: 0.5, minWidth: 0 }}
              >
                <MUI.Select
                  defaultValue={reportId}
                  value={reportId}
                  onChange={(evt) => setReportId(evt.target.value)}
                  inputProps={{
                    "aria-label": "Without label",
                    sx: { py: 0.5 },
                  }}
                >
                  <MUI.ListSubheader>{MZ.T("total-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="TotalRevenue/RevenueAnalysis">
                    {MZ.T("total-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/AverageCheck">
                    {MZ.T("total-revenue-average")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSeat">
                    {MZ.T("total-revenue-per-seat")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerHour">
                    {MZ.T("total-revenue-per-hour")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSeatPerHour">
                    {MZ.T("total-revenue-per-seat-hour")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSqm">
                    {MZ.T("total-revenue-per-sqm")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("food-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="FoodRevenue/RevenueAnalysis">
                    {MZ.T("food-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="FoodRevenue/RatioAnalysis">
                    {MZ.T("food-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>
                    {MZ.T("beverage-revenue")}
                  </MUI.ListSubheader>
                  <MUI.MenuItem value="BeverageRevenue/RevenueAnalysis">
                    {MZ.T("beverage-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="BeverageRevenue/RatioAnalysis">
                    {MZ.T("beverage-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("other-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="OtherRevenue/RevenueAnalysis">
                    {MZ.T("other-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="OtherRevenue/RatioAnalysis">
                    {MZ.T("other-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("covers")}</MUI.ListSubheader>
                  <MUI.MenuItem value="CoversRevenue/TotalCovers">
                    {MZ.T("covers-total")}
                  </MUI.MenuItem>
                </MUI.Select>
              </MUI.FormControl>

              <MUI.Box>
                <MUI.Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<MIcons.FilterAlt />}
                  sx={{ mb: 0.5 }}
                  onClick={() => setFilterDialog(true)}
                >
                  {MZ.T("filter")}
                </MUI.Button>
              </MUI.Box>

              <MUI.Box>
                <MUI.Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<MIcons.Download />}
                  sx={{ mb: 0.5, boxShadow: "none" }}
                  onClick={() => setDownloadDialog(true)}
                >
                  {MZ.T("download")}
                </MUI.Button>
              </MUI.Box>
            </MUI.Stack>
          </MUI.Box>
          <MUI.Box gridColumn="1" gridRow="2" sx={{ p: 1 }}>
            <MLab.TabPanel value="performance" sx={{ p: 0 }}>
              <MyDashboardPerformanceTab
                loading={loading}
                pData={pData}
                filterData={filterData}
                filterExport={filterExport}
                demo={props.demo}
              />
            </MLab.TabPanel>
            <MLab.TabPanel value="details" sx={{ p: 0 }}>
              <MyDashboardDetailsTab loading={loading} pData={pData} />
            </MLab.TabPanel>
          </MUI.Box>
        </MUI.Box>
      </MLab.TabContext>
      <MyDashboardFilterForm
        open={filterDialog}
        setOpen={setFilterDialog}
        loading={loading}
        setLoading={setLoading}
        filterExport={filterExport}
        setFilterExport={setFilterExport}
        filterData={filterData}
        setFilterData={setFilterData}
        dependants={dependants}
        apiError={apiError}
      />
      <MyDashboardDownloadForm
        open={downloadDialog}
        setOpen={setDownloadDialog}
        loading={loading}
        setLoading={setLoading}
        rawData={rawData}
        dependants={dependants}
        filterData={filterData}
        apiError={apiError}
      />
    </MyDemoPage>
  );
};

export default MyDemoDashboard;

//============< APIs
// get dependants
async function getDependants(portal, outletId) {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set(
      "portal",
      portal === "corporate"
        ? "ClientCorporate"
        : portal === "property"
        ? "ClientProperty"
        : "ClientOutlet"
    );
    fData.set("outlet_id", outletId);
    //========================= request
    return await MyAppM.exabyteRequest("client/demo/dependants", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function getTransactions(portal, outletId, start, end, old) {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set(
      "portal",
      portal === "corporate"
        ? "ClientCorporate"
        : portal === "property"
        ? "ClientProperty"
        : "ClientOutlet"
    );
    fData.set("outlet_id", outletId);
    fData.set(
      "start_date",
      new MZ.DateTime(start).addDays(-7).getFormated("Y-m-d")
    );
    fData.set("end_date", new MZ.DateTime(end).addDays(7).getFormated("Y-m-d"));
    fData.set(
      "old_date",
      new MZ.DateTime(old).addDays(-7).getFormated("Y-m-d")
    );
    //========================= request
    return await MyAppM.exabyteRequest("client/demo/get-transactions", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}
