// models
import * as MZ from "./MZ";
//============================== app module
export class MyAppM {
  //============================ API
  static API = "https://api.beviapp.com/";
  static MEDIA = MyAppM.API + "_media/";
  //
  static UIDD = {
    UID: null,
    USER: null,
    PORTAL: null,
    ORG: null,
    PRIVILEGES: null,
  };
  //============================ init
  static init() {
    MyAppM.checkUIDD();
    MyAppM.applyPrivileges();
    MyAppM.appendLocales();
    MyAppM.appendUserData();
    MyAppM.appendMenuActive();
    //
  }
  //============================ checkUID
  static checkUIDD(portal = null) {
    MyAppM.UIDD = MZ.Cookie.get("UIDD", true, true);
    if (
      !MyAppM.UIDD ||
      !MyAppM.UIDD.UID ||
      !MyAppM.UIDD.USER ||
      !MyAppM.UIDD.PORTAL ||
      !MyAppM.UIDD.ORG
    )
      return false;
    else if (portal && MyAppM.UIDD.PORTAL.portal_id !== portal) return false;
    return true;
  }
  //============================ logour
  static logout() {
    MZ.Cookie.del("UIDD");
    window.location.reload();
  }
  //============================ api reponse
  static exabyteApiResponse(request) {
    try {
      //============================ request error
      if (request.httpCode !== 200 || !request.response) {
        return MZ.Error(
          `${request.httpCode}: ${request.error}, \n${request.response ? "" : "empty or invalid reponse"
          }`,
          request.response
        );
      }
      //============================ api error
      if (request.response.status !== 200 || request.response.error !== null) {
        if (request.response.status === 401) {
          // if unauthorized logout
          MyAppM.logout();
          //location.reload();
          return MZ.Error(
            `${request.response.status}: ${request.response.statusText}, \n${request.response.error
            } \n${JSON.stringify(request.response.data)}`,
            request.response
          );
        } else {
          return MZ.Error(
            `${request.response.status}: ${request.response.statusText}, \n${request.response.error
            } \n${JSON.stringify(request.response.data)}`,
            request.response
          );
        }
      }
      //============================ success
      return MZ.Success(null, request.response);
    } catch (err) {
      //============================ error
      return MZ.Error(err);
    }
  }
  //============================ api
  static async exabyteRequest(url, data) {
    //============================ request
    let request = new MZ.HttpRequest({
      method: "POST",
      address: MyAppM.API + url,
      headers: {
        "User-Timezone": -new Date().getTimezoneOffset(),
      },
      bodyData: data,
      responseType: MZ.ResponseType.Json,
    });
    await request.send();
    //============================ error
    // return
    return MyAppM.exabyteApiResponse(request);
  }
  //============================ misc
  //
  static getMediaUrl(fileName) {
    try {
      if (!fileName) return null;
      else return MyAppM.MEDIA + fileName;
    } catch (err) {
      console.warn("MyAppM", "getMediaUrl", err);
    }
  }
  //
  static changeLocale(locale) {
    MZ.Cookie.set("locale", locale);
    //location.reload();
  }
}
