// packages
import React from "react";
import * as Router from "react-router-dom";
// data
import * as XLSX from "xlsx";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
import * as mz from "../models/mz/mz";
// layouts
import MyPage from "../layouts/MyPage";
// components
import MyTable from "../components/MyTable";
import MyFormDialog from "../components/MyFormDialog";
import MyDataTable from "../components/MyDataTable";
import MyFilePicker from "../components/MyFilePicker";

const MyTransactions = () => {
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  const RouterParams = Router.useParams();
  // ui
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [dependants, setDependants] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const [importFormOpen, setImportFormOpen] = React.useState(false);

  // check if logged in
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
    (async () => {
      // data
      var r = await dependantsAPI();
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        setDependants(r.data["data"]);
      }
    })();
    getData();
  }, []);

  async function getData() {
    // data
    var r = await getAPI();
    setLoading(false);
    if (r.success === false) {
      setApiError(r.message);
    } else {
      setApiError(null);
      setData(r.data["data"]["transactions"]);
    }
  }

  return (
    <MyPage>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MyDataTable
        sx={{ p: 1 }}
        small={true}
        loading={loading}
        title={MZ.T("transactions")}
        titleActions={[
          {
            icon: "",
            text: MZ.T("import"),
            props: { variant: "outlined" },
            onClick: () => setImportFormOpen(true),
          },
          {
            icon: "",
            text: MZ.T("add"),
            props: { variant: "outlined" },
            onClick: () => setAddFormOpen(true),
          },
        ]}
        columns={[
          ...(["corporate"].includes(RouterParams.portal)
            ? [
                {
                  key: "property_id",
                  head: MZ.T("property"),
                  value: (k, v) => v.property_name ?? "-",
                },
              ]
            : []),
          ...(["corporate", "property"].includes(RouterParams.portal)
            ? [
                {
                  key: "outlet_id",
                  head: MZ.T("outlet"),
                  value: (k, v) => v.outlet_name ?? "-",
                },
              ]
            : []),
          {
            key: "year",
            head: MZ.T("year"),
            value: (k, v) => v.year,
          },
          {
            key: "count",
            head: MZ.T("progress"),
            value: (k, v) =>
              `${v.count} / ${MZ.DateTime.isLeapYear(v.year) ? "366" : "365"}`,
          },
        ]}
        data={data ?? []}
      />
      <MyAddForm
        open={addFormOpen}
        setOpen={setAddFormOpen}
        loading={loading}
        setLoading={setLoading}
        data={data}
        dependants={dependants}
        apiError={apiError}
        onSubmit={() => {
          getData();
        }}
      />
      <MyImportForm
        open={importFormOpen}
        setOpen={setImportFormOpen}
        loading={loading}
        setLoading={setLoading}
        data={data}
        dependants={dependants}
        apiError={apiError}
        onSubmit={() => {
          getData();
        }}
      />
    </MyPage>
  );
};

export default MyTransactions;
//============< filterForm
const MyAddForm = (props) => {
  // form
  var formController = MZ.FormController();
  const [apiError, setApiError] = React.useState(null);
  //
  return (
    <MyFormDialog
      open={props.open}
      title={MZ.T("add")}
      onSubmit={async (form) => {
        if (apiError) setApiError(null);
        if (formController.valid()) {
          props.setLoading(true);
          var r = await addAPI(formController.toFormData());
          props.setLoading(false);
          if (r.success === false) {
            setApiError(r.message);
          } else {
            if (props.onSubmit) props.onSubmit();
            setApiError(null);
            props.setOpen(false);
          }
        }
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {MZ.T("add")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="outlet_id"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("outlet")}
                name="outlet_id"
                select={true}
                type="text"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                children={
                  props.dependants
                    ? MZ.ForEach(props?.dependants?.outlets, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v.id}>
                          {v.name}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="date"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("date")}
                name="date"
                type="date"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>

        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="covers"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("covers")}
                name="covers"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="total_revenue"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("total-revenue")}
                name="total_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="food_revenue"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("food-revenue")}
                name="food_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="beverage_revenue"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("beverage-revenue")}
                name="beverage_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="other_revenue"
            index={0}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("other-revenue")}
                name="other_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
      </MUI.Grid>
    </MyFormDialog>
  );
};
//============< filterForm
const MyImportForm = (props) => {
  // form
  var formController = MZ.FormController();
  //
  const [apiError, setApiError] = React.useState(null);
  const [imported, importedSet] = React.useState(null);
  const [outlets, outletsSet] = React.useState([]);
  //
  return (
    <MyFormDialog
      open={props.open}
      title={MZ.T("import")}
      onSubmit={async (form) => {
        if (apiError) setApiError(null);
        if (formController.valid()) {
          let data = mz.linkObjectData(
            imported["data"],
            formController.toFormData(),
            {
              outlet_id: ["outlets_names[]", "outlets_ids[]"],
            },
            {
              date: (value) =>
                new mz.DateTime(
                  mz.round((value - 25569) * 86400 * 1000)
                ).formatDateTime(),
            }
          );
          let fd = mz.arrayOfObjectsToFormData(data);
          props.setLoading(true);
          var r = await addAPI(fd);
          props.setLoading(false);
          if (r.success === false) {
            setApiError(r.message);
          } else {
            if (props.onSubmit) props.onSubmit();
            setApiError(null);
            props.setOpen(false);
          }
        }
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {MZ.T("import")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid item xs={12}>
          <MyFilePicker
            accept=".xlsx,.xls,.csv,.tsv"
            onChange={async (evt, file) => {
              if (file) {
                importedSet(await mz.parseDataSheet(file, XLSX));
              } else {
                importedSet(null);
                outletsSet([]);
              }
            }}
          />
        </MUI.Grid>

        <MUI.Grid item xs={12}>
          <MUI.Divider sx={{ my: 1, backgroundColor: "primary.main" }} />
        </MUI.Grid>

        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="outlet_id"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("outlet")}
                name="outlet"
                value={value}
                onChange={(evt) => {
                  setValue(evt.target.value);
                  if (evt.target.value) {
                    let arr = mz.cleanArray(
                      mz.unique(mz.arrayKey(imported.data, evt.target.value))
                    );
                    if (arr && arr.length < 100) outletsSet(arr);
                  } else outletsSet([]);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="date"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("date")}
                name="date"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="covers"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("covers")}
                name="covers"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="total_revenue"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("total-revenue")}
                name="total_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="food_revenue"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("food-revenue")}
                name="food_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="beverage_revenue"
            initValue={null}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("beverage-revenue")}
                name="beverage_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="other_revenue"
            initValue={""}
            formController={formController}
            validator={MZ.ValidateText(true)}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                error={!!error}
                className="w100"
                label={MZ.T("other-revenue")}
                name="other_revenue"
                type="number"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                select={true}
                children={
                  imported
                    ? MZ.ForEach(imported.keys, (k, v) => (
                        <MUI.MenuItem key={`AFO-${k}`} value={v}>
                          {v}
                        </MUI.MenuItem>
                      ))
                    : []
                }
              />
            )}
          />
        </MUI.Grid>

        <MUI.Grid item xs={12}>
          <MyTable
            small={true}
            heads={[
              {
                children: [
                  [
                    { child: MZ.T("data") },
                    { child: MZ.T("outlet") },
                    { child: "", sx: { width: 25 } },
                  ],
                ],
              },
            ]}
            bodies={[
              {
                children: mz.forEach(outlets, (k, v) => {
                  return [
                    {
                      child: (
                        <MZ.InputControllerWidget
                          name={`outlets_names`}
                          index={parseInt(k)}
                          initValue={v}
                          formController={formController}
                          inputField={(value, setValue, error) => (
                            <MUI.TextField
                              className="w100"
                              name={`outlets_names`}
                              value={value}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      ),
                    },
                    {
                      child: (
                        <MZ.InputControllerWidget
                          name={`outlets_ids`}
                          index={parseInt(k)}
                          initValue={null}
                          formController={formController}
                          validator={MZ.ValidateText(true)}
                          inputField={(value, setValue, error) => (
                            <MUI.TextField
                              error={!!error}
                              className="w100"
                              name={`outlets_ids`}
                              value={value}
                              onChange={(evt) => setValue(evt.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              select={true}
                              children={
                                props.dependants
                                  ? MZ.ForEach(
                                      props.dependants["outlets"],
                                      (k, v) => (
                                        <MUI.MenuItem
                                          key={`IOS-${k}`}
                                          value={v.id}
                                        >
                                          {v.name}
                                        </MUI.MenuItem>
                                      )
                                    )
                                  : []
                              }
                            />
                          )}
                        />
                      ),
                    },
                    {
                      child: (
                        <MUI.IconButton
                          sx={{ color: "#f00" }}
                          onClick={() => {
                            let arr = [...outlets];
                            arr.splice(k, 1);
                            outletsSet(mz.cleanArray(arr));
                          }}
                          children={<MIcons.Delete />}
                        />
                      ),
                    },
                  ];
                }),
              },
            ]}
          />
        </MUI.Grid>
      </MUI.Grid>
    </MyFormDialog>
  );
};

// get transactions
async function dependantsAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/transactions/dependants", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function getAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/transactions/get", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function addAPI(fData = new FormData()) {
  try {
    //========================= validate data
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/transactions/add", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}
