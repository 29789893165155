// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../../models/MyAppM";
import * as MZ from "../../models/MZ";
// layouts
import MyTranslationSelect from "../../layouts/MyTranslationSelect";

const MySetPassword = () => {
  const RouterNavigate = Router.useNavigate();
  // check if logged in
  React.useEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate(`/`);
  }, [RouterNavigate]);
  // controls
  var formController = MZ.FormController();
  //
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  //
  async function handleSetPass(evt) {
    try {
      evt.preventDefault();
      if (formController.valid() === false) return;
      // login nigga
      setLoading(true);
      let fData = formController.toFormData();
      fData.set("UID", MyAppM.UIDD.UID);
      let r = await MyAppM.exabyteRequest("client/access/set-password", fData);
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      }
      setApiError(null);
      RouterNavigate(`/${MyAppM.UIDD.PORTAL.link}/dashboard`);
    } catch (err) {
      setApiError(err);
      console.warn(err);
    }
  }

  return (
    <MUI.Container
      component="main"
      maxWidth="xs"
      sx={{
        paddingTop: 9,
      }}
    >
      <MUI.CssBaseline />
      <MUI.Card
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <MUI.Typography
          component="h1"
          variant="h5"
          sx={{ marginBottom: 1, textAlign: "center" }}
        >
          {MZ.T("set-password")}
        </MUI.Typography>

        {!!apiError ? (
          <MUI.Alert severity="error" sx={{ marginTop: 3 }}>
            {apiError}
          </MUI.Alert>
        ) : null}

        <MUI.Box
          component="form"
          onSubmit={handleSetPass}
          noValidate
          sx={{ mt: 1 }}
        >
          <MZ.InputControllerWidget
            name="password"
            formController={formController}
            errorAfterFirstSubmit={true}
            validator={MZ.ValidateText(true, 6, null, true)}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  margin="normal"
                  required
                  className="w100"
                  label={MZ.T("password")}
                  type="password"
                  autoComplete="new-password"
                  error={!!error}
                  onChange={(evt) => setValue(evt.target.value)}
                  autoFocus
                />
                <MUI.FormHelperText error={!!error}>{error}</MUI.FormHelperText>
              </>
            )}
          />
          <MZ.InputControllerWidget
            name="cpassword"
            formController={formController}
            validator={(value, name) => {
              let err1 = MZ.ValidateText(true, 6, null, true)(value);
              if (err1) return err1;
              if (value !== formController.getValue("password"))
                return MZ.T("password-doesnt-match");
              return null;
            }}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  margin="normal"
                  required
                  className="w100"
                  label={MZ.T("confirm-password")}
                  type="password"
                  autoComplete="new-password"
                  error={!!error}
                  onChange={(evt) => setValue(evt.target.value)}
                />
                <MUI.FormHelperText error={!!error}>{error}</MUI.FormHelperText>
              </>
            )}
          />

          <MLab.LoadingButton
            startIcon={<MIcons.Edit />}
            loading={loading}
            loadingPosition="start"
            type="submit"
            className="w100"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {MZ.T("set")}
          </MLab.LoadingButton>

          <MUI.Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <MUI.Button
              onClick={() =>
                RouterNavigate(`/${MyAppM.UIDD.PORTAL.link}/dashboard`)
              }
              variant="text"
            >
              {MZ.T("skip")}
            </MUI.Button>
            <MyTranslationSelect />
          </MUI.Stack>
        </MUI.Box>
      </MUI.Card>
    </MUI.Container>
  );
};

export default MySetPassword;
