// packages
import React from "react";
// UI
import * as MUI from "@mui/material";
// models
import * as MZ from "../models/MZ";
//==============================
const MyDialog = (props) => {
  return (
    <MUI.Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      children={
        <>
          <MUI.DialogTitle id="alert-dialog-title">
            {props.title}
          </MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.DialogContentText id="alert-dialog-description">
              {props.description}
            </MUI.DialogContentText>
          </MUI.DialogContent>
          <MUI.DialogActions>
            {(props.actions ?? ["yes"]).map((v, i) => (
              <MUI.Button key={MZ.GetKey()} onClick={props.onClose}>
                {v}
              </MUI.Button>
            ))}
          </MUI.DialogActions>
        </>
      }
    />
  );
};

export default MyDialog;
