// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
import * as FC from "../models/FormController";
// layouts
import MyPage from "../layouts/MyPage";
// components
import MyFormDialog from "../components/MyFormDialog";
import MyCalendar from "../components/MyCalendar";
import MyGrid from "../components/MyGrid";

const MyActionTracker = () => {
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  // const RouterParams = Router.useParams();
  // ui
  const [calendarDate, calendarDate_set] = React.useState(new MZ.DateTime());

  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [dependants, setDependants] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const [importFormOpen, setImportFormOpen] = React.useState(false);
  const [editEventData, setEditEventData] = React.useState(null);

  // check if logged in
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
    (async () => {
      // data
      var r = await dependantsAPI();
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        setDependants(r.data["data"]);
      }
    })();
    getData();
  }, []);

  async function getData() {
    // data
    var r = await getAPI();
    setLoading(false);
    if (r.success === false) {
      setApiError(r.message);
    } else {
      setApiError(null);
      setData(r.data["data"]["actions"]);
    }
  }

  return (
    <MyPage remBottomPad={true}>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MyCalendar
        sx={{ p: 1, height: "100%" }}
        value={calendarDate}
        onChanged={(value) => calendarDate_set(value)}
        loading={loading}
        title={MZ.T("action-tracker")}
        actions={[
          {
            //icon: <MIcons.Edit />,
            text: MZ.T("add"),
            onClick: (k, v) => {
              setAddFormOpen(true);
            },
          },
        ]}
        onEventClick={(event) => {
          setEditEventData(event);
        }}
        data={data}
      />
      <MyManageForm
        open={addFormOpen}
        setOpen={setAddFormOpen}
        loading={loading}
        setLoading={setLoading}
        apiError={apiError}
        dependants={dependants}
        onSubmit={() => {
          getData();
        }}
      />
      <MyManageForm
        open={!!editEventData}
        setOpen={() => setEditEventData(null)}
        loading={loading}
        setLoading={setLoading}
        apiError={apiError}
        dependants={dependants}
        editData={editEventData}
        onSubmit={() => {
          getData();
        }}
      />
    </MyPage>
  );
};

export default MyActionTracker;

//============< filterForm
const MyManageForm = (props) => {
  const RouterParams = Router.useParams();
  // form
  var formController = MZ.FormController();
  const [apiError, setApiError] = React.useState(null);
  const [portal, setPortal] = React.useState(
    RouterParams.portal == "corporate"
      ? "ClientCorporate"
      : RouterParams.portal == "property"
      ? "ClientProperty"
      : "ClientOutlet"
  );
  const [outlets, setOutlets] = React.useState([]);
  //
  React.useLayoutEffect(() => {
    if (props.editData) {
      formController.import({
        export: true,
        data: {
          event_id: props.editData["id"],
          portal_id: props.editData["portal_id"],
          property_id: props.editData["property_id"],
          outlet_id: props.editData["outlet_id"],
          type: props.editData["type"],
          description: props.editData["description"],
          color: props.editData["color"] ?? "#007bff",
          startstamp: new MZ.DateTime(props.editData["startstamp"]).getFormated(
            "Y-m-d"
          ),
          endstamp: new MZ.DateTime(props.editData["endstamp"]).getFormated(
            "Y-m-d"
          ),
        },
      });
    }

    if (props.dependants) {
      console.log(props.dependants);
      if (RouterParams.portal == "property") {
        setOutlets(props?.dependants?.outlets);
      }
    }
  }, [props.editData, props.dependants]);
  //
  return (
    <MyFormDialog
      open={props.open}
      title={`${MZ.T("action-tracker")}`}
      onSubmit={async (form) => {
        if (apiError) setApiError(null);
        if (formController.valid()) {
          if (props.editData) {
            props.setLoading(true);
            var r = await editAPI(formController.toFormData());
            props.setLoading(false);
            if (r.success === false) {
              setApiError(r.message);
            } else {
              if (props.onSubmit) props.onSubmit();
              setApiError(null);
              props.setOpen(false);
            }
          } else {
            props.setLoading(true);
            var r = await addAPI(formController.toFormData());
            props.setLoading(false);
            if (r.success === false) {
              setApiError(r.message);
            } else {
              if (props.onSubmit) props.onSubmit();
              setApiError(null);
              props.setOpen(false);
            }
          }
        }
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {props.editData ? MZ.T("edit") : MZ.T("add")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {apiError}
        </MUI.Alert>
      )}

      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MZ.InputControllerWidget
          name="event_id"
          formController={formController}
          inputField={(value, setValue, error) => null}
        />

        <MUI.Grid item xs={12} sx={{ pb: 2 }}>
          <MZ.InputControllerWidget
            name="portal_id"
            initValue={portal}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.ToggleButtonGroup
                value={value}
                color="primary"
                exclusive
                onChange={(event, val) => {
                  setValue(val);
                  setPortal(val);
                }}
              >
                {["corporate"].includes(RouterParams.portal) ? (
                  <MUI.ToggleButton
                    value="ClientCorporate"
                    children={MZ.T("corporate")}
                  />
                ) : null}

                {["corporate", "property"].includes(RouterParams.portal) ? (
                  <MUI.ToggleButton
                    value="ClientProperty"
                    children={MZ.T("property")}
                  />
                ) : null}

                <MUI.ToggleButton
                  value="ClientOutlet"
                  children={MZ.T("outlet")}
                />
              </MUI.ToggleButtonGroup>
            )}
          />
        </MUI.Grid>

        {["ClientProperty", "ClientOutlet"].includes(portal) &&
        ["corporate"].includes(RouterParams.portal) ? (
          <MUI.Grid item xs={12} sm={6} md={4}>
            <MZ.InputControllerWidget
              name="property_id"
              formController={formController}
              inputField={(value, setValue, error) => (
                <>
                  <MUI.TextField
                    className="w100"
                    label={MZ.T("property")}
                    name="property"
                    value={value}
                    onChange={(evt) => {
                      setValue(evt.target.value);

                      let arr = [];
                      for (let obj of props?.dependants?.outlets) {
                        if (obj["property_id"] === evt.target.value)
                          arr.push(obj);
                      }
                      formController.setValue("outlet_id", null, "");
                      setOutlets(arr);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    select={true}
                    children={MZ.ForEach(
                      props.dependants.properties ?? [],
                      (k, v) => (
                        <MUI.MenuItem
                          key={`FCO-${k}`}
                          value={v["id"]}
                          children={v["name"]}
                        />
                      )
                    )}
                  />
                </>
              )}
            />
          </MUI.Grid>
        ) : null}

        {["ClientOutlet"].includes(portal) &&
        ["corporate", "property"].includes(RouterParams.portal) ? (
          <MUI.Grid item xs={12} sm={6} md={4}>
            <MZ.InputControllerWidget
              name="outlet_id"
              formController={formController}
              inputField={(value, setValue, error) => (
                <>
                  <MUI.TextField
                    className="w100"
                    label={MZ.T("outlet")}
                    name="outlet_id"
                    value={value}
                    onChange={(evt) => setValue(evt.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    select={true}
                    children={MZ.ForEach(outlets, (k, v) => (
                      <MUI.MenuItem
                        key={`FCO-${k}`}
                        value={v["id"]}
                        children={v["name"]}
                      />
                    ))}
                  />
                </>
              )}
            />
          </MUI.Grid>
        ) : null}

        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="type"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("type")}
                  name="type"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  select={true}
                  children={MZ.ForEach(
                    ["event", "promotion", "note"],
                    (k, v) => (
                      <MUI.MenuItem
                        key={`FCO-${k}`}
                        value={v}
                        children={MZ.T(v)}
                      />
                    )
                  )}
                />
              </>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="description"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("description")}
                  name="description"
                  type="text"
                  value={value}
                  required
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="color"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("color")}
                  name="color"
                  type="color"
                  value={value}
                  required
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="startstamp"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("start-date")}
                  name="startstamp"
                  type="date"
                  value={value}
                  required
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6} md={4}>
          <MZ.InputControllerWidget
            name="endstamp"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("end-date")}
                  name="endstamp"
                  type="date"
                  value={value}
                  required
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          />
        </MUI.Grid>
      </MUI.Grid>
    </MyFormDialog>
  );
};

// get transactions
async function getAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/events/get", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function dependantsAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/events/dependants", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function addAPI(fData = new FormData()) {
  try {
    //========================= validate data
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/events/add", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function editAPI(fData = new FormData()) {
  try {
    //========================= validate data
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/events/edit", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}
