// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
// components
import * as MZ from "../models/MZ";
//==============================
const MyTable = (props) => {
  return (
    <MUI.TableContainer sx={props.sx}>
      <MUI.Table sx={{ minWidth: props.minWidth, borderColor: "primary.main" }}>
        {props.bodyLoading ? (
          <MUI.TableBody>
            <MUI.TableRow>
              <MUI.TableCell
                sx={{
                  p: 0,
                  borderWidth: 0.25,
                }}
                colSpan={props.bodyLoadingColSpan ?? 1}
                rowSpan={props.bodyLoadingRowSpan ?? 1}
              >
                {props.bodyLoadingChild ?? (
                  <MUI.Skeleton height={100} variant="rectangular" />
                )}
              </MUI.TableCell>
            </MUI.TableRow>
          </MUI.TableBody>
        ) : (
          MZ.ForEach(props.groups, (k, group) => {
            let key = `table-${k}`;
            if (!group.children || group.hidden === true) return;
            if (group.head) {
              return (
                <MUI.TableHead key={key} sx={{ ...group.sx }}>
                  {MZ.ForEach(group.children, (k2, row) => {
                    key += k2;
                    return (
                      <MUI.TableRow key={MZ.GetKey()}>
                        {MZ.ForEach(row, (k3, cell) => {
                          key += k3;
                          return (
                            <MUI.TableCell
                              key={MZ.GetKey()}
                              sx={{
                                p: props.small ? 1 : 2,
                                borderWidth: 0.25,
                                backgroundColor: "primary.main",
                                borderColor: "light.light",
                                color: "light.main",
                                "&:nth-of-type(even)": {
                                  backgroundColor: "primary.lighter",
                                },
                                textAlign:
                                  cell.center ?? true ? "center" : "start",
                                ...cell.sx,
                              }}
                              colSpan={cell.colSpan ?? 1}
                              rowSpan={cell.rowSpan ?? 1}
                            >
                              {cell.child ?? null}
                            </MUI.TableCell>
                          );
                        })}
                      </MUI.TableRow>
                    );
                  })}
                </MUI.TableHead>
              );
            } else {
              return (
                <MUI.TableBody key={key} sx={{ ...group.sx }}>
                  {MZ.ForEach(group.children, (k2, row) => {
                    key += k2;
                    return (
                      <MUI.TableRow key={MZ.GetKey()}>
                        {MZ.ForEach(row, (k3, cell) => {
                          key += k3;
                          return (
                            <MUI.TableCell
                              key={MZ.GetKey()}
                              sx={{
                                p: props.small ? 1 : 2,
                                borderWidth: 0.25,
                                backgroundColor: "light.main",
                                borderColor: "primary.light",
                                color: "primary.main",
                                "&:nth-of-type(even)": {
                                  backgroundColor: "white.main",
                                },
                                textAlign:
                                  cell.center ?? true ? "center" : "start",
                                ...cell.sx,
                              }}
                              colSpan={cell.colSpan ?? 1}
                              rowSpan={cell.rowSpan ?? 1}
                            >
                              {cell.child ?? null}
                            </MUI.TableCell>
                          );
                        })}
                      </MUI.TableRow>
                    );
                  })}
                </MUI.TableBody>
              );
            }
          })
        )}
      </MUI.Table>
    </MUI.TableContainer>
  );
};
MyTable.propTypes = {
  minWidth: PropTypes.number,
  groups: PropTypes.array.isRequired,
  sx: PropTypes.object,
  loading: PropTypes.bool,
  loadingChild: PropTypes.node,
  loadingColSpan: PropTypes.number,
  loadingRowSpan: PropTypes.number,
};
export default MyTable;
