// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../../models/MyAppM";
import * as MZ from "../../models/MZ";
// components
import MyTranslationSelect from "../../layouts/MyTranslationSelect";

const MyLogin = () => {
  // router
  const RouterNavigate = Router.useNavigate();
  // form Controller
  const formController = MZ.FormController();

  // check if logged in
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === true)
      RouterNavigate(`/${MyAppM.UIDD.PORTAL.link}/dashboard`);
  }, [RouterNavigate]);

  //
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleLogin(evt) {
    try {
      evt.preventDefault();
      if (formController.valid() !== true) return;
      // login nigga
      setLoading(true);
      let fData = formController.toFormData();
      let r = await MyAppM.exabyteRequest("client/access/login", fData);
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        MZ.Cookie.set("UIDD", r.data["data"], true, true);
        MyAppM.checkUIDD();
        if (r.data["message"] === "set-password")
          RouterNavigate(`/set-password`);
        else RouterNavigate(`/${MyAppM.UIDD.PORTAL.link}/dashboard`);
      }
    } catch (err) {
      setApiError(err);
      console.warn(err);
    }
  }

  return (
    <MUI.Container
      component="main"
      maxWidth="xs"
      sx={{
        paddingTop: 9,
      }}
    >
      <MUI.CssBaseline />
      <MUI.Card
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <MUI.Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: "center" }}
        >
          {MZ.T("login")}
        </MUI.Typography>

        {MZ.If(
          !!apiError,
          <MUI.Alert severity="error" sx={{ marginTop: 3 }}>
            {apiError}
          </MUI.Alert>
        )}

        <MUI.Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{ mt: 1 }}
        >
          <MZ.InputControllerWidget
            name="email"
            formController={formController}
            errorAfterFirstSubmit={true}
            validator={MZ.ValidateEmail(true, null, null, true)}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  margin="normal"
                  required
                  className="w100"
                  label={MZ.T("email")}
                  name="email"
                  autoComplete="email"
                  error={!!error}
                  onChange={(evt) => setValue(evt.target.value)}
                  autoFocus
                />
                <MUI.FormHelperText error={!!error}>{error}</MUI.FormHelperText>
              </>
            )}
          />

          <MZ.InputControllerWidget
            name="password"
            formController={formController}
            errorAfterFirstSubmit={true}
            validator={MZ.ValidateText(true, 6, null, true)}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  margin="normal"
                  required
                  className="w100"
                  label={MZ.T("password")}
                  name="password"
                  autoComplete="password"
                  type="password"
                  error={!!error}
                  onChange={(evt) => setValue(evt.target.value)}
                />
                <MUI.FormHelperText error={!!error}>{error}</MUI.FormHelperText>
              </>
            )}
          />

          <MLab.LoadingButton
            startIcon={<MIcons.Login />}
            loading={loading}
            loadingPosition="start"
            type="submit"
            className="w100"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {MZ.T("login")}
          </MLab.LoadingButton>

          <MUI.Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <MUI.Button
              onClick={() => RouterNavigate("reset-password")}
              variant="text"
            >
              {MZ.T("forgot-password")}
            </MUI.Button>
            <MyTranslationSelect />
          </MUI.Stack>
        </MUI.Box>
      </MUI.Card>
      <MUI.Link href="https://demo.beviapp.com/" target="_blank">
        <MIcons.Launch /> {MZ.T("demo")}
      </MUI.Link>
    </MUI.Container>
  );
};

export default MyLogin;
