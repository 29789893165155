// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// models
import * as MZ from "../models/MZ";
//==============================
const MyFilePicker = (props) => {
  const fileInput = React.useRef();
  const [mediaUrl, mediaUrlSet] = React.useState(null);
  const [file, fileSet] = React.useState(null);

  return (
    <MUI.Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        width: !props.fullWidth ? "auto" : "100%",
        borderWidth: 1,
        borderColor: "primary.light",
        borderStyle: "solid",
        borderRadius: 1,
        overflow: "hidden",
        ":hover": {
          borderColor: "primary.main",
        },
        ...props.sx,
      }}
    >
      <MUI.Typography>
        {file && mediaUrl ? (
          <img
            src={mediaUrl}
            alt={file.name}
            width="100"
            style={{ objectFit: "contain" }}
          />
        ) : null}
      </MUI.Typography>
      <MUI.Typography
        sx={{
          display: "inline-flex",
          flexGrow: 1,
          px: 2,
          alignItems: "center",
        }}
      >
        {file ? file.name : props.placeholder ?? "Choose File"}
      </MUI.Typography>
      {file ? (
        <MUI.IconButton
          onClick={(evt) => {
            fileInput.current.value = "";
            fileSet(null);
            mediaUrlSet(null);
            if (props.onChange) {
              props.onChange({ target: fileInput.current }, null);
            }
          }}
          children={<MIcons.Close />}
        />
      ) : null}
      <MUI.Button
        variant="contained"
        component="label"
        sx={{
          boxShadow: "none",
          borderRadius: 0,
        }}
        {...props.buttonProps}
      >
        {props.label ?? "Choose File"}
        <input
          ref={fileInput}
          type="file"
          id={props.id}
          name={props.name}
          accept={props.accept}
          onChange={(evt) => {
            fileSet(evt.target.files[0]);
            mediaUrlSet(
              evt.target.files[0]
                ? evt.target.files[0].type.indexOf("images/") === 0
                  ? URL.createObjectURL(evt.target.files[0])
                  : null
                : null
            );
            if (props.onChange) props.onChange(evt, evt.target.files[0]);
          }}
          hidden
        />
      </MUI.Button>
    </MUI.Box>
  );
};
MyFilePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
  buttonProps: PropTypes.object,
};
export default MyFilePicker;
