// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
// layouts
import MyPage from "../layouts/MyPage";
// components
import MyFormDialog from "../components/MyFormDialog";
import MyDataTable from "../components/MyDataTable";

const MyUsers = () => {
  // constants
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  const RouterParams = Router.useParams();
  // ui
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [dependants, setDependants] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const [editEventData, setEditEventData] = React.useState(null);

  // check if logged in
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
    (async () => {
      // data
      var r = await dependantsAPI();
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        setDependants(r.data["data"]);
      }
    })();
    getData();
  }, []);

  async function getData() {
    // data
    var r = await getAPI();
    setLoading(false);
    console.log(r);
    if (r.success === false) {
      setApiError(r.message);
    } else {
      setApiError(null);
      setData(r.data["data"]["users"]);
    }
  }

  return (
    <MyPage>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MyDataTable
        sx={{ p: 1 }}
        small={true}
        loading={loading}
        title={MZ.T("users")}
        titleActions={[
          {
            icon: "",
            text: MZ.T("add"),
            props: { variant: "outlined" },
            onClick: () => setAddFormOpen(true),
          },
        ]}
        columns={[
          ...(["corporate"].includes(RouterParams.portal)
            ? [
                {
                  key: "property_id",
                  head: MZ.T("property"),
                  value: (k, v) => v.property_name ?? "-",
                },
              ]
            : []),
          ...(["corporate", "property"].includes(RouterParams.portal)
            ? [
                {
                  key: "outlet_id",
                  head: MZ.T("outlet"),
                  value: (k, v) => v.outlet_name ?? "-",
                },
              ]
            : []),
          {
            key: "name",
            head: MZ.T("name"),
            value: (k, v) => v?.name,
          },
          {
            key: "email",
            head: MZ.T("email"),
            value: (k, v) => v?.email,
          },
          {
            key: "telephone",
            head: MZ.T("telephone"),
            value: (k, v) => v?.telephone ?? "-",
          },
          {
            key: "banned",
            head: MZ.T("banned"),
            value: (k, v) => (v?.banned == 1 ? "Banned" : "Unbanned"),
          },
          {
            key: "addstamp",
            head: MZ.T("added-date"),
            value: (k, v) => v?.addstamp,
          },
          {
            key: "updatestamp",
            head: MZ.T("last-modified"),
            value: (k, v) => v?.updatestamp,
          },
        ]}
        actions={[
          {
            icon: <MIcons.Edit color="success" fontSize="small" />,
            text: "",
            props: { variant: "outlined" },
            onClick: (k, v) => setEditEventData(v),
          },
          {
            icon: <MIcons.Lock color="info" fontSize="small" />,
            text: "",
            props: { variant: "outlined" },
            onClick: (k, v) => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm(
                  `Are you sure you want to reset password for '${v.name}' ?`
                )
              ) {
                resetAPI(v["email"]);
              }
            },
          },
          {
            icon: <MIcons.Delete color="error" fontSize="small" />,
            text: "",
            props: { variant: "outlined" },
            onClick: async (k, v) => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm(`Are you sure you want to delete '${v.name}' ?`)
              ) {
                await deleteAPI(v["id"]);
                getData();
              }
            },
          },
        ]}
        data={data ?? []}
      />
      <MyManageForm
        open={addFormOpen}
        setOpen={setAddFormOpen}
        loading={loading}
        setLoading={setLoading}
        apiError={apiError}
        dependants={dependants}
        onSubmit={() => {
          getData();
        }}
      />
      <MyManageForm
        open={!!editEventData}
        setOpen={() => setEditEventData(null)}
        loading={loading}
        setLoading={setLoading}
        apiError={apiError}
        dependants={dependants}
        editData={editEventData}
        onSubmit={() => {
          getData();
        }}
      />
    </MyPage>
  );
};

export default MyUsers;

//============< filterForm
const MyManageForm = (props) => {
  const RouterParams = Router.useParams();
  // form
  var formController = MZ.FormController();
  const [apiError, setApiError] = React.useState(null);
  const [portal, setPortal] = React.useState(
    RouterParams.portal == "corporate"
      ? "ClientCorporate"
      : RouterParams.portal == "property"
      ? "ClientProperty"
      : "ClientOutlet"
  );
  const [outlets, setOutlets] = React.useState([]);
  //
  React.useLayoutEffect(() => {
    if (props.editData) {
      formController.import({
        export: true,
        data: {
          user_id: props.editData["id"],
          portal_id: props.editData["portal_id"],
          property_id: props.editData["property_id"],
          outlet_id: props.editData["outlet_id"],
          name: props.editData["name"],
          email: props.editData["email"],
          telephone: props.editData["telephone"],
          banned: props.editData["banned"],
        },
      });
    }

    if (props.dependants) {
      console.log(props.dependants);
      if (RouterParams.portal == "property") {
        setOutlets(props?.dependants?.outlets);
      }
    }
  }, [props.editData, props.dependants]);
  //
  return (
    <MyFormDialog
      open={props.open}
      title={MZ.T("user")}
      onSubmit={async (form) => {
        if (apiError) setApiError(null);
        if (formController.valid()) {
          if (props.editData) {
            props.setLoading(true);
            var r = await editAPI(formController.toFormData());
            props.setLoading(false);
            if (r.success === false) {
              setApiError(r.message);
            } else {
              if (props.onSubmit) props.onSubmit();
              setApiError(null);
              props.setOpen(false);
            }
          } else {
            props.setLoading(true);
            var r = await addAPI(formController.toFormData());
            props.setLoading(false);
            if (r.success === false) {
              setApiError(r.message);
            } else {
              if (props.onSubmit) props.onSubmit();
              setApiError(null);
              props.setOpen(false);
            }
          }
        }
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Send />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {props.editData ? MZ.T("edit") : MZ.T("add")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {apiError}
        </MUI.Alert>
      )}

      <MZ.InputControllerWidget
        name="user_id"
        formController={formController}
        inputField={(value, setValue, error) => null}
      />

      <MUI.Grid
        container
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        <MUI.Grid item xs={12} sx={{ pb: 2 }}>
          <MZ.InputControllerWidget
            name="portal_id"
            initValue={portal}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.ToggleButtonGroup
                value={value}
                color="primary"
                exclusive
                onChange={(event, val) => {
                  setValue(val);
                  setPortal(val);
                }}
              >
                {["corporate"].includes(RouterParams.portal) ? (
                  <MUI.ToggleButton
                    value="ClientCorporate"
                    children={MZ.T("corporate")}
                  />
                ) : null}

                {["corporate", "property"].includes(RouterParams.portal) ? (
                  <MUI.ToggleButton
                    value="ClientProperty"
                    children={MZ.T("property")}
                  />
                ) : null}

                <MUI.ToggleButton
                  value="ClientOutlet"
                  children={MZ.T("outlet")}
                />
              </MUI.ToggleButtonGroup>
            )}
          />
        </MUI.Grid>

        {["ClientProperty", "ClientOutlet"].includes(portal) &&
        ["corporate"].includes(RouterParams.portal) ? (
          <MUI.Grid item xs={12} sm={6} md={4}>
            <MZ.InputControllerWidget
              name="property_id"
              formController={formController}
              inputField={(value, setValue, error) => (
                <>
                  <MUI.TextField
                    className="w100"
                    label={MZ.T("property")}
                    name="property"
                    value={value}
                    onChange={(evt) => {
                      setValue(evt.target.value);

                      let arr = [];
                      for (let obj of props?.dependants?.outlets) {
                        if (obj["property_id"] === evt.target.value)
                          arr.push(obj);
                      }
                      formController.setValue("outlet_id", null, "");
                      setOutlets(arr);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    select={true}
                    children={MZ.ForEach(
                      props.dependants.properties ?? [],
                      (k, v) => (
                        <MUI.MenuItem
                          key={`FCO-${k}`}
                          value={v["id"]}
                          children={v["name"]}
                        />
                      )
                    )}
                  />
                </>
              )}
            />
          </MUI.Grid>
        ) : null}

        {["ClientOutlet"].includes(portal) &&
        ["corporate", "property"].includes(RouterParams.portal) ? (
          <MUI.Grid item xs={12} sm={6} md={4}>
            <MZ.InputControllerWidget
              name="outlet_id"
              formController={formController}
              inputField={(value, setValue, error) => (
                <>
                  <MUI.TextField
                    className="w100"
                    label={MZ.T("outlet")}
                    name="outlet_id"
                    value={value}
                    onChange={(evt) => setValue(evt.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    select={true}
                    children={MZ.ForEach(outlets, (k, v) => (
                      <MUI.MenuItem
                        key={`FCO-${k}`}
                        value={v["id"]}
                        children={v["name"]}
                      />
                    ))}
                  />
                </>
              )}
            />
          </MUI.Grid>
        ) : null}

        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="name"
            initValue={null}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("name")}
                name="name"
                type="text"
                required
                value={value}
                onChange={(evt) => {
                  setValue(evt.target.value);
                  formController.setValue("portal_id", null, "ClientProperty");
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="email"
            initValue={null}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("email")}
                name="email"
                type="email"
                required
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="telephone"
            initValue={null}
            formController={formController}
            inputField={(value, setValue, error) => (
              <MUI.TextField
                className="w100"
                label={MZ.T("telephone")}
                name="telephone"
                type="tel"
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={6}>
          <MZ.InputControllerWidget
            name="banned"
            formController={formController}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  className="w100"
                  label={MZ.T("banned")}
                  name="banned"
                  value={value}
                  onChange={(evt) => setValue(evt.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select={true}
                  children={MZ.ForEach(
                    [
                      { v: 0, l: "unbanned" },
                      { v: 1, l: "banned" },
                    ],
                    (k, v) => (
                      <MUI.MenuItem
                        key={`FCO-${k}`}
                        value={v["v"]}
                        children={v["l"]}
                      />
                    )
                  )}
                />
              </>
            )}
          />
        </MUI.Grid>
      </MUI.Grid>
    </MyFormDialog>
  );
};

// get transactions
async function dependantsAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/users/dependants", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function getAPI() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/users/get", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function addAPI(fData = new FormData()) {
  try {
    //========================= validate data
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/users/add", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function editAPI(fData = new FormData()) {
  try {
    //========================= validate data
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/users/edit", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function deleteAPI(user_id) {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    fData.set("user_id", user_id);
    //========================= request
    return await MyAppM.exabyteRequest("client/users/delete", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function resetAPI(email) {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    fData.set("email", email);
    //========================= request
    return await MyAppM.exabyteRequest("client/access/reset-password", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}
