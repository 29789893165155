// packages
import React from "react";
import PropTypes from "prop-types";
import { Colors } from "chart.js";
//============================== key
var Key = 0;
export const GetKey = () => {
  Key++;
  return Key;
};
//============================== controllers
export function FormController() {
  const [firstSubmited, setFirstSubmited] = React.useState(false);
  var groups = {};
  var controllers = {};
  var importedData = {};

  return {
    groups: groups,
    controllers: controllers,
    firstSubmited: firstSubmited,
    importedData: importedData,
    addGroup: (GroupController) => {
      if (GroupController.index > -1) {
        if (!Array.isArray(groups[GroupController.name]))
          groups[GroupController.name] = [];

        groups[GroupController.name][GroupController.index] =
          GroupController;
      } else {
        groups[GroupController.name] = GroupController;
      }
      return GroupController;
    },
    addInput: (InputController) => {
      if (InputController.index > -1) {
        if (!Array.isArray(controllers[InputController.name]))
          controllers[InputController.name] = [];

        controllers[InputController.name][InputController.index] =
          InputController;
      } else {
        controllers[InputController.name] = InputController;
      }
      if (groups[InputController.name]) {
        if (Array.isArray(groups[InputController.name])) {
          for (let i in groups[InputController.name]) groups[InputController.name][i].setValues();
        } else groups[InputController.name].setValues();
      }
      return InputController;
    },
    setValue: (name, index = null, value) => {
      if (controllers[name]) {
        if (index && Array.isArray(controllers[name])) {
          controllers[name][index].setValue(value);
        } else if (!index && !Array.isArray(controllers[name])) {
          controllers[name].setValue(value);
        }
      }
      return undefined;
    },
    setValues: (name, value) => {
      if (controllers[name]) {
        if (Array.isArray(controllers[name])) {
          for (let i in controllers[name]) controllers[name][i].setValue(value);
        } else {
          controllers[name].setValue(value);
        }
      }
      return undefined;
    },
    setIndexedValues: (name, startIndex, endIndex, value) => {
      if (controllers[name]) {
        if (Array.isArray(controllers[name])) {
          for (let i in controllers[name]) {
            if (startIndex <= i && i <= endIndex) controllers[name][i].setValue(value);
          }
        } else {
          controllers[name].setValue(value);
        }
      }
      return undefined;
    },
    getValue: (name, index = null) => {
      if (controllers[name]) {
        if (index && Array.isArray(controllers[name])) {
          return controllers[name][index].value;
        } else if (!index && !Array.isArray(controllers[name])) {
          return controllers[name].value;
        }
      }
      return undefined;
    },
    getValues: (name) => {
      if (controllers[name]) {
        if (Array.isArray(controllers[name])) {
          let obj = [];
          for (let i in controllers[name]) {
            obj.push(controllers[name][i].value);
          }
          return obj;
        } else {
          return controllers[name].value;
        }
      }
      return undefined;
    },
    valid: () => {
      if (!firstSubmited) setFirstSubmited(true);
      let valid = true;
      for (let k in controllers) {
        if (Array.isArray(controllers[k])) {
          for (let i in controllers[k]) {
            if (controllers[k][i].valid(true) === false) valid = false;
          }
        } else {
          if (controllers[k].valid(true) === false) valid = false;
        }
      }
      return valid;
    },
    toObj: (valuesOnly = false, changedOnly = false) => {
      let obj = {};
      for (let k in controllers) {
        if (Array.isArray(controllers[k])) {
          for (let i in controllers[k]) {
            if (!obj[k]) obj[k] = [];
            if (
              (!valuesOnly || (valuesOnly && controllers[k][i].value)) &&
              (!changedOnly || (changedOnly && controllers[k][i].changed()))
            ) {
              if (controllers[k][i].staticValue) {
                if (controllers[k][i].value)
                  obj[k].push(controllers[k][i].staticValue);
              } else obj[k].push(controllers[k][i].value);
            }
          }
        } else {
          if (
            (!valuesOnly || (valuesOnly && controllers[k].value)) &&
            (!changedOnly || (changedOnly && controllers[k].changed()))
          ) {
            if (controllers[k].staticValue) {
              if (controllers[k].value) obj[k] = controllers[k].staticValue;
            } else obj[k] = controllers[k].value;
          }
        }
      }
      return obj;
    },
    toFormData: (valuesOnly = false, changedOnly = false) => {
      let fdata = new FormData();
      for (let k in controllers) {
        if (Array.isArray(controllers[k])) {
          for (let i in controllers[k]) {
            if (
              (!valuesOnly || (valuesOnly && controllers[k][i].value)) &&
              (!changedOnly || (changedOnly && controllers[k][i].changed()))
            ) {
              if (controllers[k][i].staticValue) {
                if (controllers[k][i].value)
                  fdata.append(`${k}[]`, controllers[k][i].staticValue);
              } else fdata.append(`${k}[]`, controllers[k][i].value);
            }
          }
        } else {
          if (
            (!valuesOnly || (valuesOnly && controllers[k].value)) &&
            (!changedOnly || (changedOnly && controllers[k].changed()))
          ) {
            if (controllers[k].staticValue) {
              if (controllers[k].value)
                fdata.set(k, controllers[k].staticValue);
            } else fdata.set(k, controllers[k].value);
          }
        }
      }
      return fdata;
    },
    export: () => {
      let obj = {};
      for (let k in controllers) {
        if (Array.isArray(controllers[k])) {
          obj[k] = {};
          for (let i in controllers[k]) {
            obj[k][i] = controllers[k][i].value;
          }
        } else {
          obj[k] = controllers[k].value;
        }
      }
      return { export: true, data: obj };
    },
    import: (exportObj) => {
      if (!exportObj || !exportObj.data) return;
      for (let k in exportObj.data) {
        importedData[k] = exportObj.data[k];
      }
      // for (let k in exportObj.data) {
      //   if (!controllers[k]) continue;
      //   if (Array.isArray(controllers[k]) && Array.isArray(exportObj.data[k])) {
      //     for (let i in exportObj.data[k]) {
      //       if (!controllers[k][i]) continue;
      //       controllers[k][i].setValue(exportObj.data[k][i]);
      //     }
      //   } else if (!Array.isArray(exportObj.data[k])) {
      //     controllers[k].setValue(exportObj.data[k]);
      //   }
      // }
    },
  };
}
// group
export function GroupController({ name = "", index = -1, startIndex = null, endIndex = null, formController = {} }) {
  const [values, setValues] = React.useState({});

  return formController.addGroup({
    formController: formController,
    name: name,
    values: values,
    index: index ?? -1,
    startIndex: startIndex,
    endIndex: endIndex,
    setValues: () => {
      if (
        formController.controllers[name] &&
        Array.isArray(formController.controllers[name])
      ) {
        let obj = {};
        for (let i in formController.controllers[name]) {
          if ((startIndex == null || endIndex == null) ||
            (startIndex !== null && endIndex !== null && startIndex <= i && i <= endIndex)
          ) {
            obj[i] = formController.getValue(name, i);
          }
        }
        setTimeout(() => setValues(obj), 1);
      }
    },
  });
}
export const GroupControllerWidget = (props) => {
  var controller = GroupController({
    name: props.name,
    formController: props.formController,
    index: props.index ?? -1,
    startIndex: props.startIndex,
    endIndex: props.endIndex,
  });

  return props.inputField(controller.values);
};
GroupControllerWidget.propTypes = {
  name: PropTypes.string.isRequired,
  formController: PropTypes.object.isRequired,
  inputField: PropTypes.func.isRequired,
  index: PropTypes.number,
  startIndex: PropTypes.number,
  endIndex: PropTypes.number,
};
// input
export function InputController({
  name = "",
  formController = {},
  index = -1,
  initValue = "",
  staticValue = null,
  validator = null,
  errorAfterFirstSubmit = false,
  errorAfterFirstChange = false,
}) {
  let iVal = Arr.crawl(formController.importedData, `${name}${index > -1 ? `/${index}` : ""}`);
  const [value, setValue] = React.useState(iVal ?? initValue);
  const [changed, setChanged] = React.useState(false);
  const [error, setError] = React.useState(null);

  return formController.addInput({
    formController: formController,
    name: name,
    index: index,
    value: value,
    initValue: initValue,
    staticValue: staticValue,
    error: error,
    setValue: (value) => {
      setValue(value);
      if (!changed) setChanged(true);
      if (
        (!errorAfterFirstSubmit ||
          (errorAfterFirstSubmit && formController.firstSubmited)) &&
        (!errorAfterFirstChange || (errorAfterFirstChange && changed))
      ) {
        let err = validator ? validator(value, name) : null;
        setError(err);
      }
    },
    setError: setError,
    valid: (firstSubmited) => {
      let err = validator ? validator(value, name) : null;
      if (
        (!errorAfterFirstSubmit || (errorAfterFirstSubmit && firstSubmited)) &&
        (!errorAfterFirstChange || (errorAfterFirstChange && changed))
      ) {
        setError(err);
      }
      return !err;
    },
    changed: () => initValue !== value,
  });
}
export const InputControllerWidget = (props) => {
  var controller = InputController({
    name: props.name,
    formController: props.formController,
    index: props.index ?? -1,
    initValue: props.initValue ?? "",
    staticValue: props.staticValue,
    validator: props.validator,
    errorAfterFirstSubmit: props.errorAfterFirstSubmit ?? false,
    errorAfterFirstChange: props.errorAfterFirstChange ?? false,
  });

  React.useEffect(() => {
    if (controller.formController.groups[controller.name]) {
      if (Array.isArray(controller.formController.groups[controller.name])) {
        for (let i in controller.formController.groups[controller.name]) controller.formController.groups[controller.name][i].setValues();
      } else controller.formController.groups[controller.name].setValues();
    }
  }, [controller.value, controller.name, controller.formController]);

  return props.inputField(
    controller.value,
    controller.setValue,
    controller.error
  );
};
InputControllerWidget.propTypes = {
  name: PropTypes.string.isRequired,
  formController: PropTypes.object.isRequired,
  inputField: PropTypes.func.isRequired,
  index: PropTypes.number,
  initValue: PropTypes.any,
  staticValue: PropTypes.any,
  validator: PropTypes.func,
  errorAfterFirstSubmit: PropTypes.bool,
  errorAfterFirstChange: PropTypes.bool,
};
//========================= validators
export const ValidateText = (
  required = false,
  min = null,
  max = null,
  useTransation = false
) => {
  return (value, name) => {
    // check required
    if (!required && !value) return null;
    // check empty value if required
    if (required && !value) {
      return useTransation
        ? Translation.get(`${name ?? "value"}=required`)
        : `${name ?? "value"}=required`;
    }
    if (required || (!required && value)) {
      if (min && value.length < min) {
        return useTransation
          ? Translation.get(`${name ?? "value"}=under_min_length`, [min])
          : `${name ?? "value"}=under_min_length=${min}`;
      }
      if (max && value.length > max) {
        return useTransation
          ? Translation.get(`${name ?? "value"}=above_max_length`, [max])
          : `${name ?? "value"}=above_max_length=${max}`;
      }
    }
    return null;
  };
};
export const ValidateEmail = (
  required = false,
  min = null,
  max = null,
  useTransation = false
) => {
  return (value, name) => {
    // check text
    let err = ValidateText(required, min, max, useTransation)(value, name);
    if (err) return err;
    // email regec
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return useTransation
        ? Translation.get(`${name ?? "value"}=invalid`)
        : `${name ?? "value"}=invalid`;
    }
    return null;
  };
};
//============================== translation
// class
export class Translation {
  static locale;
  static localeDir;
  static locales = {};
  static fillerTag = null;
  static processKey = null;
  // init
  static init(fillerTag = null, processKey = null) {
    try {
      Translation.fillerTag = fillerTag;
      Translation.processKey = processKey;

      Translation.locale = Cookie.get("locale");
      let locales = Object.keys(Translation.locales);
      if (!Translation.locale || !locales.includes(Translation.locale)) {
        let browser = (
          window.navigator.userLanguage || window.navigator.language
        ).split("-")[0];
        if (locales.includes(browser)) Translation.locale = browser;
        else Translation.locale = locales[0];
      }
      Translation.localeDir = Translation.locales[Translation.locale].dir;
      document.dir = Translation.localeDir;
      document.body.setAttribute("dir", Translation.getDir());
      Cookie.set("locale", Translation.locale);
    } catch (e) { }
  }
  // add locale before init
  static addLocale(code, name, rtl, data) {
    try {
      Translation.locales[code] = {
        code: code,
        name: name,
        dir: rtl ? "rtl" : "ltr",
        data: data,
      };
    } catch (e) { }
  }
  // change
  static changeLocale(code) {
    try {
      if (Translation.locales[code]) {
        Cookie.set("locale", code);
        window.location.reload(false);
      }
    } catch (e) { }
  }
  // translation
  static get(key, fillers = null) {
    try {
      if (Translation.processKey) key = Translation.processKey(key);
      if (Translation.locales && Translation.locales[Translation.locale]) {
        let str = Translation.locales[Translation.locale].data[key] ?? "N/A";
        if (Translation.fillerTag && fillers) {
          let i = 0;
          while (str.includes(Translation.fillerTag)) {
            str = str.replace(Translation.fillerTag, fillers[i] ?? "");
            i++;
          }
        }
        return str;
      }
      return "N/A";
    } catch (e) {
      return "N/A";
    }
  }
}
// shortcut function
export const T = Translation.get;
//============================== res
export class Response {
  // Response
  success = true;
  message = null;
  data = null;
  constructor(success = true, message = null, data = null) {
    this.success = success;
    this.message = message ? message.toString() : null;
    this.data = data;
  }

  static success(message, data) {
    return new Response(true, message, data);
  }
  static error(message, data) {
    return new Response(false, message, data);
  }
}
// shortcut function
export const Success = Response.success;
export const Error = Response.error;
//============================== cookie
export class Cookie {
  static get(name, base64Decode = false, jsonParse = false) {
    try {
      var value = null;
      var list = decodeURIComponent(document.cookie).split(";");
      //separate
      var map = {};
      for (var str of list) {
        var pair = str.trim().split("=");
        var val = pair.join("=").split(`${pair[0]}=`)[1];
        if (map.hasOwnProperty(pair[0])) {
          if (!Array.isArray(map[pair[0]])) map[pair[0]] = [map[pair[0]]];
          map[pair[0]].push(val);
        } else {
          map[pair[0]] = val;
        }
      }
      value = map[name];
      // if decode
      if (value != null && base64Decode === true) value = window.atob(value);
      // if jsonparse
      if (value != null && jsonParse === true) value = JSON.parse(value);
      //
      return value;
    } catch (err) {
      console.warn("mzCookies", "get", err);
      return undefined;
    }
  }
  static set(
    name,
    value,
    base64Encode = false,
    jsonStringify = false,
    expiryHours = null
  ) {
    try {
      if (jsonStringify === true) value = JSON.stringify(value);
      if (base64Encode === true) value = window.btoa(value);
      if (expiryHours) {
        var d = new Date(Date.now() + expiryHours * 3600000);
        document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;
      } else document.cookie = `${name}=${value};path=/`;
      return true;
    } catch (err) {
      console.warn("mzCookies", "set", err);
      return false;
    }
  }
  static del(name) {
    try {
      var d = new Date(Date.now() / 2);
      document.cookie = `${name}=${null};expires=${d.toUTCString()};path=/`;
      return true;
    } catch (err) {
      console.warn("mzCookies", "delete", err);
      return false;
    }
  }
}
//============================== storage
export class Storage {
  // Session
  static getSession(name, base64Decode = false, jsonParse = false) {
    try {
      var value = sessionStorage.getItem(name);
      // if decode
      if (value != null && base64Decode === true) value = window.atob(value);
      // if jsonparse
      if (value != null && jsonParse === true) value = JSON.parse(value);
      //
      return value;
    } catch (err) {
      console.warn("mzStorage", "getSession", err);
      return undefined;
    }
  }
  static setSession(name, value, base64Encode = false, jsonStringify = false) {
    try {
      if (jsonStringify === true) value = JSON.stringify(value);
      if (base64Encode === true) value = window.btoa(value);
      return sessionStorage.setItem(name, value);
    } catch (err) {
      console.warn("mzStorage", "setSession", err);
      return false;
    }
  }
  static delSession(name) {
    try {
      return sessionStorage.removeItem(name);
    } catch (err) {
      console.warn("mzStorage", "delSession", err);
      return false;
    }
  }
  // Local
  static getLocal(name, base64Decode = false, jsonParse = false) {
    try {
      var value = localStorage.getItem(name);
      // if decode
      if (value != null && base64Decode === true) value = window.atob(value);
      // if jsonparse
      if (value != null && jsonParse === true) value = JSON.parse(value);
      //
      return value;
    } catch (err) {
      console.warn("mzStorage", "getLocal", err);
      return undefined;
    }
  }
  static setLocal(name, value, base64Encode = false, jsonStringify = false) {
    try {
      if (jsonStringify === true) value = JSON.stringify(value);
      if (base64Encode === true) value = window.btoa(value);
      return localStorage.setItem(name, value);
    } catch (err) {
      console.warn("mzStorage", "setLocal", err);
      return false;
    }
  }
  static delLocal(name) {
    try {
      return localStorage.removeItem(name);
    } catch (err) {
      console.warn("mzStorage", "delLocal", err);
      return false;
    }
  }
}
//============================== arr
export class Arr {
  static sum(array, valueKey) {
    try {
      if (!(array instanceof Array) && typeof array != "object") return 0;
      let n = 0;
      for (let i in array) {
        if (valueKey) n += parseFloat(array[i][valueKey] ?? 0);
        else n += parseFloat(array[i] ?? 0);
      }
      return n;
    } catch (err) {
      return NaN;
    }
  }
  static sumKeys(array, valueKeys) {
    try {
      if (
        (!(array instanceof Array) && typeof array != "object") ||
        !(valueKeys instanceof Array)
      )
        return undefined;
      // create object
      let obj = {};
      for (let key of valueKeys) obj[key] = 0;
      // sum
      for (let i in array) {
        for (let key of valueKeys) {
          if (array[i][key]) obj[key] += parseFloat(array[i][key]) ?? 0;
        }
      }
      return obj;
    } catch (err) {
      return undefined;
    }
  }
  static sumKeysIf(array, valueKeys, condition = (value) => null) {
    try {
      if (
        !(array instanceof Array) ||
        !(valueKeys instanceof Array) ||
        !condition
      )
        return undefined;
      // create object
      let obj = {};
      for (let key of valueKeys) obj[key] = 0;
      // sum
      for (let i in array) {
        if (condition(array[i])) {
          for (let key of valueKeys) {
            if (array[i][key]) obj[key] += parseFloat(array[i][key]) ?? 0;
          }
        }
      }
      return obj;
    } catch (err) {
      return undefined;
    }
  }
  static sumKeysIfs(
    array,
    objectKeys = ["obj1"],
    valueKeys = [],
    condition = (value) => "obj1"
  ) {
    try {
      if (
        !(array instanceof Array) ||
        !(objectKeys instanceof Array) ||
        !(valueKeys instanceof Array) ||
        !condition
      )
        return undefined;
      // create object
      let obj = {};
      for (let o of objectKeys) {
        obj[o] = {};
        for (let v of valueKeys) {
          obj[o][v] = 0;
        }
      }
      // sum
      for (let i in array) {
        let keys = condition(array[i]);
        for (let o in keys) {
          if (obj[o]) {
            for (let key of valueKeys) {
              if (array[i][key]) obj[o][key] += parseFloat(array[i][key]) ?? 0;
            }
          }
        }
      }
      return obj;
    } catch (err) {
      return undefined;
    }
  }
  static sort(
    array = null,
    reverse = false,
    property = null,
    process = (v) => v
  ) {
    try {
      if (!(array instanceof Array)) return undefined;
      return array.sort(function (a, b) {
        let rev = reverse === true ? -1 : 1;
        if (property) {
          a = a[property];
          b = b[property];
        }
        //
        if (process) {
          a = process(a);
          b = process(b);
        }
        //
        if (a < b) return -1 * rev;
        if (a > b) return 1 * rev;
        return 0;
      });
    } catch (err) {
      return undefined;
    }
  }
  static objToArr(object, addKeyAs) {
    let arr = [];
    if (typeof object != "object")
      return arr;
    for (let k in object) {
      let o = object[k];
      if (addKeyAs) o[addKeyAs] = k;
      arr.push(o)
    }
    return arr;
  }
  static filter({ array, filterData, broad = false, broadSearches = [] }) {
    try {
      if (!(array instanceof Array) || typeof filterData != "object")
        return undefined;
      let list = [];
      for (var obj of array) {
        let match = true;
        let points = 0;
        for (var k in filterData) {
          if (filterData[k]) {
            if (Array.isArray(filterData[k])) {
              let m = true;
              for (let v of filterData[k]) {
                if (v) {
                  if (
                    (broadSearches.includes(k) && obj[k].indexOf(v) > -1) ||
                    (!broadSearches.includes(k) && obj[k] === v)
                  ) {
                    points++;
                    m = true;
                    break;
                  } else m = false;
                }
              }
              if (!m) match = false;
            } else {
              if (
                (broadSearches.includes(k) &&
                  obj[k].indexOf(filterData[k]) > -1) ||
                (!broadSearches.includes(k) && obj[k] === filterData[k])
              ) {
                points++;
              } else match = false;
            }
          }
        }
        if (broad && points > 0) {
          obj["MZFILTERPOINTS"] = points;
          list.push(obj);
        } else if (!broad && match) list.push(obj);
      }
      if (broad) list = Arr.sort(list, false, "MZFILTERPOINTS");
      return list;
    } catch (err) {
      return array;
    }
  }
  static crawl(array, key, fallback = undefined, sep = "/") {
    try {
      if (!(array instanceof Array) && typeof array != "object")
        return fallback;
      let k = key.split(sep);
      let v = array.hasOwnProperty(k[0]) ? array[k[0]] : null;
      for (let i = 1; i < k.length; i++) {
        if (v && v.hasOwnProperty(k[i])) {
          v = v[k[i]];
        } else v = null;
      }
      return (![null, undefined, NaN].includes(v) ? v : fallback);
    } catch (err) {
      return undefined;
    }
  }
  static generate(length, callback = (i, arr) => null) {
    try {
      let arr = [];
      for (let i = 0; i < length; i++) {
        let v = callback(i, arr);
        if (v) arr.push(v);
      }
      return arr;
    } catch (err) {
      return undefined;
    }
  }
  static forEach(array, callback = (key, value, arr) => null) {
    try {
      if (!(array instanceof Array) && typeof array != "object") return [];
      let arr = [];
      for (let k in array) {
        let v = callback(k, array[k], arr);
        if (v !== undefined) arr.push(v);
      }
      return arr;
    } catch (err) {
      return undefined;
    }
  }
  static countIf(array, condition = (k, v) => null) {
    try {
      if (!(array instanceof Array) && typeof array != "object") return [];
      let i = 0;
      for (let k in array) {
        if (condition(k, array[k])) i++;
      }
      return i;
    } catch (err) {
      return undefined;
    }
  }
  static length(any) {
    try {
      if (!any) return undefined;
      if (typeof any != "object") return Object.keys(any).length;
      if (Array.isArray(any)) return any.length;
      return any.toString().length;
    } catch (err) {
      return undefined;
    }
  }
  static arrayToTree(flatArr, childValue, childrenKey, parentKey, childKey) {
    try {
      let arr = [];
      // loop data
      // get key of arr/obj
      for (let i in flatArr) {
        // easy access
        let obj = JSON.parse(JSON.stringify(flatArr[i]));
        // check if in parent
        if (obj[childKey] === childValue) {
          // if in parent get children of current
          let arr2 = Arr.arrayToTree(
            flatArr,
            obj[parentKey],
            childrenKey,
            parentKey,
            childKey
          );
          if (arr2.length > 0) obj[childrenKey] = arr2;
          // push onto big tree after finding Children
          arr.push(obj);
        }
      }
      // return arr
      return arr;
    } catch (err) {
      return undefined;
    }
  }
  static arrayFromTree(treeArr, childrenKey) {
    try {
      let arr = [];
      // loop data
      // get key of arr/obj
      for (let i in treeArr) {
        // easy access
        let obj = JSON.parse(JSON.stringify(treeArr[i]));
        // check has children
        if (obj[childrenKey]) {
          // loop children to get flat ass
          let arr2 = Arr.arrayFromTree(obj[childrenKey], childrenKey);
          // append children to big array
          for (let i2 in arr2) arr.push(arr2[i2]);
        }
        // delete unwanted key
        delete obj[childrenKey];
        arr.push(obj);
      }
      // return arr
      return arr;
    } catch (err) {
      return undefined;
    }
  }
  static sumTreeArray(treeArr, childrenKey, valueKey, outputKey) {
    try {
      let arr = JSON.parse(JSON.stringify(treeArr));
      // loop data
      // get key of arr/obj
      for (let i in arr) {
        // check if in parent
        // check sumkey
        arr[i][outputKey] = parseFloat(arr[i][valueKey] ?? 0);
        // check if has children
        if (arr[i][childrenKey]) {
          // get sums from children
          arr[i][childrenKey] = Arr.sumTreeArray(
            arr[i][childrenKey],
            childrenKey,
            valueKey,
            outputKey
          );
          // add sums to parent
          for (let c in arr[i][childrenKey])
            arr[i][outputKey] += parseFloat(
              arr[i][childrenKey][c][outputKey] ?? 0
            );
        }
      }
      // return arr
      return arr;
    } catch (err) {
      return undefined;
    }
  }
}
// shortcut function
export const Crawl = Arr.crawl;
export const Generate = Arr.generate;
export const ForEach = Arr.forEach;
export const CountIf = Arr.countIf;
export const Length = Arr.length;
//============================== math
export class Num {
  static loop(value, step, min, max) {
    try {
      value = value + step;
      if (value > max) {
        value -= max - min + 1;
      } else if (value < min) {
        value += max - min + 1;
      }
      return value;
    } catch (err) {
      return undefined;
    }
  }
  static clamp(value, min, max) {
    try {
      return Math.min(max, Math.max(value, min));
    } catch (err) {
      return undefined;
    }
  }
  static percent(value, max, multiplier = 100) {
    try {
      return (value / max) * multiplier;
    } catch (err) {
      return undefined;
    }
  }
  static fromPercent(value, min = 0, max = 0, multiplier = 100) {
    try {
      return (max - min) * (value / multiplier);
    } catch (err) {
      return undefined;
    }
  }
  static round(value, percision = 0) {
    try {
      if (isNaN(value)) return;
      return +parseFloat(value).toFixed(percision);
    } catch (err) {
      return undefined;
    }
  }
  static readable(value, percision = 0, seperator = ",") {
    try {
      if (typeof value !== "number" || isNaN(value)) return value.toString();
      //
      let num = Math.abs(Num.round(value, percision)).toString().split(".");
      let str = num[0] ?? "0";
      let dec = num[1] ?? "0";
      let res = "";
      //
      if (str.length > 3) {
        let arr = [];
        for (let i = str.length; i > 0; i = i - 3)
          arr.push(str.slice(i - 3 < 0 ? 0 : i - 3, i));
        arr = arr.reverse();
        // 
        res = arr.join(seperator);
      } else res = str;
      // add sign
      if (Math.sign(value) < 0) res = `-${res}`;
      // add decimals
      if (percision > 0) res = `${res}.${Str.pad(dec, percision, "0", false)}`;
      //
      return res.toString();
    } catch (err) {
      return NaN;
    }
  }
  static random(min = 0, max = 1) {
    return Math.random() * (max - min + 1) + min;
  }
}
// shortcut function
export const Round = Num.round;
export const Readable = Num.readable;
export const Random = Num.random;
//============================== str
export class Str {
  static pad(value, length, padChar = "0", left = true) {
    try {
      let arr = Array(Math.abs(length) - String(value).length + 1).join(
        padChar || "0"
      );
      if (left) return arr + value;
      else return value + arr;
    } catch (err) {
      return value;
    }
  }
}
//============================== date
// const
const MonthsTextShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const MonthsTextLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const WeekdaysTextShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const WeekdaysTextLong = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const DateTimes = {
  unix: (date) => date.date.getTime(),
  Y: (date) => date.year(true),
  Ym: (date) => +`${DateTimes.Y(date)}${date.month(true)} `,
  Ymd: (date) => +`${DateTimes.Ym(date)}${date.day(true)} `,
  YmdH: (date) => +`${DateTimes.Ymd(date)}${date.hour(true)} `,
  YmdHi: (date) => +`${DateTimes.YmdH(date)}${date.minute(true)} `,
  YmdHis: (date) => +`${DateTimes.YmdHi(date)}${date.second(true)} `,
  YW: (date) => +`${DateTimes.Y(date)}${date.week(null, true)} `,
  YWw: (date) => +`${DateTimes.YW(date)}${date.weekday()} `,
  YWwH: (date) => +`${DateTimes.YWw(date)}${date.hour(true)} `,
  YWwHi: (date) => +`${DateTimes.YWwH(date)}${date.minute(true)} `,
  YWwHis: (date) => +`${DateTimes.YWwHi(date)}${date.second(true)} `,
};
export const DateIds = {
  unix: "unix",
  Y: "Y",
  Ym: "Ym",
  Ymd: "Ymd",
  YmdH: "YmdH",
  YmdHi: "YmdHi",
  YmdHis: "YmdHis",
  YW: "YW",
  YWw: "YWw",
  YWwH: "YWwH",
  YWwHi: "YWwHi",
  YWwHis: "YWwHis",
};
const DateFormats = {
  d: (date) => date.monthDay(true),
  D: (date) => date.weekdayText(true),
  j: (date) => date.monthDay(),
  l: (date) => date.weekdayText(),
  w: (date) => date.weekday(),
  W: (date) => date.week(null, true),
  z: (date) => date.yearDay(),
  F: (date) => date.monthText(),
  m: (date) => date.month(true),
  M: (date) => date.monthText(true),
  n: (date) => date.month(),
  t: (date) => date.monthDays(),
  L: (date) => date.leapYear(true),
  Y: (date) => date.year(true),
  y: (date) => date.year2(true),
  a: (date) => date.timePeriod(),
  A: (date) => date.timePeriod(true),
  g: (date) => date.hour12(),
  G: (date) => date.hour(),
  h: (date) => date.hour12(true),
  H: (date) => date.hour(true),
  i: (date) => date.minute(true),
  s: (date) => date.second(true),
  e: (date) => date.timezone(),
  E: (date) => date.timezone(true),
  O: (date) => date.timezoneOffset(),
};
// class
export class DateTime {
  // date
  date;
  SOW = null;
  constructor(/**/) {
    try {
      let args = arguments;
      if (args.length === 0) this.date = new Date(Date.now());
      else if (args.length === 1) {
        if (args[0] instanceof DateTime) this.date = new Date(args[0].date);
        else this.date = new Date(args[0]);
      } else if (args.length > 1)
        this.date = new Date(
          args[0] ?? 0,
          (args[1] ?? 1) - 1,
          args[2] ?? 1,
          args[3] ?? 0,
          args[4] ?? 0,
          args[5] ?? 0,
          args[6] ?? 0
        );
      if (!this.date) return undefined;
    } catch (err) {
      return undefined;
    }
  }
  // misc
  timezone(name = false) {
    try {
      // name
      if (name) return Intl.DateTimeFormat().resolvedOptions().timeZone;
      // normal
      return this.date
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .slice(4);
    } catch (err) {
      return undefined;
    }
  }
  timezoneOffset() {
    try {
      return this.date.getTimezoneOffset();
    } catch (err) {
      return undefined;
    }
  }
  leapYear(numeric = false) {
    return DateTime.isLeapYear(this.year(), numeric);
  }

  static isLeapYear(year, numeric = false) {
    try {
      let v = (((year % 4 === 0) || (year % 400 === 0)) && (year % 1000 !== 0));
      // numeric
      if (numeric) return v ? 1 : 0;
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  setSOW(firstWeekday = null) {
    try {
      // console.log("FWD", this.getFormated(), firstWeekday);
      if (firstWeekday) this.SOW = Num.clamp(firstWeekday, 0, 6);
      return this;
    } catch (err) {
      return undefined;
    }
  }
  // year
  year(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getFullYear(), 4, "0", true);
      // normal
      return this.date.getFullYear();
    } catch (err) {
      return undefined;
    }
  }
  totalYears() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 365);
    } catch (err) {
      return undefined;
    }
  }
  year2(pad = false) {
    try {
      let v = this.year(true).substr(-2, 2) - 0;
      // padded
      if (pad) return Str.pad(v, 2, "0", true);
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  // month
  month(pad = false) {
    try {
      let v = this.date.getMonth() + 1;
      // padded
      if (pad) return Str.pad(v, 2, "0", true);
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  totalMonths() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 30);
    } catch (err) {
      return undefined;
    }
  }
  monthText(short = true) {
    try {
      // short
      if (short) return MonthsTextShort[this.month() - 1];
      //long
      return MonthsTextLong[this.month() - 1];
    } catch (err) {
      return undefined;
    }
  }
  monthDays(pad = false) {
    try {
      let v = new Date(this.year(), this.month(), 0).getDate();
      // padded
      if (pad) return Str.pad(v, 2, "0", true);
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  // month text
  static monthNoText(month = 0, short = true) {
    try {
      // short
      if (short) return MonthsTextShort[month - 1];
      //long
      return MonthsTextLong[month - 1];
    } catch (err) {
      return undefined;
    }
  }
  // week
  week(firstWeekday = null, pad = false) {
    try {
      if (this.SOW && !firstWeekday) firstWeekday = this.SOW;
      if (!firstWeekday) firstWeekday = 0;
      //
      let yfd = this.setMonth(1).setDay(1);
      let wd = yfd.weekday(firstWeekday);
      let d = firstWeekday > wd ? firstWeekday - (wd + 7) : firstWeekday - wd;
      //
      let v = Math.floor(
        (this.getTime() - yfd.addDays(d).getTime()) / 1000 / 60 / 60 / 24 / 7
      );
      // padded
      if (pad) return Str.pad(v, 2, "0", true);
      // normal
      return v;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }
  totalWeeks() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 7);
    } catch (err) {
      return undefined;
    }
  }
  weekFirstDay(firstWeekday = null) {
    try {
      if (this.SOW && !firstWeekday) firstWeekday = this.SOW;
      if (!firstWeekday) firstWeekday = 0;
      return this.addDays(
        firstWeekday > this.weekday()
          ? firstWeekday - (this.weekday() + 7)
          : firstWeekday - this.weekday()
      );
    } catch (err) {
      return undefined;
    }
  }
  // days
  yearDay(pad = false) {
    try {
      let v = Math.floor(
        (this.getTime() - this.setMonth(1).setDay(1).getTime()) /
        1000 /
        60 /
        60 /
        24
      );
      // padded
      if (pad) return Str.pad(v, 3, "0", true);
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  monthDay(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getDate(), 2, "0", true);
      // normal
      return this.date.getDate();
    } catch (err) {
      return undefined;
    }
  }
  day(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getDate(), 2, "0", true);
      // normal
      return this.date.getDate();
    } catch (err) {
      return undefined;
    }
  }
  totalDays() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24);
    } catch (err) {
      return undefined;
    }
  }
  weekday() {
    try {
      return this.date.getDay();
    } catch (err) {
      return undefined;
    }
  }
  weekdayText(short = true) {
    try {
      // short
      if (short) return WeekdaysTextShort[this.weekday()];
      //long
      return WeekdaysTextLong[this.weekday()];
    } catch (err) {
      return undefined;
    }
  }
  // week text
  static weekdayNoText(weekday = 0, short = true) {
    try {
      // short
      if (short) return WeekdaysTextShort[weekday];
      //long
      return WeekdaysTextLong[weekday];
    } catch (err) {
      return undefined;
    }
  }
  // time
  hour(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getHours(), 2, "0", true);
      // normal
      return this.date.getHours();
    } catch (err) {
      return undefined;
    }
  }
  totalHours() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60 / 60);
    } catch (err) {
      return undefined;
    }
  }
  hour12(pad = false) {
    try {
      let v = this.hour() % 12 || 12;
      // padded
      if (pad) return Str.pad(v, 2, "0", true);
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  minute(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getMinutes(), 2, "0", true);
      // normal
      return this.date.getMinutes();
    } catch (err) {
      return undefined;
    }
  }
  totalMinutes() {
    try {
      return Math.floor(this.date.getTime() / 1000 / 60);
    } catch (err) {
      return undefined;
    }
  }
  second(pad = false) {
    try {
      // padded
      if (pad) return Str.pad(this.date.getSeconds(), 2, "0", true);
      // normal
      return this.date.getSeconds();
    } catch (err) {
      return undefined;
    }
  }
  totalSeconds() {
    try {
      return Math.floor(this.date.getTime() / 1000);
    } catch (err) {
      return undefined;
    }
  }
  timePeriod(uppercase = false) {
    try {
      let v = this.hour() < 12 ? "am" : "pm";
      // padded
      if (uppercase) return v.toUpperCase();
      // normal
      return v;
    } catch (err) {
      return undefined;
    }
  }
  // add
  addYears(years) {
    try {
      return new DateTime(
        this.year() + years,
        this.month(),
        this.monthDay(),
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  addMonths(months) {
    try {
      return new DateTime(
        this.year(),
        this.month() + months,
        this.monthDay(),
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  addDays(days) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay() + days,
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  addHours(hours) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        this.hour() + hours,
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  addMinutes(minutes) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        this.hour(),
        this.minute() + minutes,
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  addSeconds(seconds) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        this.hour(),
        this.minute(),
        this.second() + seconds
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  // add
  setYear(years) {
    try {
      return new DateTime(
        years,
        this.month(),
        this.monthDay(),
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  setMonth(months) {
    try {
      return new DateTime(
        this.year(),
        months,
        this.monthDay(),
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  setDay(days) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        days,
        this.hour(),
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  setHour(hours) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        hours,
        this.minute(),
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  setMinute(minutes) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        this.hour(),
        minutes,
        this.second()
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  setSecond(seconds) {
    try {
      return new DateTime(
        this.year(),
        this.month(),
        this.monthDay(),
        this.hour(),
        this.minute(),
        seconds
      ).setSOW(this.SOW);
    } catch (err) {
      return undefined;
    }
  }
  // format
  getFormated(format = "Y-m-d H-i-s") {
    try {
      let str = format;
      for (let k in DateFormats) {
        str = str.replaceAll(k, `{${k} } `);
      }
      for (let k in DateFormats) {
        if (str.includes(`{${k} } `))
          str = str.replaceAll(`{${k} } `, DateFormats[k](this));
      }
      return str;
    } catch (err) {
      return undefined;
    }
  }
  // id (int)
  getTime(id = DateIds.unix) {
    try {
      return (DateTimes[id] ?? DateTimes.unix)(this);
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }
  // compare (-1, 0, 1)
  compare(date, format = "Ymd") {
    try {
      if (!(date instanceof DateTime))
        date = new DateTime(date).setSOW(this.SOW);
      let d1 = this.getTime(format);
      let d2 = date.getTime(format);
      //
      if (d1 === d2) return 0;
      else if (d1 > d2) return 1;
      else if (d1 < d2) return -1;
    } catch (err) {
      return undefined;
    }
  }
  // between (true, false)
  between(startDate, endDate, format = "Ymd", strictlyBetween = false) {
    try {
      if (!(startDate instanceof DateTime))
        startDate = new DateTime(startDate).setSOW(this.SOW);
      if (!(endDate instanceof DateTime))
        endDate = new DateTime(endDate).setSOW(this.SOW);
      let d = this.getTime(format);
      let sd = startDate.getTime(format);
      let ed = endDate.getTime(format);
      if (
        (strictlyBetween === false && sd <= d && d <= ed) ||
        (strictlyBetween === true && sd < d && d < ed)
      )
        return true;
      return false;
    } catch (err) {
      return undefined;
    }
  }
  //
  daysUntil(endDate) {
    return DateTime.daysBetween(this, endDate);
  }
  //
  static daysBetween(startDate, endDate) {
    try {
      if (!(startDate instanceof DateTime))
        startDate = new DateTime(startDate);
      if (!(endDate instanceof DateTime))
        endDate = new DateTime(endDate);
      return Math.ceil((endDate.date - startDate.date) / (1000 * 3600 * 24));
    } catch (err) {
      return undefined;
    }
  }
  // intersect (true, false)
  static periodsIntersect(
    startDate1,
    endDate1,
    startDate2,
    endDate2,
    format = "Ymd",
    firstWeekday = null
  ) {
    try {
      if (!(startDate1 instanceof DateTime))
        startDate1 = new DateTime(startDate1).setSOW(firstWeekday);
      if (!(endDate1 instanceof DateTime))
        endDate1 = new DateTime(endDate1).setSOW(firstWeekday);
      if (!(startDate2 instanceof DateTime))
        startDate2 = new DateTime(startDate2).setSOW(firstWeekday);
      if (!(endDate2 instanceof DateTime))
        endDate2 = new DateTime(endDate2).setSOW(firstWeekday);
      let sd1 = startDate1.getTime(format);
      let ed1 = endDate1.getTime(format);
      let sd2 = startDate2.getTime(format);
      let ed2 = endDate2.getTime(format);
      if (
        (sd1 <= sd2 && sd2 <= ed1) ||
        (sd1 <= ed2 && ed2 <= ed1) ||
        (sd2 <= sd1 && sd1 <= ed2) ||
        (sd2 <= ed1 && ed1 <= ed2)
      )
        return true;
      return false;
    } catch (err) {
      return undefined;
    }
  }
  // min/max
  static max(dates, format = "Ymd", firstWeekday = null) {
    try {
      let d = Arr.generate(dates, (k, v) =>
        (!(k instanceof DateTime) ? new DateTime(v) : k)
          .setSOW(firstWeekday)
          .getTime(format)
      );
      let m = Math.max(...d);
      let date = dates.find((o) => o.getTime(format) === m);
      return (!(date instanceof DateTime) ? new DateTime(date) : date).setSOW(
        firstWeekday
      );
    } catch (err) {
      return undefined;
    }
  }
  static min(dates, format = "Ymd", firstWeekday = null) {
    try {
      let d = Arr.generate(dates, (k, v) =>
        (!(k instanceof DateTime) ? new DateTime(v) : k)
          .setSOW(firstWeekday)
          .getTime(format)
      );
      let m = Math.min(...d);
      let date = dates.find((o) => o.getTime(format) === m);
      return (!(date instanceof DateTime) ? new DateTime(date) : date).setSOW(
        firstWeekday
      );
    } catch (err) {
      return undefined;
    }
  }
  // data
  static calendarMonthData(date, startWeekday = 0) {
    try {
      if (!(date instanceof DateTime)) date = new DateTime(date);
      if (!date) return undefined;
      let weekdays = [];
      let daysData = [];
      let monthData = [];
      let startDate = date.setDay(1).weekFirstDay(startWeekday);
      // week days
      for (let i = 0; i < 7; i++) {
        weekdays.push(Num.loop(startWeekday + i - 1, 1, 0, 6));
      }
      // days data
      for (let di = 0; di <= 41; di++) {
        daysData.push(startDate.addDays(di));
      }
      // month data
      for (let wi = 0; wi <= 6; wi++) {
        monthData[wi] = daysData.slice(wi * 7, wi * 7 + 7);
      }
      return {
        date: date,
        year: date.year(),
        month: date.month(),
        startDate: daysData[0],
        endDate: daysData[daysData.length - 1],
        weekdays: weekdays,
        daysData: daysData,
        monthData: monthData,
      };
    } catch (err) {
      return undefined;
    }
  }
}
//============================== color
export class Color {
  // color
  rgba = [0, 0, 0, 0];
  hsl = [0, 0, 0];
  brightness = 0;
  constructor(r, g, b, a) {
    try {
      this.rgba[0] = r ?? 0;
      this.rgba[1] = g ?? 0;
      this.rgba[2] = b ?? 0;
      this.rgba[3] = a ?? 1;
      this.brightness =
        (Math.max(this.rgba[0], this.rgba[1], this.rgba[2]) +
          Math.min(this.rgba[0], this.rgba[1], this.rgba[2])) /
        2 /
        255;
    } catch (err) {
      return undefined;
    }
  }
  // parse
  static parse(color) {
    try {
      if (color instanceof Color) return color;
      let obj = [0, 0, 0, 1];
      //# F f f f
      if (color.match(/^#([a-f0-9]{3})$/i)) {
        // #f0f
        obj[0] = parseInt(color.substr(1, 1) + color.substr(1, 1), 16);
        obj[1] = parseInt(color.substr(2, 1) + color.substr(2, 1), 16);
        obj[2] = parseInt(color.substr(3, 1) + color.substr(3, 1), 16);
      } else if (color.match(/^#([a-f]){1}([a-f0-9]{3})$/i)) {
        // #ef0f
        obj[0] = parseInt(color.substr(2, 1) + color.substr(2, 1), 16);
        obj[1] = parseInt(color.substr(3, 1) + color.substr(3, 1), 16);
        obj[2] = parseInt(color.substr(4, 1) + color.substr(4, 1), 16);
        obj[3] = parseInt(color.substr(1, 1) + color.substr(1, 1), 16) / 255;
      } else if (color.match(/^#([a-f0-9]{6})$/i)) {
        // #ff00ff
        obj[0] = parseInt(color.substr(1, 2), 16);
        obj[1] = parseInt(color.substr(3, 2), 16);
        obj[2] = parseInt(color.substr(5, 2), 16);
      } else if (color.match(/^#([a-f]){2}([a-f0-9]{6})$/i)) {
        // #eeff00ff
        obj[0] = parseInt(color.substr(3, 2), 16);
        obj[1] = parseInt(color.substr(5, 2), 16);
        obj[2] = parseInt(color.substr(7, 2), 16);
        obj[3] = parseInt(color.substr(1, 2), 16) / 255;
      } else if (color.match(/^rgb\(([0-9 .]+),([0-9 .]+),([0-9 .]+)\)$/i)) {
        // rgb(255,0,255)
        color = color.substr(4, color.length - 5).split(",");
        obj[0] = Num.clamp(parseFloat(color[0]), 0, 255);
        obj[1] = Num.clamp(parseFloat(color[1]), 0, 255);
        obj[2] = Num.clamp(parseFloat(color[2]), 0, 255);
      } else if (
        color.match(/^rgba\(([0-9 .]+),([0-9 .]+),([0-9 .]+),([0-9 .]+)\)$/i)
      ) {
        // rgba(255,0,255,100)
        color = color.substr(5, color.length - 6).split(",");
        obj[0] = Num.clamp(parseFloat(color[0]), 0, 255);
        obj[1] = Num.clamp(parseFloat(color[1]), 0, 255);
        obj[2] = Num.clamp(parseFloat(color[2]), 0, 255);
        obj[3] = Num.clamp(parseFloat(color[3]), 0, 1);
      } else {
        return undefined;
      }
      return new Color(obj[0], obj[1], obj[2], obj[3]);
    } catch (err) {
      return undefined;
    }
  }
  // brightness
  setBrightness(percent) {
    try {
      if (!percent) return this;
      let add = (percent - this.brightness) * 255;
      return new Color(
        Num.clamp(this.rgba[0] + add, 0, 255),
        Num.clamp(this.rgba[1] + add, 0, 255),
        Num.clamp(this.rgba[2] + add, 0, 255),
        this.rgba[3]
      );
    } catch (err) {
      return undefined;
    }
  }
  // brightness
  addBrightness(percent) {
    try {
      if (!percent) return this;
      let add = (this.brightness + percent) * 255;
      return new Color(
        Num.clamp(this.rgba[0] + add, 0, 255),
        Num.clamp(this.rgba[1] + add, 0, 255),
        Num.clamp(this.rgba[2] + add, 0, 255),
        this.rgba[3]
      );
    } catch (err) {
      return undefined;
    }
  }
  // opacity
  setOpacity(o = 1) {
    try {
      return new Color(
        this.rgba[0],
        this.rgba[1],
        this.rgba[2],
        Num.clamp(o, 0, 1)
      );
    } catch (err) {
      return undefined;
    }
  }
  // opacity
  addOpacity(o = 1) {
    try {
      return new Color(
        this.rgba[0],
        this.rgba[1],
        this.rgba[2],
        Num.clamp(this.rgba[3] + o, 0, 1)
      );
    } catch (err) {
      return undefined;
    }
  }
  // check neigbors
  isNeighborColor(color, tolerance = 0.12) {
    try {
      if (!(color instanceof Color)) return undefined;
      tolerance = tolerance * 255;
      return (
        Math.abs(this.rgba[0] - color.rgba[0]) <= tolerance &&
        Math.abs(this.rgba[1] - color.rgba[1]) <= tolerance &&
        Math.abs(this.rgba[2] - color.rgba[2]) <= tolerance
      );
    } catch (err) {
      return undefined;
    }
  }
  // to string format
  getHEX() {
    try {
      return `#${Str.pad(this.rgba[0].toString(16), 2)}${Str.pad(
        this.rgba[1].toString(16),
        2
      )
        }${Str.pad(this.rgba[2].toString(16), 2)} `;
    } catch (err) {
      return undefined;
    }
  }
  getHEXA() {
    try {
      return `#${Str.pad(this.rgba[3].toString(16), 2)}${Str.pad(
        this.rgba[0].toString(16),
        2
      )
        }${Str.pad(this.rgba[1].toString(16), 2)}${Str.pad(
          this.rgba[2].toString(16),
          2
        )
        } `;
    } catch (err) {
      return undefined;
    }
  }
  getRGB() {
    try {
      return `rgb(${this.rgba[0]}, ${this.rgba[1]}, ${this.rgba[2]})`;
    } catch (err) {
      return undefined;
    }
  }
  getRGBA() {
    try {
      return `rgba(${this.rgba[0]}, ${this.rgba[1]}, ${this.rgba[2]}, ${this.rgba[3]})`;
    } catch (err) {
      return undefined;
    }
  }
  // rgbToHSL
  static rgbToHSL(r, g, b) {
    try {
      let obj = [0, 0, 0];
      let min = Math.min(r, g, b);
      let max = Math.max(r, g, b);
      let delta = max - min;
      //
      obj[1] = delta === 0 ? 0 : delta / max;
      obj[2] = (max + min) / 2 / 255;
      //
      // No difference
      if (delta === 0) obj[0] = 0;
      else if (max === r) obj[0] = ((g - b) / delta) % 6;
      else if (max === g) obj[0] = (b - r) / delta + 2;
      else obj[0] = (r - g) / delta + 4;
      obj[0] = Math.round(obj[0] * 60);
      if (obj[0] < 0) obj[0] += 360;
      //
      return obj;
    } catch (err) {
      return undefined;
    }
  }

  // RGBToHSL
  static RGBToHSL(r, g, b) {
    try {
      var obj = { h: 0, s: 0, l: 0 };
      var min = Math.min(r, g, b)
      var max = Math.max(r, g, b)
      var delta = max - min
      //
      obj.s = delta === 0 ? 0 : delta / max
      obj.l = (max + min) / 2 / 255
      //
      // No difference
      if (delta === 0) obj.h = 0
      else if (max === r) obj.h = ((g - b) / delta) % 6
      else if (max === g) obj.h = (b - r) / delta + 2
      else obj.h = (r - g) / delta + 4
      obj.h = Math.round(obj.h * 60)
      if (obj.h < 0) obj.h += 360
      //
      return obj
    } catch (err) {
      return undefined
    }
  }

  // HSLtoRGB
  static HSLtoRGB(h, s, l) {
    try {
      s /= 100;
      l /= 100;
      const k = n => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = n =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
      return { r: 255 * f(0), g: 255 * f(8), b: 255 * f(4) };
    } catch (err) {
      return undefined;
    }
  }

  //
  static generateColorList(count = 1, saturation = 100, lightness = 50) {
    var colors = [];
    for (let i = 0; i < count; i++) colors.push(Color.randomColor(i, saturation, lightness, count));
    return colors;
  }

  static randomColor(id, saturation = 100, lightness = 50, colors = 10) {
    if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    let rgb = Color.HSLtoRGB((id * (360 / colors) % 360), saturation, lightness);
    return new Color(rgb.r, rgb.g, rgb.b);
  }

}
//============================== HttpRequest
// constants
export const ResponseType = {
  Text: "text",
  Json: "json",
  Buffer: "arraybuffer",
  Blob: "blob",
  Document: "document",
};
const HttpRequestStates = {
  0: "unsent",
  1: "opened",
  2: "uploading",
  3: "processing",
  4: "downloading",
  5: "completed",
};
// class
export class HttpRequest {
  //
  #request = new XMLHttpRequest();
  #method = null;
  #address = null;
  #headers = null;
  #urlData = null;
  #bodyData = null;
  #responseType = null;
  #onStateChange = null;
  //
  httpCode = null;
  state = null;
  stateText = null;
  error = null;
  message = null;
  response = null;
  // stats
  stats = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  };
  // upload
  upload = {
    start: null,
    total: 0,
    loaded: 0,
    time: 0,
    duration: 0,
    percentage: 0,
    speed: 0,
    estimate: 0,
  };
  // download
  download = {
    start: null,
    total: 0,
    loaded: 0,
    time: 0,
    duration: 0,
    percentage: 0,
    speed: 0,
    estimate: 0,
  };
  // public
  constructor({
    method = null,
    address = null,
    headers = null,
    urlData = null,
    bodyData = null,
    responseType = null,
    onStateChange = null,
  }) {
    this.#method = method;
    this.#address = address;
    this.#headers = headers;
    this.#urlData = urlData;
    this.#bodyData = bodyData;
    this.#responseType = responseType;
    this.#onStateChange = onStateChange;
    // encode url data
    if (this.#urlData) {
      let query = "?";
      for (let key in this.#urlData)
        query += `${encodeURIComponent(key)}=${encodeURIComponent(
          this.#urlData[key]
        )
          }& `;
      this.#address += query;
    }
    // setState
    this.#setState(0, null);
  }
  async send() {
    return await new Promise((resolve) => {
      try {
        // request
        // response type
        if (this.#responseType) this.#request.responseType = this.#responseType;
        // this.#request.timeout = 4000;
        this.#request.open(
          this.#method,
          this.#address,
          true
          // this.#requestUsername,
          // this.#requestPassword
        );
        // headers
        if (this.#headers) {
          for (let key in this.#headers)
            this.#request.setRequestHeader(key, this.#headers[key]);
        }
        // onloadstart
        this.#request.onloadstart = (event) => this.#setState(1, null);
        // upload onloadstart
        this.#request.upload.onloadstart = (event) => {
          if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded);
          this.#setState(2, null);
        };
        // upload onprogress
        this.#request.upload.onprogress = (event) => {
          if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded);
          this.#setState(2, null);
        };
        // upload onload
        this.#request.upload.onloadend = (event) => {
          if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded);
          this.#setState(3, null);
        };
        // onprogress
        this.#request.onprogress = (event) => {
          if (this.#onStateChange)
            this.#downloadCalc(event.total, event.loaded);
          this.#setState(4, null);
        };
        // onload
        this.#request.onloadend = (event) => {
          if (this.#onStateChange)
            this.#downloadCalc(event.total, event.loaded);
          this.response = this.#request.response;
          this.#setState(5, null);
          resolve(this);
        };
        // ontimeout
        this.#request.ontimeout = (event) => {
          this.#setState(5, "timeout");
          resolve(this);
        };
        // onabort
        this.#request.onabort = (event) => {
          this.#setState(5, "aborted");
          resolve(this);
        };
        // onerror
        this.#request.onerror = (event) => {
          this.#setState(5, "failed");
          resolve(this);
        };
        // send
        this.#request.send(this.#bodyData);
      } catch (e) {
        this.#setState(5, e);
      }
    });
  }
  // private
  #uploadCalc(total, loaded) {
    if (!this.upload.start) this.upload.start = performance.now();
    this.upload.total = total;
    this.upload.loaded = loaded;
    this.upload.duration = performance.now() - this.upload.start;
    if (this.state !== 2) {
      this.upload.speed = 0;
      this.upload.estimate = 0;
    } else {
      this.upload.percentage = Num.round(
        (this.upload.loaded / this.upload.total) * 100,
        2
      );
      this.upload.speed = Num.round(
        this.upload.loaded / this.upload.duration,
        2
      );
      this.upload.estimate = Num.round(
        (this.upload.total - this.upload.loaded) / this.upload.speed,
        2
      );
    }
  }
  #downloadCalc(total, loaded) {
    if (!this.download.start) this.download.start = performance.now();
    this.download.total = total;
    this.download.loaded = loaded;
    this.download.duration = performance.now() - this.download.start;
    if (this.state !== 4) {
      this.download.speed = 0;
      this.download.estimate = 0;
    } else {
      this.download.percentage = Num.round(
        (this.download.loaded / this.download.total) * 100,
        2
      );
      this.download.speed = Num.round(
        this.download.loaded / this.download.duration,
        2
      );
      this.download.estimate = Num.round(
        (this.download.total - this.download.loaded) / this.download.speed,
        2
      );
    }
  }
  #setState(state, error = null) {
    this.httpCode = this.#request.status;
    this.state = state;
    this.stateText = HttpRequestStates[state];
    this.error = error;
    //
    if (!this.stats[state]) {
      this.stats[state] = performance.now();
      if (state > 0)
        this.stats[state - 1] =
          this.stats[state] - (this.stats[state - 1] ?? 0);
    }
    //
    if (this.#onStateChange) this.#onStateChange(this);
  }
} //============================== file
export class mzFile {
  // get file content
  static readAsText(file) {
    return new Promise((resolve, reject) => {
      try {
        if (!(file instanceof File)) return undefined;
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } catch (err) {
        return undefined;
      }
    });
  }
  static readAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      try {
        if (!(file instanceof File)) return undefined;
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      } catch (err) {
        return undefined;
      }
    });
  }
  static readAsBinaryString(file) {
    return new Promise((resolve, reject) => {
      try {
        if (!(file instanceof File)) return undefined;
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } catch (err) {
        return undefined;
      }
    });
  }
  static readAsDataURL(file) {
    return new Promise((resolve, reject) => {
      try {
        if (!(file instanceof File)) return undefined;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } catch (err) {
        return undefined;
      }
    });
  }
  static download(filename, data, type = "text/plain") {
    try {
      const blob = new Blob([data], { type: type });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const el = window.document.createElement("a");
        el.href = window.URL.createObjectURL(blob);
        el.download = filename;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      }
      return true;
    } catch (err) {
      return undefined;
    }
  }
  static downloadBlob(filename, blob) {
    try {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const el = window.document.createElement("a");
        el.href = window.URL.createObjectURL(blob);
        el.download = filename;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      }
      return true;
    } catch (err) {
      return undefined;
    }
  }
  static fetch(filePath) { }
  // get sheet data
  //   static getSheetDataObj(
  //     file,
  //     customRowDelimiter = null,
  //     customColumnDelimiter = null
  //   ) {
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         var keys = [];
  //         var data = [];
  //         // check if is file
  //         var _file = file;
  //         if (!(_file instanceof File)) reject("file not instance of File.");
  //         var _type = (_file.name.split(".").pop() || "").toLowerCase();
  //         // check if is file
  //         let reader = new FileReader();
  //         //onload
  //         reader.onload = function (e) {
  //           let fileData = e.target.result;
  //           if (_type === "xlsx" || _type === "xls") {
  //             try {
  //               let workbook = XLSX.read(fileData, {
  //                 type: "binary",
  //               });
  //               // Here is your object
  //               data = XLSX.utils.sheet_to_json(
  //                 workbook.Sheets[workbook.SheetNames[0]]
  //               );
  //               // Set keys
  //               for (let obj of data) {
  //                 for (let k in obj) {
  //                   if (!keys.includes(k)) keys.push(k);
  //                 }
  //               }
  //               //
  //               resolve({
  //                 keys: keys,
  //                 data: data,
  //               });
  //             } catch (err) {
  //               reject(err);
  //             }
  //           } else {
  //             try {
  //               let rows = fileData.split(customRowDelimiter || "\r\n");
  //               keys = rows[0].split(
  //                 customColumnDelimiter || (_type === "tsv" ? "\t" : ",")
  //               );
  //               for (let i = 1; i < rows.length; i++) {
  //                 let column = rows[i];
  //                 if (column) {
  //                   let arr = {};
  //                   column = column.split(
  //                     customColumnDelimiter || (_type === "tsv" ? "\t" : ",")
  //                   );
  //                   for (let i2 = 0; i2 < column.length; i2++) {
  //                     arr[keys[i2]] = column[i2];
  //                   }
  //                   data.push(arr);
  //                 }
  //               }
  //               resolve({
  //                 keys: keys,
  //                 data: data,
  //               });
  //             } catch (err) {
  //               reject(err);
  //             }
  //           }
  //         };
  //         //onerror
  //         reader.onerror = function (err) {
  //           reject(err);
  //         };
  //         //
  //         if (_type === "xlsx" || _type === "xls") reader.readAsBinaryString(file);
  //         else reader.readAsText(file);
  //       } catch (err) {
  //         reject(err);
  //       }
  //     });
  //   }
}
//============================== image
export class mzImage {
  // image
  canvas = document.createElement("canvas");
  sourceImage;
  width;
  height;
  ratio;
  constructor(image) {
    try {
      if (!(image instanceof Image)) return undefined;
      this.sourceImage = image;
      this.width = image.naturalWidth;
      this.height = image.naturalHeight;
      this.ratio = image.naturalWidth / image.naturalHeight;
    } catch (err) {
      return undefined;
    }
  }
  // apply changes
  apply({
    quality = 1,
    backgroundColor = "#ffffff",
    removeBg = false,
    removeBgTolerance = 10,
    cropSides = false,
    cropSidesTolerance = 10,
    resizeW = null,
    resizeH = null,
    resizeR = true,
    cutT = null,
    cutB = null,
    cutR = null,
    cutL = null,
  }) { }

  // getImage async
  static getImage(dataURL) {
    return new Promise((resolve) => {
      var image = new Image();
      image.onload = (evt) => resolve(image);
      image.src = dataURL;
    });
  }
}
//============================== functions
export function If(condition = true, onTrue = null, onFalse = null) {
  return condition ? onTrue : onFalse;
}
//============================== functions
export function IfRTL(onTrue = null, onFalse = null) {
  return Translation.localeDir === "rtl" ? onTrue : onFalse;
}
//============================== functions
export function IfLTR(onTrue = null, onFalse = null) {
  return Translation.localeDir !== "rtl" ? onTrue : onFalse;
}
//
