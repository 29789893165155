// packages
import React from "react";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// models
import * as MZ from "../models/MZ";

const MyTranslationSelect = (props) => {
  return (
    <MUI.FormControl
      variant={props.variant ?? "standard"}
      size={props.size ?? "small"}
      sx={{ ...props.sx, m: 1, minWidth: 0 }}
    >
      <MUI.Select
        value={MZ.Translation.locale}
        onChange={(evt) => MZ.Translation.changeLocale(evt.target.value)}
        inputProps={{ "aria-label": "Without label" }}
        startAdornment={
          props.showIcon === false ? null : (
            <MUI.InputAdornment position="start">
              <MIcons.Language color="primary" />
            </MUI.InputAdornment>
          )
        }
      >
        {MZ.ForEach(MZ.Translation.locales, (k, v) => (
          <MUI.MenuItem key={MZ.GetKey()} value={v.code}>
            {props.short !== true ? v.name : v.code.toUpperCase()}
          </MUI.MenuItem>
        ))}
      </MUI.Select>
    </MUI.FormControl>
  );
};

export default MyTranslationSelect;
