// packages
import React from "react";
import * as Router from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
// ui
import * as MUI from "@mui/material";
// data
import langData from "./data/languages.json";
// models
import * as MZ from "./models/MZ";
// pages
import MyLogin from "./pages/access/MyLogin";
import MyResetPassword from "./pages/access/MyResetPassword";
import MySetPassword from "./pages/access/MySetPassword";
import MyDemoDashboard from "./pages/demo/MyDemoDashboard";
import MyDashboard from "./pages/MyDashboard";
import MyUsers from "./pages/MyUsers";
import MyCurrencies from "./pages/MyCurrencies";
import MyActionTracker from "./pages/MyActionTracker";
import MyProperties from "./pages/MyProperties";
import MyOutlets from "./pages/MyOutlets";
import MyBudgetsForecasts from "./pages/MyBudgetsForecasts";
import MyTransactions from "./pages/MyTransactions";
import MyScheduledReports from "./pages/MyScheduledReports";
import TestPage from "./plugins/mz/TestPage";

MZ.Translation.addLocale("en", "English", false, langData.en);
MZ.Translation.addLocale("ar", "عربى", true, langData.ar);

function App() {
  // translation
  MZ.Translation.init("<?>");
  // const
  const RtlCache = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const LtrCache = createCache({ key: "muiltr" });

  // theme
  let colors = {
    p: new MZ.Color(26, 26, 26),
    s: new MZ.Color(102, 102, 102),
    l: new MZ.Color(238, 238, 238),
    w: new MZ.Color(255, 255, 255),
  };
  const MyTheme = MUI.createTheme({
    direction: MZ.Translation.localeDir,
    palette: {
      primary: {
        main: colors.p.getRGBA(),
        lighter: colors.p.setOpacity(0.9).getRGBA(),
        dark: colors.p.addBrightness(-0.5).getRGBA(),
        light: colors.p.setOpacity(0.25).getRGBA(),
        contrastText: colors.l.getRGBA(),
      },
      secondary: {
        main: colors.s.getRGBA(),
        lighter: colors.s.setOpacity(0.9).getRGBA(),
        dark: colors.s.addBrightness(-0.5).getRGBA(),
        light: colors.s.setOpacity(0.5).getRGBA(),
        contrastText: colors.w.getRGBA(),
      },
      light: {
        main: colors.l.getRGBA(),
        lighter: colors.l.setOpacity(0.9).getRGBA(),
        dark: colors.l.addBrightness(-0.5).getRGBA(),
        light: colors.l.setOpacity(0.3).getRGBA(),
        contrastText: colors.p.getRGBA(),
      },
      white: {
        main: colors.w.getRGBA(),
        lighter: colors.w.setOpacity(0.9).getRGBA(),
        dark: colors.w.addBrightness(0.5).getRGBA(),
        light: colors.w.setOpacity(0.5).getRGBA(),
        contrastText: colors.s.getRGBA(),
      },
    },
  });

  return (
    <CacheProvider value={MZ.IfRTL(RtlCache, LtrCache)}>
      <MUI.ThemeProvider theme={MyTheme}>
        <MUI.Box className="fill" dir={MZ.Translation.localeDir}>
          <Router.HashRouter>
            <Router.Routes>
              <Router.Route path="/test" element={<TestPage />} />
              <Router.Route path="/" element={<MyLogin />} />
              <Router.Route path="/login" element={<MyLogin />} />
              <Router.Route
                path="/reset-password"
                element={<MyResetPassword />}
              />
              <Router.Route path="/set-password" element={<MySetPassword />} />

              <Router.Route
                path="/demo/:portal/dashboard"
                element={<MyDemoDashboard demo={true} iframe={false} />}
              />
              <Router.Route
                path="/demo/:portal/dashboard/:outletId"
                element={<MyDemoDashboard demo={true} iframe={false} />}
              />
              <Router.Route
                path="/iframe/:portal/dashboard/:outletId/:filterExport/:filterData"
                element={<MyDemoDashboard demo={false} iframe={true} />}
              />

              <Router.Route
                path="/:portal/dashboard"
                element={<MyDashboard />}
              />
              <Router.Route
                path="/:portal/dashboard/:filter"
                element={<MyDashboard />}
              />
              <Router.Route path="/:portal/users" element={<MyUsers />} />
              <Router.Route
                path="/:portal/currencies"
                element={<MyCurrencies />}
              />
              <Router.Route
                path="/:portal/action-tracker"
                element={<MyActionTracker />}
              />
              <Router.Route
                path="/:portal/properties"
                element={<MyProperties />}
              />
              <Router.Route path="/:portal/outlets" element={<MyOutlets />} />
              <Router.Route
                path="/:portal/budgets-forecasts"
                element={<MyBudgetsForecasts />}
              />
              <Router.Route
                path="/:portal/transactions"
                element={<MyTransactions />}
              />
              <Router.Route
                path="/:portal/scheduled-reports"
                element={<MyScheduledReports />}
              />
              <Router.Route path="*" element={<div>404</div>} />
            </Router.Routes>
          </Router.HashRouter>
        </MUI.Box>
      </MUI.ThemeProvider>
    </CacheProvider>
  );
}

export default App;

// function App() {
//   const [name, setName] = useState("Omar");
//   const nameHandler = (evt) => {
//     setName(evt.target.value);
//   };
//   return (
//     <div>
//       <Link to="/">
//         <button>home</button>
//       </Link>
//       <Link to="/create">
//         <button>create</button>
//       </Link>
//       <Link to="/blogs/1">
//         <button>blogs</button>
//       </Link>

//       <Link to="/create">
//         <button>create</button>
//       </Link>

//       {/* <Header />
//       <input type="text" value={name} onChange={nameHandler} />
//       <Container>
//         <Button
//           onClick={nameHandler}
//           variant="success"
//           style={{ backgroundColor: "red" }}
//         >
//           submit
//         </Button>
//       </Container> */}
//       <MyRoutes />
//     </div>
//   );
// }

// export default App;
