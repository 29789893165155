// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// components
import * as MZ from "../models/MZ";
//==============================
const MyCalendar = (props) => {
  var data = MZ.DateTime.calendarMonthData(props.value);
  const stackSize = 4;
  var events = setupEvents(
    props.data,
    data["startDate"],
    data["endDate"],
    stackSize
  );

  const _weekdaysCell = (cell, column) => {
    return (
      <MUI.Box
        key={MZ.GetKey()}
        sx={{
          p: props.small ? 0.25 : 0.5,
          borderWidth: 0.05,
          borderStyle: "solid",
          color: "primary.main",
          backgroundColor: "#f2f2f2",
          borderColor: "#e6e6e6",
          fontWeight: "bold",
          textAlign: "center",
          ...cell.sx,
        }}
        gridColumn={parseInt(column) + 1}
        gridRow="1"
      >
        {cell.child ?? null}
      </MUI.Box>
    );
  };
  const _daysCell = (cell, date, events, column, row) => {
    let notThisMonth = props.value.compare(date, "Ym") !== 0;
    let today = date.compare(new MZ.DateTime(), "Ymd") === 0;

    return (
      <MUI.Box
        key={MZ.GetKey()}
        sx={{
          p: 0,
          borderWidth: 0.05,
          borderStyle: "solid",
          ...(!props.data ? {} : { borderLeft: 0, borderRight: 0 }),
          color: "primary.main",
          backgroundColor: notThisMonth ? "#f2f2f2" : "#fff",
          borderColor: notThisMonth ? "#d9d9d9" : "#e6e6e6",
          fontWeight: "bold",
          textAlign: "center",
          ...cell.sx,
        }}
        gridColumn={parseInt(column) + 1}
        gridRow={parseInt(row) + 2}
      >
        <MUI.Box
          display="grid"
          gridTemplateColumns="100%"
          gridTemplateRows="max-content auto"
          gap={0}
        >
          <MUI.Box
            sx={{
              p: props.small ? 0.1 : 0.2,
              pb: props.small ? 0.25 : 0.5,
              borderWidth: 0.05,
              borderStyle: "solid",
              borderColor: notThisMonth ? "#d9d9d9" : "#e6e6e6",
              ...(!props.data ? { borderLeft: 0, borderRight: 0 } : {}),
              borderTop: 0,
              borderBottom: 0,
            }}
            gridColumn="1"
            gridRow="1"
          >
            <MUI.Typography
              sx={{
                display: "inline",
                p: 0.5,
                fontSize: 11,
                ...(!today
                  ? {}
                  : {
                      backgroundColor: "primary.main",
                      color: "light.main",
                      borderRadius: 50,
                    }),
              }}
            >
              {cell.child ?? null}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box gridColumn="1" gridRow="2">
            <MUI.Box
              display="grid"
              gridTemplateColumns="100%"
              gridTemplateRows={`repeat(${stackSize}, 25px)`}
              gap={0}
            >
              {MZ.ForEach(events, (k, v) => {
                if (props.data && v) {
                  let evt = props.data.find((o) => o.id === v.id);
                  let evtC = MZ.Color.parse(evt["color"] ?? "rgb(0,102,255)");
                  if (evt) {
                    return (
                      <MUI.Box
                        key={`ev-${k}`}
                        sx={{
                          py: 0.2,

                          borderWidth: 0.05,
                          borderStyle: "solid",
                          borderColor: notThisMonth ? "#d9d9d9" : "#e6e6e6",

                          borderTop: 0,
                          borderBottom: 0,

                          ...(v.i === 1 && v.s === 0 // start of event
                            ? {
                                pl: 1,
                              }
                            : {
                                borderLeft: 0,
                              }),

                          ...(v.e === 0 // end of event
                            ? {
                                pr: 1,
                              }
                            : {
                                borderRight: 0,
                              }),
                        }}
                        gridColumn="1"
                        gridRow={k}
                      >
                        <MUI.Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            padding: 0,

                            borderTop: 1,
                            borderBottom: 1,

                            cursor: "pointer",

                            userSelect: "none",

                            ...(v.i === 1 && v.s === 0 // start of event
                              ? {
                                  borderTopLeftRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderLeft: 1,
                                }
                              : {}),
                            ...(v.e === 0 // end of event
                              ? {
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderRight: 1,
                                }
                              : {}),

                            color: evtC.setBrightness(0.1).getRGBA(),
                            borderColor: evtC.setBrightness(0.5).getRGBA(),
                            backgroundColor: evtC.setBrightness(0.9).getRGBA(),
                          }}
                          onClick={(e) => {
                            if (props.onEventClick) props.onEventClick(evt);
                          }}
                        >
                          {v.i === 1 ? (
                            <MUI.Typography
                              noWrap
                              sx={{
                                width: "100%",
                                fontWeight: "bold",
                                verticalAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                textAlign: "start",
                                fontSize: 12,
                              }}
                            >
                              {evt["type"] === "event" ? (
                                <MIcons.Event fontSize="11" sx={{ mx: 1 }} />
                              ) : evt["type"] === "promotion" ? (
                                <MIcons.Discount fontSize="11" sx={{ mx: 1 }} />
                              ) : (
                                <MIcons.Notes fontSize="11" sx={{ mx: 1 }} />
                              )}

                              {evt["description"]}
                            </MUI.Typography>
                          ) : null}
                        </MUI.Box>
                      </MUI.Box>
                    );
                  }
                } else {
                  return (
                    <MUI.Box
                      key={`ev-${k}`}
                      sx={{
                        borderWidth: 0.05,
                        borderStyle: "solid",
                        borderColor: notThisMonth ? "#d9d9d9" : "#e6e6e6",
                        borderTop: 0,
                        borderBottom: 0,
                      }}
                      gridColumn="1"
                      gridRow={k}
                    ></MUI.Box>
                  );
                }
              })}
            </MUI.Box>
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    );
  };
  return (
    <MUI.Box
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows="auto auto 1fr"
      gap={0}
      sx={{
        borderWidth: 0.05,
        borderStyle: "solid",
        borderColor: "primary.light",
        ...props.sx,
      }}
    >
      <MUI.Box gridColumn="1" gridRow="1" sx={{ overflow: "hidden" }}>
        <MUI.Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
          sx={{ mb: 0.5 }}
        >
          <MUI.Typography variant="h5">{props.title}</MUI.Typography>
          <MUI.Box sx={{ flexGrow: 1 }} />
          {MZ.ForEach(props.actions, (ak, av) => (
            <MUI.Button
              key={`title-actions-${ak}`}
              onClick={() => av.onClick()}
              {...av.props}
            >
              {av.icon}
              {av.text}
            </MUI.Button>
          ))}
        </MUI.Stack>
      </MUI.Box>
      <MUI.Box gridColumn="1" gridRow="2" sx={{ overflow: "hidden" }}>
        <MUI.Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
          sx={{ mb: 0.5 }}
        >
          <MUI.Typography variant="h5">{`${props.value.monthText(
            false
          )} ${props.value.year()}`}</MUI.Typography>

          <MUI.Box sx={{ flexGrow: 1 }} />

          {MZ.ForEach(
            [
              {
                icon: <MIcons.KeyboardDoubleArrowLeft />,
                onclick: () => {
                  props.onChanged(props.value.addYears(-1));
                },
              },
              {
                icon: <MIcons.KeyboardArrowLeft />,
                onclick: () => {
                  props.onChanged(props.value.addMonths(-1));
                },
              },
              {
                icon: <MIcons.Today />,
                onclick: () => {
                  props.onChanged(new MZ.DateTime());
                },
              },
              {
                icon: <MIcons.KeyboardArrowRight />,
                onclick: () => {
                  props.onChanged(props.value.addMonths(1));
                },
              },
              {
                icon: <MIcons.KeyboardDoubleArrowRight />,
                onclick: () => {
                  props.onChanged(props.value.addYears(1));
                },
              },
            ],
            (ak, av) => (
              <MUI.IconButton
                key={`title-actions-${ak}`}
                onClick={() => av.onclick()}
              >
                {av.icon}
              </MUI.IconButton>
            )
          )}
        </MUI.Stack>
      </MUI.Box>
      <MUI.Box gridColumn="1" gridRow="3" sx={{ overflow: "hidden" }}>
        <MUI.Box
          className="fill"
          display="grid"
          gridTemplateColumns="repeat(7, 14.2%)"
          gridTemplateRows="repeat(7, max-content)"
          gap={0}
        >
          {MZ.ForEach(data.weekdays, (i, v) =>
            _weekdaysCell({ child: MZ.DateTime.weekdayNoText(v) }, i)
          )}
          {flatten(
            MZ.ForEach(data.monthData, (i, v) =>
              MZ.ForEach(data.monthData[i], (i2, v2) =>
                _daysCell(
                  { child: v2.day() },
                  v2,
                  events[v2.getTime("Ymd")],
                  i2,
                  i
                )
              )
            )
          )}
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );

  function flatten(array, arr = []) {
    for (let obj of array) {
      if (Array.isArray(obj)) flatten(obj, arr);
      else arr.push(obj);
    }
    return arr;
  }

  function setupEvents(data, sDate, eDate, stackSize) {
    if (!data) return [];

    let cells = {};

    // events
    let events = [];
    for (let i in data) {
      data[i]["startstamp"] = new MZ.DateTime(data[i]["startstamp"]);
      data[i]["endstamp"] = new MZ.DateTime(data[i]["endstamp"]);
      if (
        MZ.DateTime.periodsIntersect(
          sDate,
          eDate,
          data[i]["startstamp"],
          data[i]["endstamp"]
        )
      ) {
        events.push(data[i]);
      }
    }
    events = MZ.Arr.sort(events, false, "startstamp");

    // each day
    let len = MZ.DateTime.daysBetween(sDate, eDate);
    for (let i = 0; i <= len; i++) {
      let cDate = new MZ.DateTime(sDate).addDays(i);
      let yT = new MZ.DateTime(cDate).addDays(-1).getTime("Ymd");
      let cT = cDate.getTime("Ymd");
      // add cell
      cells[cT] = {};
      for (let o = 1; o <= stackSize; o++) {
        cells[cT][o] = null;
      }
      // event
      for (let ev of events) {
        if (cDate.between(ev.startstamp, ev.endstamp)) {
          if (i === 0) {
            for (let o = 1; o <= stackSize; o++) {
              if (!cells[cT][o]) {
                cells[cT][o] = {
                  id: ev["id"],
                  i: 1,
                  s: cDate.compare(ev["startstamp"], "Ymd"),
                  e: cDate.compare(ev["endstamp"], "Ymd"),
                };
                break;
              }
            }
          } else {
            // check last day
            let or = null;
            for (let o = 1; o <= stackSize; o++) {
              if (cells[yT][o] && cells[yT][o].id === ev["id"]) {
                cells[cT][o] = {
                  id: ev["id"],
                  i: cells[yT][o].i + 1,
                  s: cDate.compare(ev["startstamp"], "Ymd"),
                  e: cDate.compare(ev["endstamp"], "Ymd"),
                };
                or = o;
                break;
              }
            }
            // put in today id not exist in last
            if (!or) {
              for (let o = 1; o <= stackSize; o++) {
                if (!cells[cT][o]) {
                  cells[cT][o] = {
                    id: ev["id"],
                    i: 1,
                    s: cDate.compare(ev["startstamp"], "Ymd"),
                    e: cDate.compare(ev["endstamp"], "Ymd"),
                  };
                  break;
                }
              }
            }
          }
        }
      }
    }

    return cells;
  }
};
MyCalendar.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  actions: PropTypes.array,
  data: PropTypes.array,
  value: PropTypes.any.isRequired,
  onChanged: PropTypes.func.isRequired,
  events: PropTypes.array,

  sx: PropTypes.object,
};
export default MyCalendar;
