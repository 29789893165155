// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
// models
import * as MZ from "../models/MZ";
//==============================
const MyGrid = {
  Container: (p) => {
    return (
      <MUI.Box
        sx={{
          height: "100%",
          width: "100%",
          ...p.sx,
          display: "grid",
          gridTemplateColumns: p.cols,
          gridTemplateRows: p.rows,
          columnGap: p.spacing ?? 0,
          rowGap: p.spacing ?? 0,
          gap: p.spacing ?? 0,
        }}
        children={p.children}
      />
    );
  },
  colsRepeat: (num = 1, value = "1fr") => `repeat(${num},${value})`,
  cowsRepeat: (num = 1, value = "1fr") => `repeat(${num},${value})`,
  colsManual: (...cols) => cols.join(" "),
  rowsManual: (...rows) => rows.join(" "),
  Item: (p) => {
    return (
      <MUI.Box
        children={p.children}
        sx={{ ...p.sx, gridColumn: p.cols, gridRow: p.rows }}
      />
    );
  },
  itemColsSpan: (num = 1) => `span ${num}`,
  itemRowsSpan: (num = 1) => `span ${num}`,
  itemColsManual: (startCol, endCol) => `${startCol} / ${endCol}`,
  itemRowsManual: (startRow, endRow) => `${startRow} / ${endRow}`,
};
MyGrid.Container.propTypes = {
  sx: PropTypes.object,
  cols: PropTypes.string,
  rows: PropTypes.string,
  spacing: PropTypes.number,
};
MyGrid.Item.propTypes = {
  sx: PropTypes.object,
  rows: PropTypes.string,
  cols: PropTypes.string,
};
export default MyGrid;
