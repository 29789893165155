// packages
import React from "react";
import PropTypes from "prop-types";
// rtl fix
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
// UI
import * as MUI from "@mui/material";
import "chart.js/auto";
import * as ChJsUI from "react-chartjs-2";
// models
import * as MZ from "./mz";

//==============================< styles
export const mainAlign = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  around: "space-around",
  between: "flex-between",
  evenly: "space-evenly",
};
export const crossAlign = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  stretch: "stretch",
  around: "space-around",
  between: "flex-between",
  evenly: "space-evenly",
};
export const mainItemAlign = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  stretch: "stretch",
  baseline: "baseline",
  around: "space-around",
  between: "flex-between",
  evenly: "space-evenly",
};
export const crossItemAlign = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  stretch: "stretch",
  baseline: "baseline",
};
export const flexFlow = {
  row: "row",
  column: "column",
  rowReverse: "row-reverse",
  columnReverse: "column-reverse",
};
export const flexWrap = {
  wrap: "wrap",
  nowrap: "nowrap",
};
//==============================<
// add app theme with rtl patch
export const AppTheme = (p) => {
  const RtlCache = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const LtrCache = createCache({ key: "muiltr" });
  // theme
  let colors = {
    p: new MZ.Color(26, 26, 26),
    s: new MZ.Color(102, 102, 102),
    l: new MZ.Color(238, 238, 238),
    w: new MZ.Color(255, 255, 255),
  };
  const Theme = MUI.createTheme({
    direction: MZ.Translation.getDir(),
    palette: {
      primary: {
        main: colors.p.getRGBA(),
        lighter: colors.p.setOpacity(0.9).getRGBA(),
        dark: colors.p.addBrightness(-0.5).getRGBA(),
        light: colors.p.setOpacity(0.25).getRGBA(),
        contrastText: colors.l.getRGBA(),
      },
      secondary: {
        main: colors.s.getRGBA(),
        lighter: colors.s.setOpacity(0.9).getRGBA(),
        dark: colors.s.addBrightness(-0.5).getRGBA(),
        light: colors.s.setOpacity(0.5).getRGBA(),
        contrastText: colors.w.getRGBA(),
      },
      light: {
        main: colors.l.getRGBA(),
        lighter: colors.l.setOpacity(0.9).getRGBA(),
        dark: colors.l.addBrightness(-0.5).getRGBA(),
        light: colors.l.setOpacity(0.3).getRGBA(),
        contrastText: colors.p.getRGBA(),
      },
      white: {
        main: colors.w.getRGBA(),
        lighter: colors.w.setOpacity(0.9).getRGBA(),
        dark: colors.w.addBrightness(0.5).getRGBA(),
        light: colors.w.setOpacity(0.5).getRGBA(),
        contrastText: colors.s.getRGBA(),
      },
    },
  });

  return (
    <CacheProvider
      value={MZ.Translation.getDir() === "rtl" ? RtlCache : LtrCache}
    >
      <MUI.ThemeProvider theme={Theme}>
        <MUI.Box
          dir={MZ.Translation.getDir()}
          sx={{
            display: "block",
            position: "fixed",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
          children={p.children}
        />
      </MUI.ThemeProvider>
    </CacheProvider>
  );
};
//==============================< breakPoints
export const breakPoints = (value, fallback = null, process = null) => {
  if (MZ.isObj(value)) {
    return MZ.forEachToObj(["xs", "sm", "md", "lg", "xl"], (k, v) => {
      if (!MZ.isNull(value[v]))
        return { [v]: MZ.isFunc(process) ? process(value[v]) : value[v] };
    });
  } else if (!MZ.isNull(value)) return value;
  return fallback;
};
//==============================< Item
export const Item = (p) => {
  return (
    <MUI.Box
      className={`mz-Item ${p.className ?? ""}`}
      sx={{
        ...(p.flex === true
          ? {
              display: "inline-flex",
              flexDirection: p.flow ?? flexFlow.col,
              flexWrap: p.wrap ?? flexWrap.wrap,
              justifyContent: p.main ?? mainAlign.start,
              alignContent: p.cross ?? crossAlign.start,
              justifyItems: p.mainItem ?? mainItemAlign.start,
              alignItems: p.crossItem ?? crossItemAlign.start,
            }
          : { display: "inline-block" }),
        gap: breakPoints(p.spacing),
        ...p.sx,
        width: breakPoints(p.width),
        height: breakPoints(p.height),
        flexGrow: breakPoints(p.grow, 0),
        flexShrink: breakPoints(p.shrink, 0),
        flexBasis: breakPoints(p.basis, "auto"),
      }}
      children={p.children}
    />
  );
};
Item.propTypes = {
  sx: PropTypes.object,
  className: PropTypes.string,
  flow: PropTypes.string,
  wrap: PropTypes.string,
  main: PropTypes.string,
  cross: PropTypes.string,
  mainItem: PropTypes.string,
  crossItem: PropTypes.string,
  flex: PropTypes.bool,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  basis: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};
//==============================< Layout
export const Layout = (p) => {
  return (
    <Item
      className="mz-Layout"
      children={p.children}
      width={"100%"}
      height={"100%"}
      spacing={p.spacing}
      sx={{
        ...p.sx,
        display: "grid",
        gridTemplateAreas: breakPoints(p.areas),
        gridTemplateColumns: breakPoints(p.cols),
        gridTemplateRows: breakPoints(p.rows),
      }}
    />
  );
};
export const LayoutItem = (p) => {
  return (
    <Item
      className="mz-LayoutItem"
      children={p.children}
      sx={{
        ...p.sx,
        overflowX: p.scrollX ? "scroll" : "hidden",
        overflowY: p.scrollY ? "scroll" : "hidden",
        gridArea: breakPoints(p.area),
      }}
    />
  );
};
/*
"'header header' 'aside main' 'footer footer'"
auto 1fr
auto
*/
Layout.propTypes = {
  sx: PropTypes.object,
  areas: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
LayoutItem.propTypes = {
  sx: PropTypes.object,
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,
  area: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
//==============================< Wrap
export const Wrap = (p) => {
  return (
    <Item
      className="mz-Wrap"
      width={"100%"}
      sx={{
        ...p.sx,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: p.main ?? mainAlign.start,
        alignContent: p.cross ?? crossAlign.start,
        justifyItems: p.mainItem ?? mainItemAlign.start,
        alignItems: p.crossItem ?? crossItemAlign.start,
        rowGap: breakPoints(p.spacing),
      }}
      children={p.children}
    />
  );
};
export const WrapItem = (p) => {
  const columns = 12;
  return (
    <Item
      className="mz-WrapItem"
      children={p.children}
      grow={breakPoints(p.cols, 0, (v) => (v === "auto" ? 1 : 0))}
      width={breakPoints(p.cols, null, (v) =>
        v === "auto" ? "auto" : `calc(${(v / columns) * 100}% - ${0}px)`
      )}
      sx={p.sx}
    />
  );
};
Wrap.propTypes = {
  sx: PropTypes.object,
  main: PropTypes.string,
  cross: PropTypes.string,
  mainItem: PropTypes.string,
  crossItem: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
WrapItem.propTypes = {
  sx: PropTypes.object,
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
//==============================< Grid
export const Grid = (p) => {
  return (
    <Item
      className="mz-Grid"
      width={"100%"}
      spacing={p.spacing}
      main={p.main}
      cross={p.cross}
      mainItem={p.mainItem}
      crossItem={p.crossItem}
      sx={{
        ...p.sx,
        display: "grid",
        gridTemplateColumns: breakPoints(p.cols, 0, (v) =>
          !MZ.isNum(v) ? "auto" : `repeat(${v}, minmax(0, 1fr))`
        ),
        gridAutoRows: breakPoints(p.rowHeight, "minmax(0, 1fr)"),
      }}
      children={p.children}
    />
  );
};
export const GridItem = (p) => {
  return (
    <Item
      className="mz-GridItem"
      children={p.children}
      width={"100%"}
      height={"100%"}
      sx={{
        ...p.sx,
        gridColumn: breakPoints(p.rows, null, (v) =>
          !MZ.isNum(v) ? "auto" : `span ${v}`
        ),
        gridRow: breakPoints(p.spacing, null, (v) =>
          !MZ.isNum(v) ? "auto" : `span ${v}`
        ),
      }}
    />
  );
};
Grid.propTypes = {
  sx: PropTypes.object,
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  rowHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  main: PropTypes.string,
  cross: PropTypes.string,
  mainItem: PropTypes.string,
  crossItem: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
GridItem.propTypes = {
  sx: PropTypes.object,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
//==============================< Flex
export const Flex = (p) => {
  return (
    <Item
      className="mz-Flex"
      width={"100%"}
      spacing={p.spacing}
      main={p.main}
      cross={p.cross}
      mainItem={p.mainItem}
      crossItem={p.crossItem}
      wrap={p.wrap}
      sx={{
        ...p.sx,
        display: "flex",
        flexDirection: p.flow ?? flexFlow.row,
      }}
      children={p.children}
    />
  );
};
export const FlexItem = (p) => {
  return (
    <Item
      className="mz-FlexItem"
      grow={p.grow}
      shrink={p.shrink}
      basis={p.basis}
      sx={p.sx}
      children={p.children}
    />
  );
};
Flex.propTypes = {
  sx: PropTypes.object,
  flow: PropTypes.string,
  wrap: PropTypes.string,
  main: PropTypes.string,
  cross: PropTypes.string,
  mainItem: PropTypes.string,
  crossItem: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
FlexItem.propTypes = {
  sx: PropTypes.object,
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  basis: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
//==============================< Graph
const GraphOptions = ({
  type,
  animation,
  filled,
  maintainRatio,
  responsive,
  horizontal,
  reverseIndex,
  beginAtZero,
  indexMin,
  indexMax,
  indexStep,
  showGrid,
  stacked,
  backgroundColor,
  color,
  rtl,
  title,
  titleAlign,
  titlePos,
  dataLabelsAlign,
  dataLabelsPos,
  xLabelsPos,
  yLabelsPos,
  displayDataLabels,
}) => {
  backgroundColor = MZ.Color.parse(backgroundColor ?? "#000").setOpacity(1);
  color = MZ.Color.parse(color ?? "#000").setOpacity(1);
  const scales = {
    stacked: MZ.isBool(stacked) ? stacked : false,
    beginAtZero: MZ.isBool(beginAtZero) ? beginAtZero : true,
    reverse: MZ.isBool(reverseIndex) ? reverseIndex : false,
    min: MZ.isNum(indexMin) ? indexMin : null,
    max: MZ.isNum(indexMax) ? indexMax : null,
    color: color.getRGBA(),
    grid: {
      display: MZ.isBool(showGrid) ? showGrid : true,
      color: color.setOpacity(0.15).getRGBA(),
    },
    angleLines: {
      display: MZ.isBool(showGrid) ? showGrid : true,
      color: color.setOpacity(0.15).getRGBA(),
    },
    pointLabels: {
      color: color.getRGBA(),
    },
    border: {
      color: color.setOpacity(0.25).getRGBA(),
    },
    ticks: {
      stepSize: MZ.isNum(indexStep) ? indexStep : null,
      color: color.getRGBA(),
      showLabelBackdrop: false,
    },
  };
  return {
    animation: MZ.isBool(animation) ? animation : true,
    fill: MZ.isBool(filled) ? filled : true,
    responsive: MZ.isBool(responsive) ? responsive : true,
    maintainAspectRatio: MZ.isBool(maintainRatio) ? maintainRatio : false,
    indexAxis: horizontal === true ? "y" : "x",
    interaction: {
      intersect: false,
      mode: "point",
    },
    layout: {
      padding: {
        bottom: ["pie", "doughnut"].includes(type) ? 10 : 2.5,
        left: ["bar", "bubble"].includes(type)
          ? MZ.ifCases(rtl, [true, 10, false, 2.5])
          : 2.5,
        right: ["bar", "bubble"].includes(type)
          ? MZ.ifCases(rtl, [true, 2.5, false, 10])
          : 2.5,
      },
    },
    title: {
      fontColor: color.getRGBA(),
    },
    legend: {
      labels: {
        fontColor: color.getRGBA(),
      },
    },
    scales: {
      x: {
        position: MZ.ifCases(xLabelsPos ?? "bottom", [
          "end",
          rtl === true ? "left" : "right",
          "start",
          rtl === true ? "right" : "left",
        ]),
        ...scales,
        display: !["radar", "pie", "doughnut", "polar"].includes(type),
      },
      y: {
        position: MZ.ifCases(yLabelsPos ?? "start", [
          "end",
          rtl === true ? "left" : "right",
          "start",
          rtl === true ? "right" : "left",
        ]),
        ...scales,
        display: !["radar", "pie", "doughnut", "polar"].includes(type),
      },
      r: {
        ...scales,
        display: ["radar", "polar"].includes(type),
      },
      // xAxes: [
      //   {
      //     position: "top",
      //     ...scales,
      //     display: !["radar", "pie", "doughnut", "polar"].includes(type),
      //   },
      //   {
      //     position: "bottom",
      //     ...scales,
      //     display: !["radar", "pie", "doughnut", "polar"].includes(type),
      //   },
      // ],
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      title: {
        rtl: MZ.isBool(rtl) ? rtl : false,
        display: !!title,
        text: title,
        align: titleAlign ?? "center",
        position: MZ.ifCases(titlePos ?? "top", [
          "end",
          rtl === true ? "left" : "right",
          "start",
          rtl === true ? "right" : "left",
        ]),
        color: color.getRGBA(),
      },
      legend: {
        rtl: MZ.isBool(rtl) ? rtl : false,
        display: MZ.isBool(displayDataLabels) ? displayDataLabels : true,
        align: dataLabelsAlign ?? "center",
        position: MZ.ifCases(
          dataLabelsPos ??
            MZ.Ifs(
              [["radar", "pie", "doughnut", "polar"].includes(type), "start"],
              "top"
            ),
          [
            "end",
            rtl === true ? "left" : "right",
            "start",
            rtl === true ? "right" : "left",
          ]
        ),
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
          color: color.getRGBA(),
        },
      },
      tooltip: {
        usePointStyle: true,
      },
    },
  };
};
const GraphMultiDataSet = ({
  type,
  labels,
  dataLabels,
  dataSets,
  dataColors,
  filled,
}) => {
  return {
    labels: labels,
    datasets: MZ.forEach(dataLabels, (k, v) => {
      return {
        label: dataLabels[k] || k,
        data: dataSets[k],
        color: MZ.Color.parse(dataColors[k] || "#333")
          .setOpacity(1)
          .getRGBA(),
        borderColor: MZ.Color.parse(dataColors[k] || "#333")
          .addBrightness(-0.5)
          .getRGBA(),
        backgroundColor: MZ.Color.parse(dataColors[k] || "#333")
          .addOpacity(
            MZ.ifCases(
              type,
              ["line", -0.75, "bar", -0.75, "radar", -0.75],
              -0.25
            )
          )
          .getRGBA(),
        fill: MZ.isBool(filled) ? filled : true,
        tension: 0.05,
        borderWidth: 1,
        hoverOffset: 25,
      };
    }),
  };
};
const GraphSingleDataSet = ({
  type,
  dataLabels,
  dataValues,
  dataColors,
  filled,
}) => {
  return {
    labels: dataLabels,
    datasets: [
      {
        label: "",
        data: dataValues,
        color: MZ.forEach(dataValues, (i, v) =>
          MZ.Color.parse(dataColors[i] || "#333")
            .setOpacity(1)
            .getRGBA()
        ),
        borderColor: MZ.forEach(dataValues, (i, v) =>
          MZ.Color.parse(dataColors[i] || "#333")
            .addBrightness(-0.5)
            .getRGBA()
        ),
        backgroundColor: MZ.forEach(dataValues, (i, v) =>
          MZ.Color.parse(dataColors[i] || "#333")
            .addOpacity(MZ.ifCases(type, ["polar", -0.5], -0.25))
            .getRGBA()
        ),
        fill: MZ.isBool(filled) ? filled : true,
        tension: 0.05,
        borderWidth: 1,
        hoverOffset: 25,
      },
    ],
  };
};
const Graph2DMultiDataSet = ({
  type,
  dataLabels,
  dataSetsX,
  dataSetsY,
  dataSetsR,
  dataColors,
  horizontal,
  filled,
}) => {
  return {
    datasets: MZ.forEach(dataLabels, (k, v) => {
      return {
        label: dataLabels[k] || k,
        data: MZ.forEach(dataSetsX[k], (k2, v2) => {
          return {
            x: MZ.crawl(
              horizontal === true ? dataSetsY : dataSetsX,
              [k, k2],
              NaN
            ),
            y: MZ.crawl(
              horizontal === true ? dataSetsX : dataSetsY,
              [k, k2],
              NaN
            ),
            r: MZ.crawl(dataSetsR, [k, k2], NaN) ?? 3,
          };
        }),
        color: MZ.Color.parse(dataColors[k] || "#333")
          .setOpacity(1)
          .getRGBA(),
        borderColor: MZ.Color.parse(dataColors[k] || "#333")
          .addBrightness(-0.5)
          .getRGBA(),
        backgroundColor: MZ.Color.parse(dataColors[k] || "#333")
          .addOpacity(-0.25)
          .getRGBA(),
        fill: MZ.isBool(filled) ? filled : true,
        tension: 0.05,
        borderWidth: 1,
        hoverOffset: 25,
      };
    }),
  };
};
//==============================< LineGraph
export const LineGraph = (p) => {
  return (
    <Item
      className="mz-LineGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{ ...p.sx, backgroundColor: p.backgroundColor }}
      children={
        <ChJsUI.Line
          options={GraphOptions({ type: "line", ...p })}
          data={GraphMultiDataSet({ type: "line", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< BarGraph
export const BarGraph = (p) => {
  return (
    <Item
      className="mz-BarGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.Bar
          options={GraphOptions({ type: "bar", ...p })}
          data={GraphMultiDataSet({ type: "bar", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< RadarGraph
export const RadarGraph = (p) => {
  return (
    <Item
      className="mz-RadarGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.Radar
          options={GraphOptions({ type: "radar", ...p })}
          data={GraphMultiDataSet({ type: "radar", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< PieGraph
export const PieGraph = (p) => {
  return (
    <Item
      className="mz-PieGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.Pie
          options={GraphOptions({ type: "pie", ...p })}
          data={GraphSingleDataSet({ type: "pie", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< Doughnut
export const DoughnutGraph = (p) => {
  return (
    <Item
      className="mz-DoughnutGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.Doughnut
          options={GraphOptions({ type: "doughnut", ...p })}
          data={GraphSingleDataSet({ type: "doughnut", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< Polar
export const PolarGraph = (p) => {
  return (
    <Item
      className="mz-DoughnutGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.PolarArea
          options={GraphOptions({ type: "polar", ...p })}
          data={GraphSingleDataSet({ type: "polar", ...p })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================< bubles
export const BubbleGraph = (p) => {
  return (
    <Item
      className="mz-BubbleGraph"
      width={"100%"}
      height={"100%"}
      grow={1}
      flex={true}
      sx={{
        ...p.sx,
        backgroundColor: p.backgroundColor,
      }}
      children={
        <ChJsUI.Bubble
          options={GraphOptions({
            type: "bubble",
            ...p,
          })}
          data={Graph2DMultiDataSet({
            type: "bubble",
            ...p,
          })}
          height={p.height ?? "100%"}
          width="100%"
        />
      }
    />
  );
};
//==============================<
const GraphsPropTypes = {
  // general
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  // options
  animation: PropTypes.bool,
  maintainRatio: PropTypes.bool,
  responsive: PropTypes.bool,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  titlePos: PropTypes.string,
  displayDataLabels: PropTypes.bool,
  dataLabelsAlign: PropTypes.string,
  dataLabelsPos: PropTypes.string,
  rtl: PropTypes.bool,
  //
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BarGraph.propTypes =
  LineGraph.propTypes =
  RadarGraph.propTypes =
    {
      labels: PropTypes.array.isRequired,
      dataLabels: PropTypes.array.isRequired,
      dataSets: PropTypes.array.isRequired,
      dataColors: PropTypes.array.isRequired,
      // options
      filled: PropTypes.bool,
      horizontal: PropTypes.bool,
      reverseIndex: PropTypes.bool,
      beginAtZero: PropTypes.bool,
      indexMin: PropTypes.number,
      indexMax: PropTypes.number,
      indexStep: PropTypes.number,
      stacked: PropTypes.bool,
      xLabelsPos: PropTypes.string,
      yLabelsPos: PropTypes.string,
      ...GraphsPropTypes,
    };
PieGraph.propTypes =
  DoughnutGraph.propTypes =
  PolarGraph.propTypes =
    {
      dataLabels: PropTypes.array.isRequired,
      dataValues: PropTypes.array.isRequired,
      dataColors: PropTypes.array.isRequired,
      ...GraphsPropTypes,
    };
BubbleGraph.propTypes = {
  dataLabels: PropTypes.array.isRequired,
  dataSetsX: PropTypes.array.isRequired,
  dataSetsY: PropTypes.array.isRequired,
  dataColors: PropTypes.array.isRequired,
  //
  dataSetsR: PropTypes.array,
  // options
  horizontal: PropTypes.bool,
  reverseIndex: PropTypes.bool,
  beginAtZero: PropTypes.bool,
  indexMin: PropTypes.number,
  indexMax: PropTypes.number,
  indexStep: PropTypes.number,
  xLabelsPos: PropTypes.string,
  yLabelsPos: PropTypes.string,
  ...GraphsPropTypes,
};
