// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../../models/MyAppM";
import * as MZ from "../../models/MZ";
// layouts
import MyTranslationSelect from "../../layouts/MyTranslationSelect";
// components
import MyDialog from "../../components/MyDialog";

const MyResetPassword = () => {
  const RouterNavigate = Router.useNavigate();
  // check if logged in
  React.useEffect(() => {
    if (MyAppM.checkUIDD() === true)
      RouterNavigate(`/${MyAppM.UIDD.PORTAL.link}/dashboard`);
  }, [RouterNavigate]);
  // controls
  var formController = MZ.FormController();

  //
  const [dialog, setDialog] = React.useState(false);
  const [apiError, setApiError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleResetPass(evt) {
    try {
      evt.preventDefault();
      if (formController.valid() === false) return;
      // login nigga
      setLoading(true);
      let fData = formController.toFormData();
      let r = await MyAppM.exabyteRequest(
        "client/access/reset-password",
        fData
      );
      setLoading(false);
      if (r.success === false) {
        setApiError(r.message);
      } else {
        setApiError(null);
        setDialog(true);
      }
    } catch (err) {
      setApiError(err);
      console.warn(err);
    }
  }

  return (
    <MUI.Container
      component="main"
      maxWidth="xs"
      sx={{
        paddingTop: 9,
      }}
    >
      <MUI.CssBaseline />
      <MUI.Card
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <MUI.Typography
          component="h1"
          variant="h5"
          sx={{ marginBottom: 1, textAlign: "center" }}
        >
          {MZ.T("reset-password")}
        </MUI.Typography>

        <MUI.Typography
          component="h1"
          variant="body2"
          color="text.secondary"
          gutterBottom
        >
          {MZ.T("reset-password-description")}
        </MUI.Typography>

        {!!apiError ? (
          <MUI.Alert severity="error" sx={{ marginTop: 3 }}>
            {apiError}
          </MUI.Alert>
        ) : null}

        <MUI.Box
          component="form"
          onSubmit={handleResetPass}
          noValidate
          sx={{ mt: 1 }}
        >
          <MZ.InputControllerWidget
            name="email"
            formController={formController}
            errorAfterFirstSubmit={true}
            validator={MZ.ValidateEmail(true, null, null, true)}
            inputField={(value, setValue, error) => (
              <>
                <MUI.TextField
                  margin="normal"
                  required
                  className="w100"
                  label={MZ.T("email")}
                  name="email"
                  autoComplete="email"
                  error={!!error}
                  onChange={(evt) => setValue(evt.target.value)}
                  autoFocus
                />
                <MUI.FormHelperText error={!!error}>{error}</MUI.FormHelperText>
              </>
            )}
          />

          <MLab.LoadingButton
            startIcon={<MIcons.Send />}
            loading={loading}
            loadingPosition="start"
            type="submit"
            className="w100"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {MZ.T("send")}
          </MLab.LoadingButton>

          <MUI.Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <MUI.Button
              onClick={() => RouterNavigate("/")}
              variant="text"
              color="error"
            >
              {MZ.T("cancel")}
            </MUI.Button>
            <MyTranslationSelect />
          </MUI.Stack>
        </MUI.Box>
      </MUI.Card>
      <MyDialog
        open={dialog}
        onClose={() => {
          setDialog(false);
          RouterNavigate(`/`);
        }}
        title={MZ.T("reset-password")}
        description={MZ.T("reset-password-sent")}
        actions={[MZ.T("ok")]}
      />
    </MUI.Container>
  );
};

export default MyResetPassword;
