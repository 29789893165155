// packages
import React from "react";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../../models/MyAppM";
import { MyReportsM } from "../../models/MyReportsM";
import * as MZ from "../../models/MZ";
// layouts
// components
import MyFormDialog from "../../components/MyFormDialog";

//============< filterForm
const MyDashboardDownloadForm = (props) => {
  // form
  var formController = MZ.FormController();
  //
  return (
    <MyFormDialog
      open={props.open}
      title={MZ.T("download")}
      onSubmit={async (form) => {
        props.setLoading(true);
        let r = MyReportsM.processDownloadData(
          props.filterData,
          formController.toObj()["reports"],
          props.rawData,
          props.dependants
        );
        if (r.success === true) {
          await downloadExcel(r.data);
        } else console.log(r);
        props.setLoading(false);
        props.setOpen(false);
      }}
      maxWidth="md"
      actions={[
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Close />}
          loading={false}
          loadingPosition="start"
          type="cancel"
          variant="contained"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          {MZ.T("cancel")}
        </MLab.LoadingButton>,
        <MLab.LoadingButton
          key={MZ.GetKey()}
          startIcon={<MIcons.Download />}
          loading={props.loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
        >
          {MZ.T("download")}
        </MLab.LoadingButton>,
      ]}
    >
      {MZ.If(
        !!props.apiError,
        <MUI.Alert severity="error" sx={{ m: 3, mt: 0 }}>
          {props.apiError}
        </MUI.Alert>
      )}
      <MUI.Grid
        container
        direction="column"
        spacing={1}
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        {MZ.ForEach(
          [
            {
              label: MZ.T("total-revenue"),
              start: 0,
              end: 5,
              children: [
                {
                  index: 0,
                  id: "TotalRevenue/RevenueAnalysis",
                  label: MZ.T("total-revenue-analysis"),
                },
                {
                  index: 1,
                  id: "TotalRevenue/AverageCheck",
                  label: MZ.T("total-revenue-average"),
                },
                {
                  index: 2,
                  id: "TotalRevenue/RevenuePerSeat",
                  label: MZ.T("total-revenue-per-seat"),
                },
                {
                  index: 3,
                  id: "TotalRevenue/RevenuePerHour",
                  label: MZ.T("total-revenue-per-hour"),
                },
                {
                  index: 4,
                  id: "TotalRevenue/RevenuePerSeatPerHour",
                  label: MZ.T("total-revenue-per-seat-hour"),
                },
                {
                  index: 5,
                  id: "TotalRevenue/RevenuePerSqm",
                  label: MZ.T("total-revenue-per-sqm"),
                },
              ],
            },
            {
              label: MZ.T("food-revenue"),
              start: 6,
              end: 7,
              children: [
                {
                  index: 6,
                  id: "FoodRevenue/RevenueAnalysis",
                  label: MZ.T("food-revenue-analysis"),
                },
                {
                  index: 7,
                  id: "FoodRevenue/RatioAnalysis",
                  label: MZ.T("food-revenue-ratio"),
                },
              ],
            },
            {
              label: MZ.T("beverage-revenue"),
              start: 8,
              end: 9,
              children: [
                {
                  index: 8,
                  id: "BeverageRevenue/RevenueAnalysis",
                  label: MZ.T("beverage-revenue-analysis"),
                },
                {
                  index: 9,
                  id: "BeverageRevenue/RatioAnalysis",
                  label: MZ.T("beverage-revenue-ratio"),
                },
              ],
            },
            {
              label: MZ.T("other-revenue"),
              start: 10,
              end: 11,
              children: [
                {
                  index: 10,
                  id: "OtherRevenue/RevenueAnalysis",
                  label: MZ.T("other-revenue-analysis"),
                },
                {
                  index: 11,
                  id: "OtherRevenue/RatioAnalysis",
                  label: MZ.T("other-revenue-ratio"),
                },
              ],
            },
            {
              label: MZ.T("covers"),
              start: 12,
              end: 12,
              children: [
                {
                  index: 12,
                  id: "CoversRevenue/TotalCovers",
                  label: MZ.T("covers-total"),
                },
              ],
            },
          ],
          (k, v) => {
            let children = MZ.ForEach(v.children, (k2, v2) => {
              return (
                <MZ.InputControllerWidget
                  key={`downloadReports-${k}${k2}`}
                  formController={formController}
                  name="reports"
                  index={v2.index}
                  initValue={true}
                  staticValue={v2.id}
                  inputField={(value, setValue, error) => (
                    <MUI.FormControlLabel
                      label={v2.label}
                      control={
                        <MUI.Checkbox
                          checked={value}
                          onChange={(evt) => setValue(evt.target.checked)}
                        />
                      }
                    />
                  )}
                />
              );
            });
            return (
              <MUI.Box key={`downloadReports-${k}`}>
                <MZ.GroupControllerWidget
                  formController={formController}
                  name={"reports"}
                  index={parseInt(k)}
                  startIndex={v.start}
                  endIndex={v.end}
                  inputField={(values) => {
                    let checks = MZ.CountIf(values, (k, v) => v === true);
                    let count = Object.keys(values).length;
                    return (
                      <MUI.FormControlLabel
                        label={v.label}
                        control={
                          <MUI.Checkbox
                            checked={checks === count}
                            indeterminate={checks > 0 && checks < count}
                            onChange={(evt) =>
                              formController.setIndexedValues(
                                "reports",
                                v.start,
                                v.end,
                                evt.target.checked
                              )
                            }
                          />
                        }
                      />
                    );
                  }}
                />
                <MUI.Box sx={{ pl: 2 }}>{children}</MUI.Box>
              </MUI.Box>
            );
          }
        )}
      </MUI.Grid>
    </MyFormDialog>
  );
};
export default MyDashboardDownloadForm;

// excel
async function downloadExcel(data) {
  try {
    //========================= request
    let request = new MZ.HttpRequest({
      method: "POST",
      address: MyAppM.API + "client/demo/excel",
      bodyData: JSON.stringify(data),
      responseType: MZ.ResponseType.Blob,
    });
    await request.send();
    MZ.mzFile.downloadBlob(data.filename + ".xlsx", request.response);
    return MZ.Success();
  } catch (err) {
    return MZ.Error(err);
  }
}
