// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
// models
import * as MZ from "../models/MZ";
//==============================
const MyFlex = {
  Container: (p) => {
    return (
      <MUI.Box
        sx={{
          height: "100%",
          width: "100%",
          ...p.sx,
          display: "flex",
          flexDirection: p.flow ?? MyFlex.flow.row,
          flexWrap: p.wrap ?? MyFlex.wrap.wrap,
          justifyContent: p.mainAlign ?? MyFlex.mainAlign.start,
          alignContent: p.crossAlign ?? MyFlex.crossAlign.start,
          justifyItems: p.itemMainAlign ?? MyFlex.itemMainAlign.start,
          alignItems: p.itemCrossAlign ?? MyFlex.itemCrossAlign.start,
          rowGap: p.spacing,
          columnGap: p.spacing,
          gap: p.spacing,
        }}
        children={p.children}
      />
    );
  },
  flow: {
    row: "row",
    column: "column",
    rowReverse: "row-reverse",
    columnReverse: "column-reverse",
  },
  wrap: {
    wrap: "wrap",
    nowrap: "nowrap",
  },
  mainAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  crossAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  itemMainAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    baseline: "baseline",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  itemCrossAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    baseline: "baseline",
  },
  Item: (p) => {
    return (
      <MUI.Box
        style={{
          ...p.sx,
          flexGrow: p.grow,
          flexShrink: p.shrink,
          flexBasis: p.basis,
        }}
        children={p.children}
      />
    );
  },
};
MyFlex.Container.propTypes = {
  sx: PropTypes.object,
  flow: PropTypes.string,
  wrap: PropTypes.string,
  mainAlign: PropTypes.string,
  crossAlign: PropTypes.string,
  itemMainAlign: PropTypes.string,
  itemCrossAlign: PropTypes.string,
  spacing: PropTypes.number,
};
MyFlex.Item.propTypes = {
  sx: PropTypes.object,
  grow: PropTypes.number,
  shrink: PropTypes.number,
  basis: PropTypes.number,
};
export default MyFlex;
