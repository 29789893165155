// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import * as MZ from "../models/MZ";
// layouts
import MyTranslationSelect from "../layouts/MyTranslationSelect";

const MyPage = (props) => {
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  // const RouterParams = Router.useParams();
  // check if logged in
  React.useEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
  }, [RouterNavigate]);

  const [iconsOnly, setIconsOnly] = React.useState(false);

  return (
    <MUI.Box
      className="fill"
      display="grid"
      gridTemplateColumns="auto 1fr"
      gridTemplateRows="4rem 1fr"
      gap={0}
    >
      <MUI.Box gridColumn="span 2" gridRow="1" sx={{ overflow: "hidden" }}>
        <MyTopMenu
          iconsOnly={iconsOnly}
          onClick={() => setIconsOnly(!iconsOnly)}
        />
      </MUI.Box>
      <MUI.Box gridColumn="1" gridRow="2">
        <MySideMenu
          iconsOnly={iconsOnly}
          onOpen={() => setIconsOnly(true)}
          onClose={() => setIconsOnly(false)}
          onToggle={() => setIconsOnly(!iconsOnly)}
        />
      </MUI.Box>
      <MUI.Box
        gridColumn="2"
        gridRow="2"
        sx={{ overflow: "auto", pb: props.remBottomPad === true ? 0 : 25 }}
      >
        <MUI.Box>{props.children}</MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

export default MyPage;

//============< Top menu
const MyTopMenu = (props) => {
  MyAppM.checkUIDD();
  const shortMenu = MUI.useMediaQuery("(max-width:600px)");

  return (
    <MUI.AppBar position="static" color="white" elevation={0} className="h100">
      <MUI.Toolbar
        className="fill"
        sx={{
          justifyContent: "start",
          alignItems: "center",
          flexGrow: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
        disableGutters={shortMenu}
      >
        <MUI.Typography
          sx={{ px: 1, py: 2, marginInlineEnd: 1 }}
          className="h100"
        >
          <img
            src={MZ.If(
              !!MZ.Arr.crawl(MyAppM.UIDD, "ORG/logo"),
              MyAppM.getMediaUrl(MZ.Arr.crawl(MyAppM.UIDD, "ORG/logo")),
              "./media/logo.png"
            )}
            alt="logo"
            height="100%"
          />
        </MUI.Typography>

        <MUI.Typography variant="h6" sx={{ flexGrow: 1 }}>
          {MZ.Arr.crawl(MyAppM.UIDD, "ORG/name")}
        </MUI.Typography>

        <MyTranslationSelect
          variant="standard"
          size="small"
          showIcon={!shortMenu}
          short={shortMenu}
        />
      </MUI.Toolbar>
    </MUI.AppBar>
  );
};

//============< Side menu
const MySideMenu = (props) => {
  const RouterNavigate = Router.useNavigate();
  const RouterLocation = Router.useLocation();
  const RouterParams = Router.useParams();
  const sideMenuSelect = RouterLocation.pathname;

  return (
    <MUI.Paper
      elevation={0}
      square
      className="h100"
      sx={{
        p: 0.75,
        backgroundColor: "primary.main",
      }}
      onClick={props.onToggle}
      // onMouseOver={props.onOpen}
      // onMouseOut={props.onClose}
    >
      <MUI.Stack
        component="nav"
        className="h100"
        sx={{
          backgroundColor: "primary.main",
          color: "light.main",
          p: 0,
          position: "relative",
          overflow: "visible",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* <MUI.IconButton
          sx={{
            position: "absolute",
            color: "light.main",
            backgroundColor: "primary.main",
            alignSelf: "flex-end",
            p: 0,
            mt: 0.5,
            mr: -2.5,
            ":hover": {
              backgroundColor: "primary.main",
            },
            zIndex: 15,
          }}
          onClick={() => props.onToggle()}
        >
          <MIcons.KeyboardArrowRight
            className={MZ.If(
              props.iconsOnly,
              MZ.IfLTR("flipH", ""),
              MZ.IfRTL("flipH", "")
            )}
            sx={{ fontSize: 20 }}
          />
        </MUI.IconButton> */}

        <MUI.Stack>
          <MySideMenuItem
            iconOnly={true}
            icon={
              <MIcons.KeyboardArrowRight
                className={MZ.If(
                  props.iconsOnly,
                  MZ.IfLTR("flipH", ""),
                  MZ.IfRTL("flipH", "")
                )}
              />
            }
            hover={false}
            onClick={() => props.onToggle()}
            sx={{ my: 0, py: 0, alignSelf: "flex-end" }}
          />

          <MySideMenuItem
            iconOnly={props.iconsOnly}
            icon={<MIcons.AccountCircle />}
            text={MZ.Arr.crawl(MyAppM.UIDD, "USER/name")}
            hover={false}
            sx={{ mb: 0, pb: 0 }}
          />
        </MUI.Stack>

        <MUI.Divider
          className="w100"
          sx={{
            my: 1,
            backgroundColor: "white.main",
          }}
        />

        {MZ.ForEach(
          [
            {
              icon: <MIcons.Dashboard />,
              name: MZ.T("dashboard"),
              path: `/${RouterParams.portal}/dashboard`,
            },
            {
              icon: <MIcons.Person />,
              name: MZ.T("users"),
              path: `/${RouterParams.portal}/users`,
            },
            // {
            //   icon: <MIcons.CurrencyExchange />,
            //   name: MZ.T("currencies"),
            //   path: `/${RouterParams.portal}/currencies`,
            // },
            {
              icon: <MIcons.EventNote />,
              name: MZ.T("action-tracker"),
              path: `/${RouterParams.portal}/action-tracker`,
            },
            ...(["corporate"].includes(RouterParams.portal)
              ? [
                  {
                    icon: <MIcons.Apartment />,
                    name: MZ.T("properties"),
                    path: `/${RouterParams.portal}/properties`,
                  },
                ]
              : []),
            ...(["corporate", "property"].includes(RouterParams.portal)
              ? [
                  {
                    icon: <MIcons.Store />,
                    name: MZ.T("outlets"),
                    path: `/${RouterParams.portal}/outlets`,
                  },
                ]
              : []),
            {
              icon: <MIcons.PriceCheck />,
              name: MZ.T("budget-forecast"),
              path: `/${RouterParams.portal}/budgets-forecasts`,
            },
            {
              icon: <MIcons.ReceiptLong />,
              name: MZ.T("transactions"),
              path: `/${RouterParams.portal}/transactions`,
            },
            {
              icon: <MIcons.AttachEmail />,
              name: MZ.T("scheduled-reports"),
              path: `/${RouterParams.portal}/scheduled-reports`,
            },
          ],
          (k, v) => (
            <MySideMenuItem
              key={MZ.GetKey()}
              selectIndex={v.path}
              selectedIndex={sideMenuSelect}
              iconOnly={props.iconsOnly}
              icon={v.icon}
              text={v.name}
              onClick={() => RouterNavigate(v.path)}
            />
          )
        )}

        <MUI.Box sx={{ flexGrow: 1 }}></MUI.Box>

        <MySideMenuItem
          iconOnly={props.iconsOnly}
          icon={<MIcons.Logout />}
          text={MZ.T("logout")}
          onClick={() => MyAppM.logout()}
          bgColor="light.main"
          bgFocus="light.main"
          color="error.main"
        />
      </MUI.Stack>
    </MUI.Paper>
  );
};

//============< Side menu item
const MySideMenuItem = (props) => {
  let selected =
    (props.selectIndex ?? 0) === (props.selectedIndex ?? 1) ?? false;
  let bgColor = props.bgColor ?? "primary.main";
  let bgFocus = props.bgFocus ?? "light.light";
  let color = props.color ?? "light.main";
  return (
    <MUI.ListItemButton
      onClick={props.onClick}
      dense={props.dense ?? false}
      sx={{
        backgroundColor: selected ? bgFocus : bgColor,
        color: color,
        ":hover": MZ.If(
          props.hover ?? true,
          {
            backgroundColor: bgFocus,
          },
          {
            backgroundColor: selected ? bgFocus : bgColor,
          }
        ),
        mb: 0.75,
        p: 0.5,
        borderRadius: 1,
        flexGrow: 0,
        ...props.sx,
      }}
      disableRipple={!(props.hover ?? true)}
    >
      <MUI.ListItemIcon
        sx={{
          minWidth: 0,
          color: "inherit",
        }}
      >
        {props.icon}
      </MUI.ListItemIcon>
      <MUI.Collapse
        in={props.iconOnly ?? true}
        collapsedSize={0}
        orientation="horizontal"
      >
        {MZ.If(
          !!props.text,
          <MUI.ListItemText
            inset={true}
            sx={{
              paddingInlineStart: 3,
              width: 125,
            }}
            primary={props.text}
            primaryTypographyProps={{
              color: "inherit",
              fontWeight: "medium",
              variant: "body2",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        )}
      </MUI.Collapse>
    </MUI.ListItemButton>
  );
};
