// packages
import React from "react";
import PropTypes from "prop-types";
// UI
import * as MUI from "@mui/material";
// models
import * as MZ from "../models/mz/mz";
//==============================
const MyWrap = {
  Container: (p) => {
    return (
      <MUI.Box
        sx={{
          height: "100%",
          width: "100%",
          ...p.sx,
          display: "grid",
          justifyContent: p.mainAlign ?? MyWrap.mainAlign.start,
          alignContent: p.crossAlign ?? MyWrap.crossAlign.start,
          justifyItems: p.itemMainAlign ?? MyWrap.itemMainAlign.start,
          alignItems: p.itemCrossAlign ?? MyWrap.itemCrossAlign.start,
          gridTemplateColumns: !p.cols ? "auto" : `repeat(${p.cols},1fr)`,
          gridAutoRows: p.allRowsSameHeight ? "1fr" : null,
          columnGap: p.spacing ?? 0,
          rowGap: p.spacing ?? 0,
          spacing: p.spacing ?? 0,
        }}
        children={p.children}
      />
    );
  },
  mainAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  crossAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  itemMainAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    baseline: "baseline",
    around: "space-around",
    between: "flex-between",
    evenly: "space-evenly",
  },
  itemCrossAlign: {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
    baseline: "baseline",
  },
  Item: (p) => {
    return (
      <MUI.Box
        children={p.children}
        sx={{
          height: "100%",
          width: "100%",
          ...p.sx,
          gridColumn: `span ${
            (!MZ.isObject(p.cols) ? p.cols : p.cols.xs) ?? 1
          }`,
          gridRow: `span ${(!MZ.isObject(p.rows) ? p.rows : p.rows.xs) ?? 1}`,

          "@media only screen and (min-width:576px)": {
            ...(MZ.isObject(p.cols) && p.cols.sm
              ? { gridColumn: `span ${p.cols.sm}` }
              : {}),
            ...(MZ.isObject(p.rows) && p.rows.sm
              ? { gridRow: `span ${p.rows.sm}` }
              : {}),
          },
          "@media only screen and (min-width:768px)": {
            ...(MZ.isObject(p.cols) && p.cols.md
              ? { gridColumn: `span ${p.cols.md}` }
              : {}),
            ...(MZ.isObject(p.rows) && p.rows.md
              ? { gridRow: `span ${p.rows.md}` }
              : {}),
          },
          "@media only screen and (min-width:992px)": {
            ...(MZ.isObject(p.cols) && p.cols.lg
              ? { gridColumn: `span ${p.cols.lg}` }
              : {}),
            ...(MZ.isObject(p.rows) && p.rows.lg
              ? { gridRow: `span ${p.rows.lg}` }
              : {}),
          },
          "@media only screen and (min-width:1200px)": {
            ...(MZ.isObject(p.cols) && p.cols.xl
              ? { gridColumn: `span ${p.cols.xl}` }
              : {}),
            ...(MZ.isObject(p.rows) && p.rows.xl
              ? { gridRow: `span ${p.rows.xl}` }
              : {}),
          },
        }}
      />
    );
  },
};
MyWrap.Container.propTypes = {
  sx: PropTypes.object,
  cols: PropTypes.number,
  allRowsSameHeight: PropTypes.bool,
  spacing: PropTypes.number,
};
MyWrap.Item.propTypes = {
  sx: PropTypes.object,
  rows: PropTypes.number,
  cols: PropTypes.any,
};
export default MyWrap;
