// ============================== Types
export function getType(value) {
    if (isNull(value)) return typeof value
    return value.constructor.name
}
export function isNull(value) {
    if ([null, undefined, NaN].includes(value)) return true
    return false
}
export function isEmpty(value) {
    if ([null, undefined, NaN, '', {}, []].includes(value)) return true
    return false
}
export function isString(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'string' &&
            value.constructor.name === 'String')
    )
        return true
    return false
}
export function isNumber(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'number' &&
            value.constructor.name === 'Number')
    )
        return true
    return false
}
export function isArray(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'object' &&
            value.constructor.name === 'Array')
    )
        return true
    return false
}
export function isObject(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'object' &&
            value.constructor.name === 'Object')
    )
        return true
    return false
}
export function isFunction(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'function' &&
            value.constructor.name === 'Function')
    )
        return true
    return false
}
export function isBoolean(value, canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'boolean' &&
            value.constructor.name === 'Boolean')
    )
        return true
    return false
}
export function isClass(value, className = '', canBeNull = false) {
    if (
        (canBeNull && isNull(value)) ||
        (!isNull(value) &&
            typeof value === 'object' &&
            value.constructor.name === className)
    )
        return true
    return false
}
export function isFile(value, canBeNull = false) {
    return isClass(value, 'File', canBeNull)
}
// ============================== func
export function safeFunc(
    func = () => { },
    errFunc = (err) => {
        console.warn('SafeFunc', err)
    }
) {
    if (!isFunction(func) || !isFunction(errFunc)) return
    try {
        return func()
    } catch (err) {
        return errFunc(err)
    }
}
// ============================== length
export function length(any) {
    if (isEmpty(any)) return 0
    if (isString(any)) return any.length
    if (isNumber(any)) return any
    if (isArray(any)) return any.length
    if (isObject(any)) return Object.keys(any).length
    if (isFunction(any)) return 0
    if (isBoolean(any)) return any ? 1 : 0
    if (isClass(any)) return 0
}
// ============================== toString
export function toString(any) {
    if (isEmpty(any)) return `${any}`
    if (isString(any)) return any
    if (isNumber(any)) return `${any}`
    if (isArray(any)) return `${any}`
    if (isObject(any)) return `${any}`
    if (isFunction(any)) return `${any}`
    if (isBoolean(any)) return `${any}`
    if (isClass(any)) return `${any}`
}
// ============================== Ifs
export function If(condition = true, onTrue = null, onFalse = null) {
    return condition
        ? isFunction(onTrue)
            ? onTrue()
            : onTrue
        : isFunction(onFalse)
            ? onFalse()
            : onFalse
}
// ============================== cookie
// options: encode, json, expire
export function getCookie(name, options = {}) {
    try {
        if (!isString(name)) return false
        var value = null
        var list = decodeURIComponent(document.cookie).split(';')
        // separate
        var map = {}
        for (var str of list) {
            var pair = str.trim().split('=')
            var val = pair.join('=').split(`${pair[0]}=`)[1]
            // eslint-disable-next-line no-undef, no-prototype-builtins
            if (map.hasOwnProperty(pair[0])) {
                if (!isArray(map[pair[0]])) map[pair[0]] = [map[pair[0]]]
                map[pair[0]].push(val)
            } else {
                map[pair[0]] = val
            }
        }
        value = map[name]
        if (isObject(options) && !isEmpty(value)) {
            if (options.encode === true) value = window.atob(value)
            if (options.json === true) value = JSON.parse(value)
        }
        return value
    } catch (err) {
        return undefined
    }
}
export function setCookie(name, value, options = {}) {
    try {
        if (!isString(name)) return false
        var expire = null
        if (isObject(options) && !isEmpty(value)) {
            if (options.json === true) value = JSON.stringify(value)
            if (options.encode === true) value = window.btoa(value)
            if (isNumber(options.expire)) {
                expire = new Date(Date.now() + options.expire * 3600000).toUTCString()
            }
        }
        // eslint-disable-next-line no-undef
        document.cookie = `${name}=${value};${!isEmpty(expire) ? `expires=${expire};` : ''
            }path=/`
        return true
    } catch (err) {
        return false
    }
}
export function delCookie(name) {
    try {
        if (!isString(name)) return false
        // eslint-disable-next-line no-undef
        document.cookie = `${name}=${null};expires=${new Date(
            Date.now() / 2
        ).toUTCString()};path=/`
        return true
    } catch (err) {
        return false
    }
}
export const cookiesEnabled = () => navigator.cookieEnabled
// ============================== session storage
export function getSessionStorage(name, options = {}) {
    try {
        if (!isString(name)) return false
        // eslint-disable-next-line no-undef
        var value = sessionStorage.getItem(name)
        if (isObject(options) && !isEmpty(value)) {
            if (options.encode === true) value = window.atob(value)
            if (options.json === true) value = JSON.parse(value)
        }
        return value
    } catch (err) {
        return undefined
    }
}
export function setSessionStorage(name, value, options = {}) {
    try {
        if (!isString(name)) return false
        if (isObject(options) && !isEmpty(value)) {
            if (options.json === true) value = JSON.stringify(value)
            if (options.encode === true) value = window.btoa(value)
        }
        // eslint-disable-next-line no-undef
        sessionStorage.setItem(name, value)
        return true
    } catch (err) {
        return false
    }
}
export function delSessionStorage(name) {
    try {
        if (!isString(name)) return false
        // eslint-disable-next-line no-undef
        sessionStorage.removeItem(name)
        return true
    } catch (err) {
        return false
    }
}
export const sessionStorageEnabled = () => {
    try {
        const key = `__MZ_Storage_Test__`
        // eslint-disable-next-line no-undef
        sessionStorage.setItem(key, null)
        // eslint-disable-next-line no-undef
        sessionStorage.removeItem(key)
        return true
    } catch (e) {
        return false
    }
}
// ============================== local storage
export function getLocalStorage(name, options = {}) {
    try {
        if (!isString(name)) return false
        // eslint-disable-next-line no-undef
        var value = localStorage.getItem(name)
        if (isObject(options) && !isEmpty(value)) {
            if (options.encode === true) value = window.atob(value)
            if (options.json === true) value = JSON.parse(value)
        }
        return value
    } catch (err) {
        return undefined
    }
}
export function setLocalStorage(name, value, options = {}) {
    try {
        if (!isString(name)) return false
        if (isObject(options) && !isEmpty(value)) {
            if (options.json === true) value = JSON.stringify(value)
            if (options.encode === true) value = window.btoa(value)
        }
        // eslint-disable-next-line no-undef
        localStorage.setItem(name, value)
        return true
    } catch (err) {
        return false
    }
}
export function delLocalStorage(name) {
    try {
        if (!isString(name)) return false
        // eslint-disable-next-line no-undef
        localStorage.removeItem(name)
        return true
    } catch (err) {
        return false
    }
}
export const localStorageEnabled = () => {
    try {
        const key = `__MZ_Storage_Test__`
        // eslint-disable-next-line no-undef
        localStorage.setItem(key, null)
        // eslint-disable-next-line no-undef
        localStorage.removeItem(key)
        return true
    } catch (e) {
        return false
    }
}
// ============================== arr
export function sumArr(array, valueKey) {
    try {
        if (!isArray(array)) return 0
        var n = 0
        for (var i in array) {
            if (valueKey) n += parseFloat(array[i][valueKey] ?? 0)
            else n += parseFloat(array[i] ?? 0)
        }
        return n
    } catch (err) {
        return NaN
    }
}
export function sumArrKeys(array, valueKeys) {
    try {
        if (!isArray(array) || !isArray(valueKeys)) return undefined
        // create object
        var obj = {}
        for (var key of valueKeys) obj[key] = 0
        // sum
        for (var i in array) {
            for (var k of valueKeys) {
                if (array[i][k]) obj[k] += parseFloat(array[i][k]) ?? 0
            }
        }
        return obj
    } catch (err) {
        return undefined
    }
}
export function sortArray(
    array = null,
    reverse = false,
    property = null,
    process = (v) => v
) {
    try {
        if (!isArray(array)) return undefined
        return array.sort(function (a, b) {
            var rev = reverse === true ? -1 : 1
            if (isString(property)) {
                a = a[property]
                b = b[property]
            }
            //
            if (isFunction(process)) {
                a = process(a)
                b = process(b)
            }
            //
            if (a < b) return -1 * rev
            if (a > b) return 1 * rev
            return 0
        })
    } catch (err) {
        return undefined
    }
}
export function filterArray({
    array,
    filterData,
    broad = false,
    broadSearches = []
}) {
    try {
        if (!isArray(array) || !isObject(filterData)) return undefined
        var list = []
        for (var obj of array) {
            var match = true
            var points = 0
            for (var k in filterData) {
                if (filterData[k]) {
                    if (isArray(filterData[k])) {
                        var m = true
                        for (var v of filterData[k]) {
                            if (v) {
                                if (
                                    (broadSearches.includes(k) && obj[k].indexOf(v) > -1) ||
                                    (!broadSearches.includes(k) && obj[k] === v)
                                ) {
                                    points++
                                    m = true
                                    break
                                } else m = false
                            }
                        }
                        if (!m) match = false
                    } else {
                        if (
                            (broadSearches.includes(k) &&
                                obj[k].indexOf(filterData[k]) > -1) ||
                            (!broadSearches.includes(k) && obj[k] === filterData[k])
                        ) {
                            points++
                        } else match = false
                    }
                }
            }
            if (broad && points > 0) {
                obj.MZFILTERPOINTS = points
                list.push(obj)
            } else if (!broad && match) list.push(obj)
        }
        if (broad) list = sortArray(list, false, 'MZFILTERPOINTS')
        return list
    } catch (err) {
        return array
    }
}
export function crawl(array, keys, fallback = undefined) {
    try {
        if (!isArray(array) && !isArray(keys) && !isObject(array)) return fallback
        // eslint-disable-next-line no-undef, no-prototype-builtins
        var v = array.hasOwnProperty(keys[0]) ? array[keys[0]] : null
        for (var i = 1; i < keys.length; i++) {
            // eslint-disable-next-line no-undef, no-prototype-builtins
            if (v && v.hasOwnProperty(keys[i])) {
                v = v[keys[i]]
            } else v = null
        }
        return v || fallback
    } catch (err) {
        return undefined
    }
}
export function generateArray(length, callback = (i, arr) => null) {
    try {
        if (!isNumber(length) || !isFunction(callback)) return []
        var arr = []
        for (var i = 0; i < length; i++) {
            var v = callback(i, arr)
            if (v) arr.push(v)
        }
        return arr
    } catch (err) {
        return undefined
    }
}
export function forEach(array, callback = (key, value, arr) => null) {
    try {
        if (!isArray(array) || !isFunction(callback)) return []
        var arr = []
        for (var k in array) {
            var v = callback(k, array[k], arr)
            if (v !== undefined) arr.push(v)
        }
        return arr
    } catch (err) {
        return undefined
    }
}
export function countIf(array, condition = (k, v) => null) {
    try {
        if (!isArray(array) || !isFunction(condition)) return 0
        var i = 0
        for (var k in array) {
            if (condition(k, array[k])) i++
        }
        return i
    } catch (err) {
        return undefined
    }
}
export function arrayKey(array, key) {
    try {
        if (!isArray(array)) return []
        var arr = []
        for (var k in array) {
            arr.push(array[k][key])
        }
        return arr
    } catch (err) {
        return undefined
    }
}
export function unique(array) {
    try {
        if (!isArray(array)) return []
        var arr = []
        for (var v of array) {
            if (!arr.includes(v)) arr.push(v)
        }
        return arr
    } catch (err) {
        return undefined
    }
}
export function cleanArray(array) {
    try {
        if (!isArray(array)) return []
        for (var k in array) {
            if (isEmpty(array[k])) delete array[k]
        }
        return array
    } catch (err) {
        return undefined
    }
}
export function arrayToTree(flatArr, childValue, childrenKey, parentKey, childKey) {
    try {
        var arr = []
        // loop data
        // get key of arr/obj
        for (var i in flatArr) {
            // easy access
            var obj = JSON.parse(JSON.stringify(flatArr[i]))
            // check if in parent
            if (obj[childKey] === childValue) {
                // if in parent get children of current
                var arr2 = arrayToTree(
                    flatArr,
                    obj[parentKey],
                    childrenKey,
                    parentKey,
                    childKey
                )
                if (arr2.length > 0) obj[childrenKey] = arr2
                // push onto big tree after finding Children
                arr.push(obj)
            }
        }
        // return arr
        return arr
    } catch (err) {
        return undefined
    }
}
export function arrayFromTree(treeArr, childrenKey) {
    try {
        var arr = []
        // loop data
        // get key of arr/obj
        for (var i in treeArr) {
            // easy access
            var obj = JSON.parse(JSON.stringify(treeArr[i]))
            // check has children
            if (obj[childrenKey]) {
                // loop children to get flat ass
                var arr2 = arrayFromTree(obj[childrenKey], childrenKey)
                // append children to big array
                for (var i2 in arr2) arr.push(arr2[i2])
            }
            // delete unwanted key
            delete obj[childrenKey]
            arr.push(obj)
        }
        // return arr
        return arr
    } catch (err) {
        return undefined
    }
}
export function sumTreeArray(treeArr, childrenKey, valueKey, outputKey) {
    try {
        var arr = JSON.parse(JSON.stringify(treeArr))
        // loop data
        // get key of arr/obj
        for (var i in arr) {
            // check if in parent
            // check sumkey
            arr[i][outputKey] = parseFloat(arr[i][valueKey] ?? 0)
            // check if has children
            if (arr[i][childrenKey]) {
                // get sums from children
                arr[i][childrenKey] = sumTreeArray(
                    arr[i][childrenKey],
                    childrenKey,
                    valueKey,
                    outputKey
                )
                // add sums to parent
                for (var c in arr[i][childrenKey])
                    arr[i][outputKey] += parseFloat(
                        arr[i][childrenKey][c][outputKey] ?? 0
                    )
            }
        }
        // return arr
        return arr
    } catch (err) {
        return undefined
    }
}
// ============================== formdata
export function formDataToObject(formData) {
    try {
        if (!isClass(formData, "FormData")) return undefined
        let obj = {};
        for (let pair of formData.entries()) {
            if (pair[0].indexOf("[]") > 0 && !isArray(obj[pair[0]])) obj[pair[0]] = [];
            if (isArray(obj[pair[0]])) obj[pair[0]].push(pair[1]);
            else obj[pair[0]] = pair[1];
        }
        return obj;
    } catch (err) {
        return undefined
    }
}
// ============================== obj
export function reverseObjectPairs(object) {
    try {
        if (!isObject(object)) return undefined
        let obj = {};
        for (let k in object) {
            let obj = {};
            obj[toString(object[k])] = k;
        }
        return obj;
    } catch (err) {
        return undefined
    }
}
export function objectToFormData(object) {
    try {
        if (!isObject(object)) return undefined
        let fd = new FormData();
        for (let k in object) {
            if (isArray(object[k])) {
                for (let v of object[k]) fd.append(`${k}[]`, v);
            } else {
                fd.set(k, object[k])
            }
        }
        return fd;
    } catch (err) {
        return undefined
    }
}
export function arrayOfObjectsToFormData(array) {
    try {
        if (!isArray(array)) return undefined
        let fd = new FormData();
        for (let obj of array) {
            for (let k in obj) {
                fd.append(`${k}[]`, obj[k]);
            }
        }
        return fd;
    } catch (err) {
        console.log(err)
        return undefined
    }
}
// ============================== math
export function linkObjectData(data, formData, values, process) {
    try {
        if (!isArray(data) || !isClass(formData, "FormData") || !isObject(values, true))
            return undefined
        let arr = [];
        // values
        let exclude = [];
        for (let k in values) {
            exclude.push(values[k][0])
            exclude.push(values[k][1])
        }
        // keys
        let formKeys = {};
        for (let k of unique(Array.from(formData.keys()))) {
            if (!exclude.includes(k)) {
                if (k.indexOf("[]") > 0) formKeys[k] = formData.getAll(k);
                else formKeys[k] = formData.get(k);
            }
        }
        //link
        for (let i in data) {
            let obj = {};
            let remove = false;
            for (let k in formKeys) {
                if (isArray(formKeys[k])) {
                    obj[k] = [];
                    for (let v of formKeys[k]) {
                        
                        if (values[k]) {
                            let vi = formData.getAll(values[k][0]).findIndex(o => o === v);
                            if (vi > -1) {
                                obj[k] = formData.getAll(values[k][1])[vi];
                            } else remove = true;
                        }
                        else obj[k].push(data[i][v])
                    }
                } else {
                    if (values[k]) {
                        let v = data[i][formKeys[k]];
                        let vi = formData.getAll(values[k][0]).findIndex(o => o === v);
                        if (vi > -1) {
                            obj[k] = formData.getAll(values[k][1])[vi];
                        } else remove = true;
                    }
                    else obj[k] = data[i][formKeys[k]];
                }
            }
            if (!remove) {
                if (isObject(process)) {
                    for (let k in process) {
                        if (isFunction(process[k]) && obj.hasOwnProperty(k)) {
                            obj[k] = process[k](obj[k]);
                        }
                    }
                }
                arr.push(obj);
            }
        }
        return arr;
    } catch (err) {
        return undefined
    }
}
// ============================== math
export function loopNum(value, step, min, max) {
    if (!isNumber(value) || !isNumber(step) || !isNumber(min) || !isNumber(max))
        return NaN
    value = value + step
    if (value > max) {
        value -= max - min + 1
    } else if (value < min) {
        value += max - min + 1
    }
    return value
}
export function clamp(value, min, max) {
    if (!isNumber(value) || !isNumber(min) || !isNumber(max)) return NaN
    return Math.min(max, Math.max(value, min))
}
export function percent(value, max, multiplier = 100) {
    if (!isNumber(value) || !isNumber(max) || !isNumber(multiplier)) return NaN
    return (value / max) * multiplier
}
export function fromPercent(value, min = 0, max = 0, multiplier = 100) {
    if (
        !isNumber(value) ||
        !isNumber(min) ||
        !isNumber(max) ||
        !isNumber(multiplier)
    )
        return NaN
    return (max - min) * (value / multiplier)
}
export function round(value, percision = 0) {
    if (!isNumber(value) || !isNumber(percision)) return NaN
    return +parseFloat(value).toFixed(percision)
}
export function random(min = 0, max = 1) {
    if (!isNumber(min) || !isNumber(max)) return NaN
    return Math.random() * (max - min + 1) + min
}
export function readable(value, percision = null, seperator = ',') {
    try {
        if (!isNumber(value) || !isString(seperator)) return NaN
        //
        var num = Math.abs(round(value, percision)).toString().split('.')
        var str = num[0] ?? '0'
        var dec = num[1] ?? '0'
        var res = ''
        //
        if (str.length > 3) {
            var arr = []
            for (var i = str.length; i > 0; i = i - 3)
                arr.push(str.slice(i - 3 < 0 ? 0 : i - 3, i))
            arr = arr.reverse()
            //
            res = arr.join(seperator)
        } else res = str
        // add sign
        if (Math.sign(value) < 0) res = `-${res}`
        // add decimals
        if (percision > 0) res = `${res}.${pad(dec, percision, '0', false)}`
        //
        return res.toString()
    } catch (err) {
        return NaN
    }
}
// ============================== str
export function pad(value, length, padChar = '0', left = true) {
    try {
        if (
            isNull(value) ||
            !isNumber(length) ||
            !isString(padChar) ||
            !isBoolean(left)
        )
            return value
        value = toString(value)
        var arr = Array(Math.abs(length) - String(value).length + 1).join(
            padChar || '0'
        )
        if (left) return arr + value
        else return value + arr
    } catch (err) {
        return value
    }
}
export function strSize(string) {
    return new TextEncoder().encode(string ?? '').length / 1024
}
// ============================== file
export function getFileContentAsText(file) {
    return new Promise((resolve, reject) => {
        try {
            if (!isFile(file)) return undefined
            // eslint-disable-next-line no-undef, no-prototype-builtins
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        } catch (err) {
            return undefined
        }
    })
}
export function getFileContentAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
        try {
            if (!isFile(file)) return undefined
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-undef, no-prototype-builtins
                const reader = new FileReader()
                reader.readAsArrayBuffer(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        } catch (err) {
            return undefined
        }
    })
}
export function getFileContentAsBinaryString(file) {
    return new Promise((resolve, reject) => {
        try {
            if (!isFile(file)) return undefined
            // eslint-disable-next-line no-undef, no-prototype-builtins
            const reader = new FileReader()
            reader.readAsBinaryString(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        } catch (err) {
            return undefined
        }
    })
}
export function getFileContentAsDataURL(file) {
    return new Promise((resolve, reject) => {
        try {
            if (!isFile(file)) return undefined
            // eslint-disable-next-line no-undef, no-prototype-builtins
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        } catch (err) {
            return undefined
        }
    })
}
export function downloadFile(filename, data, type = 'text/plain') {
    try {
        if (!isString(filename) || !isString(data) || !isString(type)) return false
        // eslint-disable-next-line no-undef, no-prototype-builtins
        const blob = new Blob([data], { type: type })
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename)
        } else {
            const el = window.document.createElement('a')
            el.href = window.URL.createObjectURL(blob)
            el.download = filename
            document.body.appendChild(el)
            el.click()
            document.body.removeChild(el)
        }
        return true
    } catch (err) {
        return false
    }
}
//
export function parseDataSheet(
    file,
    XLSX,
    customRowDelimiter = null,
    customColumnDelimiter = null
) {
    // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            if (!isFile(file)) return undefined
            var extension = (file.name.split('.').pop() || '').toLowerCase()
            var keys = []
            var data = []
            var fileData
            if (extension === 'xlsx' || extension === 'xls')
                fileData = await getFileContentAsBinaryString(file)
            else fileData = await getFileContentAsText(file)
            if (!fileData) return undefined
            if ((extension === 'xlsx' || extension === 'xls') && XLSX) {
                var workbook = XLSX.read(fileData, {
                    type: 'binary'
                })
                // Here is your object
                data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
                // loop data
                for (var k in data) {
                    var hasData = false
                    // set keys
                    for (var k2 in data[k]) {
                        if (!keys.includes(k2)) keys.push(k2)
                        if (!isEmpty(data[k][k2])) hasData = true
                    }
                    // remove empties like formulas
                    if (!hasData) delete data[k]
                }
                // return data
                resolve({
                    keys: keys,
                    data: data
                })
            } else {
                customRowDelimiter = customRowDelimiter ?? '\r\n'
                customColumnDelimiter =
                    customColumnDelimiter ?? (extension === 'tsv' ? '\t' : ',')
                var rows = fileData.split(customRowDelimiter)
                keys = rows[0].split(customColumnDelimiter)
                for (var i = 1; i < rows.length; i++) {
                    var column = rows[i]
                    if (column) {
                        var arr = {}
                        column = column.split(customColumnDelimiter)
                        for (var i2 = 0; i2 < column.length; i2++) {
                            arr[keys[i2]] = column[i2]
                        }
                        data.push(arr)
                    }
                }
                resolve({
                    keys: keys,
                    data: data
                })
            }
        } catch (err) {
            reject(err)
        }
    })
}
// ============================== translation
// class
export default class Translation {
    static #locale;
    static #locales = {};
    static #fillerTag = null;
    static #processKey = null;
    // init
    static init(fillerTag = null, processKey = null) {
        try {
            Translation.#fillerTag = fillerTag
            Translation.#processKey = processKey
            // locale
            Translation.#locale = getCookie('locale')
            var locales = Object.keys(Translation.#locales)
            if (!Translation.#locale || !locales.includes(Translation.#locale)) {
                var browser = (
                    window.navigator.userLanguage || window.navigator.language
                ).split('-')[0]
                if (locales.includes(browser)) Translation.#locale = browser
                else Translation.#locale = locales[0]
            }
            // other shit
            document.dir = Translation.getDir()
            document.body.setAttribute('dir', Translation.getDir())
            setCookie('locale', Translation.#locale)
        } catch (e) { }
    }
    // add locale before init
    static addLocale(code, name, rtl, data) {
        try {
            Translation.#locales[code] = {
                code: code,
                name: name,
                dir: rtl ? 'rtl' : 'ltr',
                data: data
            }
        } catch (e) { }
    }
    // set
    static setLocale(code, reloadPage = false) {
        try {
            if (Translation.#locales[code]) {
                Translation.#locale = code;
                document.dir = Translation.getDir()
                document.body.setAttribute('dir', Translation.getDir())
                setCookie('locale', Translation.#locale)
                if (reloadPage) window.location.reload(false);
            }
        } catch (e) { }
    }
    // get Data
    static getLocale() {
        return Translation.#locale;
    }
    static getDir() {
        return Translation.#locales[Translation.#locale].dir;
    }
    // translate
    static translate(key, fillers = null) {
        try {
            if (Translation.#processKey) key = Translation.#processKey(key)
            if (Translation.#locales && Translation.#locales[Translation.#locale]) {
                var str = Translation.#locales[Translation.#locale].data[key] ?? 'N/A'
                if (Translation.#fillerTag && fillers) {
                    var i = 0
                    while (str.includes(Translation.#fillerTag)) {
                        str = str.replace(Translation.#fillerTag, fillers[i] ?? '')
                        i++
                    }
                }
                return str
            }
            return 'N/A'
        } catch (e) {
            return 'N/A'
        }
    }
}
// shortcut function
export const T = Translation.translate
// ============================== image
export class Image {
    // image
    canvas = document.createElement('canvas')
    sourceImage
    width
    height
    ratio
    constructor(image) {
        try {
            if (!(image instanceof Image)) return undefined
            this.sourceImage = image
            this.width = image.naturalWidth
            this.height = image.naturalHeight
            this.ratio = image.naturalWidth / image.naturalHeight
        } catch (err) {
            return undefined
        }
    }

    // apply changes
    apply({
        quality = 1,
        backgroundColor = '#ffffff',
        removeBg = false,
        removeBgTolerance = 10,
        cropSides = false,
        cropSidesTolerance = 10,
        resizeW = null,
        resizeH = null,
        resizeR = true,
        cutT = null,
        cutB = null,
        cutR = null,
        cutL = null
    }) { }

    // getImage async
    static getImage(dataURL) {
        return new Promise((resolve) => {
            var image = new Image()
            image.onload = (evt) => resolve(image)
            image.src = dataURL
        })
    }
}
// ============================== response
export class Response {
    // Response
    success = true
    message = null
    data = null
    constructor(success = true, message = null, data = null) {
        this.success = success
        this.message = message ? message.toString() : null
        this.data = data
    }

    static success(message, data) {
        return new Response(true, message, data)
    }

    static error(message, data) {
        return new Response(false, message, data)
    }
}
// shortcut function
export const Success = Response.success
export const Error = Response.error
// ============================== date
// const
const MonthsTextShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
]
const MonthsTextLong = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]
const WeekdaysTextShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const WeekdaysTextLong = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]
const DateTimesFuncs = {
    unix: (date) => date.date.getTime(),
    Y: (date) => date.year(true),
    Ym: (date) => +`${DateTimesFuncs.Y(date)}${date.month(true)}`,
    Ymd: (date) => +`${DateTimesFuncs.Ym(date)}${date.day(true)}`,
    YmdH: (date) => +`${DateTimesFuncs.Ymd(date)}${date.hour(true)}`,
    YmdHi: (date) => +`${DateTimesFuncs.YmdH(date)}${date.minute(true)}`,
    YmdHis: (date) => +`${DateTimesFuncs.YmdHi(date)}${date.second(true)}`,
    YW: (date) => +`${DateTimesFuncs.Y(date)}${date.week(null, true)}`,
    YWw: (date) => +`${DateTimesFuncs.YW(date)}${date.weekday()}`,
    YWwH: (date) => +`${DateTimesFuncs.YWw(date)}${date.hour(true)}`,
    YWwHi: (date) => +`${DateTimesFuncs.YWwH(date)}${date.minute(true)}`,
    YWwHis: (date) => +`${DateTimesFuncs.YWwHi(date)}${date.second(true)}`
}
export const DateTimes = {
    unix: 'unix',
    Y: 'Y',
    Ym: 'Ym',
    Ymd: 'Ymd',
    YmdH: 'YmdH',
    YmdHi: 'YmdHi',
    YmdHis: 'YmdHis',
    YW: 'YW',
    YWw: 'YWw',
    YWwH: 'YWwH',
    YWwHi: 'YWwHi',
    YWwHis: 'YWwHis'
}
const DateFormats = {
    d: (date) => date.monthDay(true),
    D: (date) => date.weekdayText(true),
    j: (date) => date.monthDay(),
    l: (date) => date.weekdayText(),
    w: (date) => date.weekday(),
    W: (date) => date.week(null, true),
    z: (date) => date.yearDay(),
    F: (date) => date.monthText(),
    m: (date) => date.month(true),
    M: (date) => date.monthText(true),
    n: (date) => date.month(),
    t: (date) => date.monthDays(),
    L: (date) => date.leapYear(true),
    Y: (date) => date.year(true),
    y: (date) => date.year2(true),
    a: (date) => date.timePeriod(),
    A: (date) => date.timePeriod(true),
    g: (date) => date.hour12(),
    G: (date) => date.hour(),
    h: (date) => date.hour12(true),
    H: (date) => date.hour(true),
    i: (date) => date.minute(true),
    s: (date) => date.second(true),
    e: (date) => date.timezone(),
    E: (date) => date.timezone(true),
    O: (date) => date.timezoneOffset()
}
// class
export class DateTime {
    // date
    date
    SOW = null
    constructor(/**/) {
        try {
            var args = arguments
            if (args.length === 0) this.date = new Date(Date.now())
            else if (args.length === 1) {
                if (args[0] instanceof DateTime) this.date = new Date(args[0].date)
                else this.date = new Date(args[0])
            } else if (args.length > 1)
                this.date = new Date(
                    args[0] ?? 0,
                    (args[1] ?? 1) - 1,
                    args[2] ?? 1,
                    args[3] ?? 0,
                    args[4] ?? 0,
                    args[5] ?? 0,
                    args[6] ?? 0
                )
            if (!this.date) return undefined
        } catch (err) {
            return undefined
        }
    }

    setSOW(firstWeekday = null) {
        try {
            if (firstWeekday) this.SOW = clamp(firstWeekday, 0, 6)
            return this
        } catch (err) {
            return undefined
        }
    }

    // misc
    timezone(name = false) {
        try {
            // name
            if (name) return Intl.DateTimeFormat().resolvedOptions().timeZone
            // normal
            return this.date
                .toLocaleDateString(undefined, {
                    day: '2-digit',
                    timeZoneName: 'short'
                })
                .slice(4)
        } catch (err) {
            return undefined
        }
    }

    timezoneOffset() {
        try {
            return this.date.getTimezoneOffset()
        } catch (err) {
            return undefined
        }
    }

    leapYear(numeric = false) {
        try {
            var v = this.year() % 400 === 0 && this.year() % 4 === 0
            // numeric
            if (numeric) return v ? 1 : 0
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    // year
    year(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getFullYear().toString(), 4, '0', true)
            // normal
            return this.date.getFullYear()
        } catch (err) {
            return undefined
        }
    }

    totalYears() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 365)
        } catch (err) {
            return undefined
        }
    }

    year2(padded = false) {
        try {
            var v = this.year(true).substr(-2, 2) - 0
            // padded
            if (padded) return pad(v.toString(), 2, '0', true)
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    // month
    month(padded = false) {
        try {
            var v = this.date.getMonth() + 1
            // padded
            if (padded) return pad(v.toString(), 2, '0', true)
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    totalMonths() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 30)
        } catch (err) {
            return undefined
        }
    }

    monthText(short = true) {
        try {
            // short
            if (short) return MonthsTextShort[this.month() - 1]
            // long
            return MonthsTextLong[this.month() - 1]
        } catch (err) {
            return undefined
        }
    }

    monthDays(padded = false) {
        try {
            var v = new Date(this.year(), this.month(), 0).getDate()
            // padded
            if (padded) return pad(v.toString(), 2, '0', true)
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    // month text
    static monthNoText(month = 0, short = true) {
        try {
            // short
            if (short) return MonthsTextShort[month - 1]
            // long
            return MonthsTextLong[month - 1]
        } catch (err) {
            return undefined
        }
    }

    // week
    week(firstWeekday = null, padded = false) {
        try {
            if (this.SOW && !firstWeekday) firstWeekday = this.SOW
            if (!firstWeekday) firstWeekday = 0
            //
            var yfd = this.setMonth(1).setDay(1)
            var wd = yfd.weekday(firstWeekday)
            var d = firstWeekday > wd ? firstWeekday - (wd + 7) : firstWeekday - wd
            //
            var v = Math.floor(
                (this.getTime() - yfd.addDays(d).getTime()) / 1000 / 60 / 60 / 24 / 7
            )
            // padded
            if (padded) return pad(v.toString(), 2, '0', true)
            // normal
            return v
        } catch (err) {
            console.log(err)
            return undefined
        }
    }

    totalWeeks() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24 / 7)
        } catch (err) {
            return undefined
        }
    }

    weekFirstDay(firstWeekday = null) {
        try {
            if (this.SOW && !firstWeekday) firstWeekday = this.SOW
            if (!firstWeekday) firstWeekday = 0
            return this.addDays(
                firstWeekday > this.weekday()
                    ? firstWeekday - (this.weekday() + 7)
                    : firstWeekday - this.weekday()
            )
        } catch (err) {
            return undefined
        }
    }

    // days
    yearDay(padded = false) {
        try {
            var v = Math.floor(
                (this.getTime() - this.setMonth(1).setDay(1).getTime()) /
                1000 /
                60 /
                60 /
                24
            )
            // padded
            if (padded) return pad(v.toString(), 3, '0', true)
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    monthDay(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getDate().toString(), 2, '0', true)
            // normal
            return this.date.getDate()
        } catch (err) {
            return undefined
        }
    }

    day(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getDate().toString(), 2, '0', true)
            // normal
            return this.date.getDate()
        } catch (err) {
            return undefined
        }
    }

    totalDays() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60 / 60 / 24)
        } catch (err) {
            return undefined
        }
    }

    weekday() {
        try {
            return this.date.getDay()
        } catch (err) {
            return undefined
        }
    }

    weekdayText(short = true) {
        try {
            // short
            if (short) return WeekdaysTextShort[this.weekday()]
            // long
            return WeekdaysTextLong[this.weekday()]
        } catch (err) {
            return undefined
        }
    }

    // week text
    static weekdayNoText(weekday = 0, short = true) {
        try {
            // short
            if (short) return WeekdaysTextShort[weekday]
            // long
            return WeekdaysTextLong[weekday]
        } catch (err) {
            return undefined
        }
    }

    // time
    hour(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getHours().toString(), 2, '0', true)
            // normal
            return this.date.getHours()
        } catch (err) {
            return undefined
        }
    }

    totalHours() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60 / 60)
        } catch (err) {
            return undefined
        }
    }

    hour12(padded = false) {
        try {
            var v = this.hour() % 12 || 12
            // padded
            if (padded) return pad(v.toString(), 2, '0', true)
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    minute(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getMinutes().toString(), 2, '0', true)
            // normal
            return this.date.getMinutes()
        } catch (err) {
            return undefined
        }
    }

    totalMinutes() {
        try {
            return Math.floor(this.date.getTime() / 1000 / 60)
        } catch (err) {
            return undefined
        }
    }

    second(padded = false) {
        try {
            // padded
            if (padded) return pad(this.date.getSeconds().toString(), 2, '0', true)
            // normal
            return this.date.getSeconds()
        } catch (err) {
            return undefined
        }
    }

    totalSeconds() {
        try {
            return Math.floor(this.date.getTime() / 1000)
        } catch (err) {
            return undefined
        }
    }

    timePeriod(uppercase = false) {
        try {
            var v = this.hour() < 12 ? 'am' : 'pm'
            // padded
            if (uppercase) return v.toUpperCase()
            // normal
            return v
        } catch (err) {
            return undefined
        }
    }

    // add
    addYears(years) {
        try {
            return new DateTime(
                this.year() + years,
                this.month(),
                this.monthDay(),
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    addMonths(months) {
        try {
            return new DateTime(
                this.year(),
                this.month() + months,
                this.monthDay(),
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    addDays(days) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay() + days,
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    addHours(hours) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                this.hour() + hours,
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    addMinutes(minutes) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                this.hour(),
                this.minute() + minutes,
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    addSeconds(seconds) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                this.hour(),
                this.minute(),
                this.second() + seconds
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    // add
    setYear(years) {
        try {
            return new DateTime(
                years,
                this.month(),
                this.monthDay(),
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    setMonth(months) {
        try {
            return new DateTime(
                this.year(),
                months,
                this.monthDay(),
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    setDay(days) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                days,
                this.hour(),
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    setHour(hours) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                hours,
                this.minute(),
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    setMinute(minutes) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                this.hour(),
                minutes,
                this.second()
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    setSecond(seconds) {
        try {
            return new DateTime(
                this.year(),
                this.month(),
                this.monthDay(),
                this.hour(),
                this.minute(),
                seconds
            ).setSOW(this.SOW)
        } catch (err) {
            return undefined
        }
    }

    // static formats
    formatDate() {
        return `${DateFormats.Y(this)}-${DateFormats.m(this)}-${DateFormats.d(
            this
        )}`
    }

    formatTime() {
        return `${DateFormats.h(this)}:${DateFormats.i(this)}:${DateFormats.s(
            this
        )} ${DateFormats.a(this)}`
    }

    formatDateTime() {
        return `${this.formatDate()} ${this.formatTime()}`
    }

    // format
    getFormated(format = 'Y-m-d H-i-s') {
        try {
            var str = format
            for (var k in DateFormats) {
                str = str.replaceAll(k, `{${k}}`)
            }
            for (var k2 in DateFormats) {
                if (str.includes(`{${k2}}`))
                    str = str.replaceAll(`{${k2}}`, DateFormats[k2](this))
            }
            return str
        } catch (err) {
            return undefined
        }
    }

    // id (int)
    getTime(id = DateTimes.unix) {
        try {
            return (DateTimesFuncs[id] ?? DateTimesFuncs.unix)(this)
        } catch (err) {
            console.log(err)
            return undefined
        }
    }

    // compare (-1, 0, 1)
    compare(date, time = DateTimes.Ymd) {
        try {
            date = new DateTime(date).setSOW(this.SOW)
            var d1 = this.getTime(time)
            var d2 = date.getTime(time)
            //
            if (d1 === d2) return 0
            else if (d1 > d2) return 1
            else if (d1 < d2) return -1
        } catch (err) {
            return undefined
        }
    }

    // between (true, false)
    between(startDate, endDate, time = DateTimes.Ymd, strictlyBetween = false) {
        try {
            startDate = new DateTime(startDate).setSOW(this.SOW)
            endDate = new DateTime(endDate).setSOW(this.SOW)
            var d = this.getTime(time)
            var sd = startDate.getTime(time)
            var ed = endDate.getTime(time)
            if (
                (strictlyBetween === false && sd <= d && d <= ed) ||
                (strictlyBetween === true && sd < d && d < ed)
            )
                return true
            return false
        } catch (err) {
            return undefined
        }
    }

    // intersect (true, false)
    static periodsIntersect(
        startDate1,
        endDate1,
        startDate2,
        endDate2,
        time = DateTimes.Ymd,
        firstWeekday = null
    ) {
        try {
            startDate1 = new DateTime(startDate1).setSOW(firstWeekday)
            endDate1 = new DateTime(endDate1).setSOW(firstWeekday)
            startDate2 = new DateTime(startDate2).setSOW(firstWeekday)
            endDate2 = new DateTime(endDate2).setSOW(firstWeekday)
            var sd1 = startDate1.getTime(time)
            var ed1 = endDate1.getTime(time)
            var sd2 = startDate2.getTime(time)
            var ed2 = endDate2.getTime(time)
            if (
                (sd1 <= sd2 && sd2 <= ed1) ||
                (sd1 <= ed2 && ed2 <= ed1) ||
                (sd2 <= sd1 && sd1 <= ed2) ||
                (sd2 <= ed1 && ed1 <= ed2)
            )
                return true
            return false
        } catch (err) {
            return undefined
        }
    }

    // min/max
    static max(dates, time = DateTimes.Ymd, firstWeekday = null) {
        try {
            var d = forEach(dates, (k, v) =>
                new DateTime(v).setSOW(firstWeekday).getTime(time)
            )
            var m = Math.max(...d)
            var date = dates.find((o) => o.getTime(time) === m)
            return new DateTime(date).setSOW(firstWeekday)
        } catch (err) {
            return undefined
        }
    }

    static min(dates, time = DateTimes.Ymd, firstWeekday = null) {
        try {
            var d = forEach(dates, (k, v) =>
                new DateTime(v).setSOW(firstWeekday).getTime(time)
            )
            var m = Math.min(...d)
            var date = dates.find((o) => o.getTime(time) === m)
            return new DateTime(date).setSOW(firstWeekday)
        } catch (err) {
            return undefined
        }
    }

    // data
    static calendarMonthData(date, startWeekday = 0) {
        try {
            date = new DateTime(date)
            if (!date) return undefined
            var weekdays = []
            var daysData = []
            var monthData = []
            var startDate = date.setDay(1).weekFirstDay(startWeekday)
            // week days
            for (var i = 0; i < 7; i++) {
                weekdays.push(loopNum(startWeekday + i - 1, 1, 0, 6))
            }
            // days data
            for (var di = 0; di <= 41; di++) {
                daysData.push(startDate.addDays(di))
            }
            // month data
            for (var wi = 0; wi <= 6; wi++) {
                monthData[wi] = daysData.slice(wi * 7, wi * 7 + 7)
            }
            return {
                date: date,
                year: date.year(),
                month: date.month(),
                startDate: daysData[0],
                endDate: daysData[daysData.length - 1],
                weekdays: weekdays,
                daysData: daysData,
                monthData: monthData
            }
        } catch (err) {
            return undefined
        }
    }
}
// ============================== color
export class Color {
    // color
    rgba = [0, 0, 0, 0]
    hsl = [0, 0, 0]
    brightness = 0
    constructor(r, g, b, a) {
        try {
            this.rgba[0] = r ?? 0
            this.rgba[1] = g ?? 0
            this.rgba[2] = b ?? 0
            this.rgba[3] = a ?? 1
            this.brightness =
                (Math.max(this.rgba[0], this.rgba[1], this.rgba[2]) +
                    Math.min(this.rgba[0], this.rgba[1], this.rgba[2])) /
                2 /
                255
        } catch (err) {
            return undefined
        }
    }

    // parse
    static parse(color) {
        try {
            if (color instanceof Color) return color
            var obj = [0, 0, 0, 1]
            // # F f f f
            if (color.match(/^#([a-f0-9]{3})$/i)) {
                // #f0f
                obj[0] = parseInt(color.substr(1, 1) + color.substr(1, 1), 16)
                obj[1] = parseInt(color.substr(2, 1) + color.substr(2, 1), 16)
                obj[2] = parseInt(color.substr(3, 1) + color.substr(3, 1), 16)
            } else if (color.match(/^#([a-f]){1}([a-f0-9]{3})$/i)) {
                // #ef0f
                obj[0] = parseInt(color.substr(2, 1) + color.substr(2, 1), 16)
                obj[1] = parseInt(color.substr(3, 1) + color.substr(3, 1), 16)
                obj[2] = parseInt(color.substr(4, 1) + color.substr(4, 1), 16)
                obj[3] = parseInt(color.substr(1, 1) + color.substr(1, 1), 16) / 255
            } else if (color.match(/^#([a-f0-9]{6})$/i)) {
                // #ff00ff
                obj[0] = parseInt(color.substr(1, 2), 16)
                obj[1] = parseInt(color.substr(3, 2), 16)
                obj[2] = parseInt(color.substr(5, 2), 16)
            } else if (color.match(/^#([a-f]){2}([a-f0-9]{6})$/i)) {
                // #eeff00ff
                obj[0] = parseInt(color.substr(3, 2), 16)
                obj[1] = parseInt(color.substr(5, 2), 16)
                obj[2] = parseInt(color.substr(7, 2), 16)
                obj[3] = parseInt(color.substr(1, 2), 16) / 255
            } else if (color.match(/^rgb\(([0-9 .]+),([0-9 .]+),([0-9 .]+)\)$/i)) {
                // rgb(255,0,255)
                color = color.substr(4, color.length - 5).split(',')
                obj[0] = clamp(parseFloat(color[0]), 0, 255)
                obj[1] = clamp(parseFloat(color[1]), 0, 255)
                obj[2] = clamp(parseFloat(color[2]), 0, 255)
            } else if (
                color.match(/^rgba\(([0-9 .]+),([0-9 .]+),([0-9 .]+),([0-9 .]+)\)$/i)
            ) {
                // rgba(255,0,255,100)
                color = color.substr(5, color.length - 6).split(',')
                obj[0] = clamp(parseFloat(color[0]), 0, 255)
                obj[1] = clamp(parseFloat(color[1]), 0, 255)
                obj[2] = clamp(parseFloat(color[2]), 0, 255)
                obj[3] = clamp(parseFloat(color[3]), 0, 1)
            } else {
                return undefined
            }
            return new Color(obj[0], obj[1], obj[2], obj[3])
        } catch (err) {
            return undefined
        }
    }

    // brightness
    setBrightness(percent) {
        try {
            if (isEmpty(percent)) return this
            var add = (percent - this.brightness) * 255
            return new Color(
                clamp(this.rgba[0] + add, 0, 255),
                clamp(this.rgba[1] + add, 0, 255),
                clamp(this.rgba[2] + add, 0, 255),
                this.rgba[3]
            )
        } catch (err) {
            return undefined
        }
    }

    // brightness
    addBrightness(percent) {
        try {
            if (isEmpty(percent)) return this
            var add = (this.brightness + percent) * 255
            return new Color(
                clamp(this.rgba[0] + add, 0, 255),
                clamp(this.rgba[1] + add, 0, 255),
                clamp(this.rgba[2] + add, 0, 255),
                this.rgba[3]
            )
        } catch (err) {
            return undefined
        }
    }

    // opacity
    setOpacity(o = 1) {
        try {
            return new Color(this.rgba[0], this.rgba[1], this.rgba[2], clamp(o, 0, 1))
        } catch (err) {
            return undefined
        }
    }

    // opacity
    addOpacity(o = 1) {
        try {
            return new Color(
                this.rgba[0],
                this.rgba[1],
                this.rgba[2],
                clamp(this.rgba[3] + o, 0, 1)
            )
        } catch (err) {
            return undefined
        }
    }

    // check neigbors
    isNeighborColor(color, tolerance = 0.12) {
        try {
            if (isClass(color, 'Color')) return
            tolerance = tolerance * 255
            return (
                Math.abs(this.rgba[0] - color.rgba[0]) <= tolerance &&
                Math.abs(this.rgba[1] - color.rgba[1]) <= tolerance &&
                Math.abs(this.rgba[2] - color.rgba[2]) <= tolerance
            )
        } catch (err) {
            return undefined
        }
    }

    // to string format
    getHEX() {
        try {
            return `#${pad(this.rgba[0].toString(16), 2)}${pad(
                this.rgba[1].toString(16),
                2
            )}${pad(this.rgba[2].toString(16), 2)}`
        } catch (err) {
            return undefined
        }
    }

    getHEXA() {
        try {
            return `#${pad(this.rgba[3].toString(16), 2)}${pad(
                this.rgba[0].toString(16),
                2
            )}${pad(this.rgba[1].toString(16), 2)}${pad(
                this.rgba[2].toString(16),
                2
            )}`
        } catch (err) {
            return undefined
        }
    }

    getRGB() {
        try {
            return `rgb(${this.rgba[0]},${this.rgba[1]},${this.rgba[2]})`
        } catch (err) {
            return undefined
        }
    }

    getRGBA() {
        try {
            return `rgba(${this.rgba[0]},${this.rgba[1]},${this.rgba[2]},${this.rgba[3]})`
        } catch (err) {
            return undefined
        }
    }

    // rgbToHSL
    static rgbToHSL(r, g, b) {
        try {
            var obj = [0, 0, 0]
            var min = Math.min(r, g, b)
            var max = Math.max(r, g, b)
            var delta = max - min
            //
            obj[1] = delta === 0 ? 0 : delta / max
            obj[2] = (max + min) / 2 / 255
            //
            // No difference
            if (delta === 0) obj[0] = 0
            else if (max === r) obj[0] = ((g - b) / delta) % 6
            else if (max === g) obj[0] = (b - r) / delta + 2
            else obj[0] = (r - g) / delta + 4
            obj[0] = Math.round(obj[0] * 60)
            if (obj[0] < 0) obj[0] += 360
            //
            return obj
        } catch (err) {
            return undefined
        }
    }
}
// ============================== timer
export class Timer {
    #interval = null
    #startTime
    #duration
    #callback
    constructor(duration = 1000, callback = (time) => { }) {
        this.#duration = duration
        this.#callback = callback
    }

    start() {
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
        this.#startTime = performance.now()
        clearInterval(this.#interval)
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
        this.#callback(performance.now() - this.#startTime)
        this.#interval = setInterval(
            // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
            () => this.#callback(performance.now() - this.#startTime),
            this.#duration
        )
    }

    stop() {
        clearInterval(this.#interval)
    }
}
// ============================== frame
export class FrameTimer {
    #playing = false
    // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
    #lastFrame = performance.now()
    #sleepDuration
    #callback

    constructor(sleepDuration = 1000, callback = (lastFrame) => { }) {
        this.#sleepDuration = sleepDuration
        this.#callback = callback
    }

    async start() {
        this.#playing = true
        while (this.#playing) {
            // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
            this.#callback(performance.now() - this.#lastFrame)
            // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
            this.#lastFrame = performance.now()
            await this.#sleep()
        }
    }

    stop() {
        this.#playing = false
    }

    async #sleep() {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve(true)
            }, this.#sleepDuration)
        })
    }
}
// ============================== HttpRequest
// constants
export const ResponseType = {
    Text: 'text',
    Json: 'json',
    Buffer: 'arraybuffer',
    Blob: 'blob',
    Document: 'document'
}
const HttpRequestStates = {
    0: 'unsent',
    1: 'opened',
    2: 'uploading',
    3: 'processing',
    4: 'downloading',
    5: 'completed'
}
// class
export class HttpRequest {
    //
    // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
    #request = new XMLHttpRequest()
    #method = null
    #address = null
    #headers = null
    #urlData = null
    #bodyData = null
    #responseType = null
    #onStateChange = null
    //
    httpCode = null
    state = null
    stateText = null
    error = null
    message = null
    response = null
    // stats
    stats = {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null
    }

    // upload
    upload = {
        start: null,
        total: 0,
        loaded: 0,
        time: 0,
        duration: 0,
        percentage: 0,
        speed: 0,
        estimate: 0
    }

    // download
    download = {
        start: null,
        total: 0,
        loaded: 0,
        time: 0,
        duration: 0,
        percentage: 0,
        speed: 0,
        estimate: 0
    }

    // public
    constructor({
        method = null,
        address = null,
        headers = null,
        urlData = null,
        bodyData = null,
        responseType = null,
        onStateChange = null
    }) {
        this.#method = method
        this.#address = address
        this.#headers = headers
        this.#urlData = urlData
        this.#bodyData = bodyData
        this.#responseType = responseType
        this.#onStateChange = onStateChange
        // encode url data
        if (this.#urlData) {
            var query = '?'
            for (var key in this.#urlData)
                query += `${encodeURIComponent(key)}=${encodeURIComponent(
                    this.#urlData[key]
                )}&`
            this.#address += query
        }
        // setState
        this.#setState(0, null)
    }

    async send() {
        return await new Promise((resolve) => {
            try {
                // request
                // response type
                if (this.#responseType) this.#request.responseType = this.#responseType
                // this.#request.timeout = 4000;
                this.#request.open(
                    this.#method,
                    this.#address,
                    true
                    // this.#requestUsername,
                    // this.#requestPassword
                )
                // headers
                if (this.#headers) {
                    for (var key in this.#headers)
                        this.#request.setRequestHeader(key, this.#headers[key])
                }
                // onloadstart
                this.#request.onloadstart = (event) => this.#setState(1, null)
                // upload onloadstart
                this.#request.upload.onloadstart = (event) => {
                    if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded)
                    this.#setState(2, null)
                }
                // upload onprogress
                this.#request.upload.onprogress = (event) => {
                    if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded)
                    this.#setState(2, null)
                }
                // upload onload
                this.#request.upload.onloadend = (event) => {
                    if (this.#onStateChange) this.#uploadCalc(event.total, event.loaded)
                    this.#setState(3, null)
                }
                // onprogress
                this.#request.onprogress = (event) => {
                    if (this.#onStateChange) this.#downloadCalc(event.total, event.loaded)
                    this.#setState(4, null)
                }
                // onload
                this.#request.onloadend = (event) => {
                    if (this.#onStateChange) this.#downloadCalc(event.total, event.loaded)
                    this.response = this.#request.response
                    this.#setState(5, null)
                    resolve(this)
                }
                // ontimeout
                this.#request.ontimeout = (event) => {
                    this.#setState(5, 'timeout')
                    resolve(this)
                }
                // onabort
                this.#request.onabort = (event) => {
                    this.#setState(5, 'aborted')
                    resolve(this)
                }
                // onerror
                this.#request.onerror = (event) => {
                    this.#setState(5, 'failed')
                    resolve(this)
                }
                // send
                this.#request.send(this.#bodyData)
            } catch (e) {
                this.#setState(5, e)
            }
        })
    }

    // private
    #uploadCalc(total, loaded) {
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
        if (!this.upload.start) this.upload.start = performance.now()
        this.upload.total = total
        this.upload.loaded = loaded
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor
        this.upload.duration = performance.now() - this.upload.start
        if (this.state !== 2) {
            this.upload.speed = 0
            this.upload.estimate = 0
        } else {
            this.upload.percentage = round(
                (this.upload.loaded / this.upload.total) * 100,
                2
            )
            this.upload.speed = round(this.upload.loaded / this.upload.duration, 2)
            this.upload.estimate = round(
                (this.upload.total - this.upload.loaded) / this.upload.speed,
                2
            )
        }
    }

    // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
    #downloadCalc(total, loaded) {
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
        if (!this.download.start) this.download.start = performance.now()
        this.download.total = total
        this.download.loaded = loaded
        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
        this.download.duration = performance.now() - this.download.start
        if (this.state !== 4) {
            this.download.speed = 0
            this.download.estimate = 0
        } else {
            this.download.percentage = round(
                (this.download.loaded / this.download.total) * 100,
                2
            )
            this.download.speed = round(
                this.download.loaded / this.download.duration,
                2
            )
            this.download.estimate = round(
                (this.download.total - this.download.loaded) / this.download.speed,
                2
            )
        }
    }


    // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
    #setState(state, error = null) {
        this.httpCode = this.#request.status
        this.state = state
        this.stateText = HttpRequestStates[state]
        this.error = error
        //
        if (!this.stats[state]) {
            // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
            this.stats[state] = performance.now()
            if (state > 0)
                this.stats[state - 1] = this.stats[state] - (this.stats[state - 1] ?? 0)
        }
        //
        if (this.#onStateChange) this.#onStateChange(this)
    }
}
// ============================== HttpRequest
// class
const SocketServerStates = {
    0: 'connecting',
    1: 'connected',
    2: 'closing',
    3: 'closed'
}
export class SocketServer {
    //
    #socket = null
    #secure = null
    #host = null
    #port = null
    #filepath = null
    #onMessage = null
    #onStateChange = null
    #url = null
    //
    #state = 0
    #connected = false
    #error = null
    // public
    constructor({
        secure = false,
        host = null,
        port = null,
        filepath = null,
        onMessage = (data) => { },
        onStateChange = (state, stateText, connected, error) => { }
    }) {
        this.#secure = secure
        this.#host = host
        this.#port = port
        this.#filepath = filepath
        this.#onMessage = onMessage
        this.#onStateChange = onStateChange
        this.connect()
    }

    connect() {
        // close first
        this.close()
        // reopen
        this.#url = `${this.#secure === true ? `wss` : `ws`}://${this.#host}:${this.#port
            }${this.#filepath ? `/${this.#filepath}` : ``}`

        console.log(this.#secure, this.#url)

        // eslint-disable-next-line no-undef, no-prototype-builtins, no-async-promise-executor, no-dupe-class-members
        this.#socket = new WebSocket(this.#url)
        this.#setState()
        this.#socket.onopen = (evt) => {
            this.#setState()
        }
        this.#socket.onerror = (evt) => {
            this.#setState()
        }
        this.#socket.onclose = (evt) => {
            this.#setState()
        }
        this.#socket.onmessage = (evt) => {
            this.#setState()
            if (this.#onMessage) this.#onMessage(evt.data)
        }
    }

    send(data) {
        if (this.#socket && this.#connected) {
            this.#socket.send(data)
        }
    }

    close() {
        if (this.#socket) {
            this.#socket.close()
            this.#socket = null
        }
    }

    #setState(error) {
        this.#state = this.#socket.readyState
        this.#connected = this.#state === 1;
        this.#error = error
        //
        if (this.#onStateChange)
            this.#onStateChange(
                this.#state,
                SocketServerStates[this.#state],
                this.#connected,
                this.#error
            )
    }
}
