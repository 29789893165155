// packages
import React from "react";
import * as MUI from "@mui/material";
// mz
import * as MZ from "./mz";
import * as MZUI from "./mz-ui";
import * as MZReact from "./mz-react";

//==============================< State
MZ.Translation.addLocale("en", "English", false, {});
MZ.Translation.addLocale("ar", "Arabic", false, {});

const TestPage = (props) => {
  MZ.Translation.init();
  return (
    <MZUI.AppTheme>
      <MZUI.Layout
        areas={{
          xs: `'header header'
         'aside main'
         'footer footer'`,
        }}
        cols={{ xs: "auto 1fr" }}
        rows={{ xs: "auto 1fr auto" }}
        spacing={1}
      >
        <MZUI.LayoutItem area={"aside"} sx={{ backgroundColor: `#00ccff` }}>
          side
        </MZUI.LayoutItem>
        <MZUI.LayoutItem area={"header"} sx={{ backgroundColor: `#00cc00` }}>
          header
          <MZUI.Flex>
            {MZ.forEach(MZ.Translation.getLocales(), (k, v) => {
              return (
                <MUI.Button
                  key={k}
                  onClick={() => {
                    MZ.Translation.setLocale(k, true);
                  }}
                  children={k}
                />
              );
            })}
          </MZUI.Flex>
        </MZUI.LayoutItem>
        <MZUI.LayoutItem
          area={"main"}
          sx={{ p: 2, backgroundColor: `#ddd` }}
          scrollX={true}
          scrollY={true}
        >
          wrap
          <MZUI.Wrap
            spacing={1}
            main={MZUI.mainAlign.start}
            cross={MZUI.crossAlign.start}
            mainItem={MZUI.mainItemAlign.start}
            crossItem={MZUI.crossItemAlign.start}
          >
            {MZ.generateArray(10, (i) => (
              <MZUI.WrapItem
                key={i}
                cols={{ xs: 6, sm: 4 }}
                sx={{
                  backgroundColor: `rgba(${i * (255 / 10)},0,0)`,
                  color: "#fff",
                }}
              >
                {i}
              </MZUI.WrapItem>
            ))}
          </MZUI.Wrap>
          grid
          <MZUI.Grid
            cols={{ xs: 4, sm: 5 }}
            spacing={1}
            rowHeight={{ xs: 25, sm: 50 }}
            main={MZUI.mainAlign.start}
            cross={MZUI.crossAlign.start}
            mainItem={MZUI.mainItemAlign.start}
            crossItem={MZUI.crossItemAlign.start}
          >
            {MZ.generateArray(50, (i) => (
              <MZUI.GridItem
                key={i}
                cols={{ xs: 1, sm: 1 }}
                sx={{
                  backgroundColor: `rgba(${i * (255 / 50)},0,0)`,
                  color: "#fff",
                }}
              >
                {i}
                {i === 0 ? (
                  <>
                    <br />
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </MZUI.GridItem>
            ))}
          </MZUI.Grid>
          flex
          <MZUI.Flex
            spacing={1}
            main={MZUI.mainAlign.start}
            cross={MZUI.crossAlign.start}
            mainItem={MZUI.mainItemAlign.start}
            crossItem={MZUI.crossItemAlign.start}
          >
            {MZ.generateArray(5, (i) => {
              return (
                <MZUI.FlexItem
                  key={i}
                  grow={{ xs: i === 0 ? 5 : 1, sm: i === 0 ? 10 : 1 }}
                  sx={{
                    backgroundColor: `rgba(${i * (255 / 10)},0,0)`,
                    color: "#fff",
                  }}
                >
                  {i}
                </MZUI.FlexItem>
              );
            })}
          </MZUI.Flex>
          graphs
          <MZUI.Grid
            cols={{ xs: 1, sm: 1, md: 2, lg: 3 }}
            rowHeight={300}
            spacing={1}
          >
            <MZUI.GridItem>
              <MZUI.LineGraph
                title="LineGraph"
                labels={["label 1", "label 2", "label 3", "label 4"]}
                dataLabels={["data 1", "data 2", "data 3", "data 4"]}
                dataSets={[
                  [100, 105, 50, 140],
                  [50, 190, 150, 100],
                  [105, 50, 140, 190],
                  [190, 100, 105, 50],
                ]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#333"
                color="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.BarGraph
                title="BarGraph"
                labels={["label 1", "label 2", "label 3", "label 4"]}
                dataLabels={["data 1", "data 2", "data 3", "data 4"]}
                dataSets={[
                  [100, 105, 50, 140],
                  [50, 190, 150, 100],
                  [105, 50, 140, 190],
                  [190, 100, 105, 50],
                ]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#eee"
                color="#000"
                stacked={false}
                horizontal={false}
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.RadarGraph
                title="RadarGraph"
                labels={["label 1", "label 2", "label 3", "label 4"]}
                dataLabels={["data 1", "data 2", "data 3", "data 4"]}
                dataSets={[
                  [100, 105, 50, 140],
                  [50, 190, 150, 100],
                  [105, 50, 140, 190],
                  [190, 100, 105, 50],
                ]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#003"
                color="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.PieGraph
                title="PieGraph"
                dataLabels={["data 1", "data2", "data3", "data4"]}
                dataValues={[100, 105, 140, 50]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#333"
                color="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.DoughnutGraph
                title="DoughnutGraph"
                dataLabels={["data 1", "data2", "data3", "data4"]}
                dataValues={[100, 105, 140, 50]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.PolarGraph
                title="PolarGraph"
                dataLabels={["data 1", "data2", "data3", "data4"]}
                dataValues={[100, 105, 140, 50]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#333"
                color="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
            <MZUI.GridItem>
              <MZUI.BubbleGraph
                title="BubbleGraph"
                dataLabels={["data 1", "data2", "data3", "data4"]}
                dataSetsX={[
                  [1, 2, 3, 4],
                  [1, 2, 3, 4],
                  [1, 2, 3, 4],
                  [1, 2, 3, 4],
                ]}
                dataSetsY={[
                  [100, 105, 50, 140],
                  [50, 190, 150, 100],
                  [105, 50, 140, 190],
                  [190, 100, 105, 50],
                ]}
                dataColors={["#cc0000", "#00cc00", "#00ccff", "#6600ff"]}
                backgroundColor="#333"
                color="#fff"
                rtl={true}
              />
            </MZUI.GridItem>
          </MZUI.Grid>
        </MZUI.LayoutItem>
        <MZUI.LayoutItem area={"footer"} sx={{ backgroundColor: `#cc0000` }}>
          footer
        </MZUI.LayoutItem>
      </MZUI.Layout>
    </MZUI.AppTheme>
  );
};
export default TestPage;
