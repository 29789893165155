// packages
import React from "react";
import * as Router from "react-router-dom";
// UI
import * as MUI from "@mui/material";
import * as MLab from "@mui/lab";
import * as MIcons from "@mui/icons-material";
// models
import { MyAppM } from "../models/MyAppM";
import { MyReportsM } from "../models/MyReportsM";
import * as MZ from "../models/MZ";
// layouts
import MyPage from "../layouts/MyPage";
import MyDashboardPerformanceTab from "../layouts/dashboard/MyDashboardPerformanceTab";
import MyDashboardDetailsTab from "../layouts/dashboard/MyDashboardDetailsTab";
import MyDashboardFilterForm from "../layouts/dashboard/MyDashboardFilterForm";
import MyDashboardDownloadForm from "../layouts/dashboard/MyDashboardDownloadForm";
// components
import MyFlex from "../components/MyFlex";
import MyGrid from "../components/MyGrid";

const MyDashboard = () => {
  // constants
  const RouterNavigate = Router.useNavigate();
  // const RouterLocation = Router.useLocation();
  const RouterParams = Router.useParams();
  // my variables
  // ui
  const [tab, setTab] = React.useState("performance");
  const [filterDialog, setFilterDialog] = React.useState(false);
  const [downloadDialog, setDownloadDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [apiError, setApiError] = React.useState(null);
  // data
  const [filterExport, setFilterExport] = React.useState({});
  const [filterData, setFilterData] = React.useState({});
  const [reportId, setReportId] = React.useState(
    "TotalRevenue/RevenueAnalysis"
  );
  const [dependants, setDependants] = React.useState(null);
  const [rawData, setRawData] = React.useState(null);
  const oldDates = React.useRef({});
  const [pData, setPData] = React.useState({});

  // check if logged in
  // pre render
  React.useLayoutEffect(() => {
    if (MyAppM.checkUIDD() === false) RouterNavigate("/");
    (async () => {
      // dependants
      if (!dependants) {
        var r = await getDependants();
        if (r.success === false) {
          setApiError(r.message);
        } else {
          setApiError(null);
          setDependants(r.data["data"]);
        }
      }
    })();
  }, []);

  // after render
  React.useEffect(() => {
    (async () => {
      let rData = rawData;
      if (!filterData || !dependants) return;
      //
      let now = new MZ.DateTime();
      if (!filterData["start_date"])
        filterData["start_date"] = now.addDays(-1).getFormated("Y-m-d");
      if (!filterData["end_date"])
        filterData["end_date"] = now.addDays(-1).getFormated("Y-m-d");
      if (!filterData["old_date"])
        filterData["old_date"] = now
          .addDays(-1)
          .addYears(-1)
          .getFormated("Y-m-d");
      //transactions
      if (
        filterData["start_date"] !== oldDates.current["start_date"] ||
        filterData["end_date"] !== oldDates.current["end_date"] ||
        filterData["old_date"] !== oldDates.current["old_date"]
      ) {
        var r = await getTransactions(
          filterData["start_date"],
          filterData["end_date"],
          filterData["old_date"]
        );
        if (r.success === false) {
          setApiError(r.message);
        } else {
          setApiError(null);
          setRawData(r.data["data"]);
          rData = r.data["data"];
          oldDates.current["start_date"] = filterData["start_date"];
          oldDates.current["end_date"] = filterData["end_date"];
          oldDates.current["old_date"] = filterData["old_date"];
        }
      }
      //
      var r = MyReportsM.processData(
        filterData,
        reportId,
        rData,
        dependants,
        RouterParams.portal
      );
      if (r.success === false) {
        setApiError(r.message);
        setFilterDialog(true);
      } else {
        setApiError(null);
        setPData(r.data);
      }

      setLoading(null);
    })();
  }, [dependants, rawData, filterData, reportId]);

  return (
    <MyPage>
      {MZ.If(
        !!apiError,
        <MUI.Alert severity="error" sx={{ m: 3 }}>
          {apiError}
        </MUI.Alert>
      )}
      <MLab.TabContext value={tab}>
        <MyGrid.Container
          cols={"1fr"}
          rows={MyGrid.rowsManual("auto", "1fr")}
          spacing={1}
        >
          <MyGrid.Item
            cols={"1"}
            rows={"1"}
            sx={{ mt: 0.5, borderBottom: 1, borderColor: "divider", px: 0.25 }}
          >
            <MyFlex.Container spacing={1}>
              <MyFlex.Item grow={1}>
                <MLab.TabList
                  onChange={(evt, val) => setTab(val)}
                  sx={{ minHeight: 0 }}
                >
                  <MUI.Tab
                    icon={<MIcons.Assessment sx={{ fontSize: 15 }} />}
                    iconPosition="start"
                    label={MZ.T("performance")}
                    value="performance"
                    sx={{ fontSize: 12.5, minHeight: 0 }}
                  />
                  <MUI.Tab
                    icon={<MIcons.TableChart sx={{ fontSize: 15 }} />}
                    iconPosition="start"
                    label={MZ.T("details")}
                    value="details"
                    sx={{ fontSize: 12.5, minHeight: 0 }}
                  />
                </MLab.TabList>
              </MyFlex.Item>

              <MUI.FormControl
                variant="filled"
                size="small"
                sx={{ mb: 0.5, minWidth: 0 }}
              >
                <MUI.Select
                  defaultValue={reportId}
                  value={reportId}
                  onChange={(evt) => setReportId(evt.target.value)}
                  inputProps={{
                    "aria-label": "Without label",
                    sx: { py: 0.5 },
                  }}
                >
                  <MUI.ListSubheader>{MZ.T("total-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="TotalRevenue/RevenueAnalysis">
                    {MZ.T("total-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/AverageCheck">
                    {MZ.T("total-revenue-average")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSeat">
                    {MZ.T("total-revenue-per-seat")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerHour">
                    {MZ.T("total-revenue-per-hour")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSeatPerHour">
                    {MZ.T("total-revenue-per-seat-hour")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="TotalRevenue/RevenuePerSqm">
                    {MZ.T("total-revenue-per-sqm")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("food-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="FoodRevenue/RevenueAnalysis">
                    {MZ.T("food-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="FoodRevenue/RatioAnalysis">
                    {MZ.T("food-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>
                    {MZ.T("beverage-revenue")}
                  </MUI.ListSubheader>
                  <MUI.MenuItem value="BeverageRevenue/RevenueAnalysis">
                    {MZ.T("beverage-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="BeverageRevenue/RatioAnalysis">
                    {MZ.T("beverage-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("other-revenue")}</MUI.ListSubheader>
                  <MUI.MenuItem value="OtherRevenue/RevenueAnalysis">
                    {MZ.T("other-revenue-analysis")}
                  </MUI.MenuItem>
                  <MUI.MenuItem value="OtherRevenue/RatioAnalysis">
                    {MZ.T("other-revenue-ratio")}
                  </MUI.MenuItem>
                  <MUI.ListSubheader>{MZ.T("covers")}</MUI.ListSubheader>
                  <MUI.MenuItem value="CoversRevenue/TotalCovers">
                    {MZ.T("covers-total")}
                  </MUI.MenuItem>
                </MUI.Select>
              </MUI.FormControl>

              <MUI.Box>
                <MUI.Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<MIcons.FilterAlt />}
                  sx={{ mb: 0.5 }}
                  onClick={() => setFilterDialog(true)}
                >
                  {MZ.T("filter")}
                </MUI.Button>
              </MUI.Box>

              <MUI.Box>
                <MUI.Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<MIcons.Download />}
                  sx={{ mb: 0.5, boxShadow: "none" }}
                  onClick={() => setDownloadDialog(true)}
                >
                  {MZ.T("download")}
                </MUI.Button>
              </MUI.Box>
            </MyFlex.Container>
          </MyGrid.Item>
          <MyGrid.Item cols={"1"} rows={"2"} sx={{ p: 2 }}>
            <MLab.TabPanel value="performance" sx={{ p: 0 }}>
              <MyDashboardPerformanceTab
                loading={loading}
                pData={pData}
                filterData={filterData}
                filterExport={filterExport}
                demo={false}
              />
            </MLab.TabPanel>
            <MLab.TabPanel value="details" sx={{ p: 0 }}>
              <MyDashboardDetailsTab loading={loading} pData={pData} />
            </MLab.TabPanel>
          </MyGrid.Item>
        </MyGrid.Container>
      </MLab.TabContext>
      <MyDashboardFilterForm
        open={filterDialog}
        setOpen={setFilterDialog}
        loading={loading}
        setLoading={setLoading}
        filterExport={filterExport}
        setFilterExport={setFilterExport}
        filterData={filterData}
        setFilterData={setFilterData}
        dependants={dependants}
        apiError={apiError}
      />
      <MyDashboardDownloadForm
        open={downloadDialog}
        setOpen={setDownloadDialog}
        loading={loading}
        setLoading={setLoading}
        rawData={rawData}
        dependants={dependants}
        filterData={filterData}
        apiError={apiError}
      />
    </MyPage>
  );
};
export default MyDashboard;

//============< APIs
// get dependants
async function getDependants() {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    //========================= request
    return await MyAppM.exabyteRequest("client/dashboard/dependants", fData);
  } catch (err) {
    return MZ.Error(err);
  }
}

// get transactions
async function getTransactions(start, end, old) {
  try {
    //========================= validate data
    let fData = new FormData();
    fData.set("UID", MyAppM.UIDD.UID);
    fData.set(
      "start_date",
      new MZ.DateTime(start).addDays(-7).getFormated("Y-m-d")
    );
    fData.set("end_date", new MZ.DateTime(end).addDays(7).getFormated("Y-m-d"));
    fData.set(
      "old_date",
      new MZ.DateTime(old).addDays(-7).getFormated("Y-m-d")
    );
    //========================= request
    return await MyAppM.exabyteRequest(
      "client/dashboard/get-transactions",
      fData
    );
  } catch (err) {
    return MZ.Error(err);
  }
}
